import { DataGridBody } from '../data-grid-body'

/**
 * ox-grid-body 의 keydown handler
 *
 * - handler의 this 는 ox-grid-body임.
 */
export async function dataGridBodyKeydownHandler(this: DataGridBody, e: KeyboardEvent): Promise<void> {
  // 한글 Composing 이벤트는 무시
  if (e.isComposing) return

  // arrow-key
  const key = e.key
  var { row = 0, column = 0 } = this.focused || {}
  var { records = [] } = this.data || {}
  var maxrow = this.config.rows.appendable ? records.length : records.length - 1
  var maxcolumn = (this.columns || []).filter(column => !column.hidden).length - 1

  if (this.editTarget) {
    switch (key) {
      case 'Escape':
      case 'Esc':
      /* TODO 편집이 취소되어야 한다. */
      case 'Enter':
        this.editTarget = null

        this.focus()
        return

      case 'Tab':
        this.editTarget = null
        if (e.shiftKey) {
          column = Math.max(0, column - 1)
        } else {
          column = Math.min(maxcolumn, column + 1)
        }
        this.focus()
        break

      // case 'Down':
      // case 'ArrowDown':
      //   this.editTarget = null
      //   row = Math.min(maxrow, row + 1)
      //   this.focus()
      //   break

      case 'PageUp':
      case 'PageDown':
      case 'End':
      case 'Home':
        // e.preventDefault()
        // return
        break

      default:
        if (e.metaKey || e.ctrlKey) {
          /* Window System에 선택된 영역이 없다면, 셀을 복사/붙여넣기 한다. */
          switch (e.key) {
            case 'c':
              const selection = window.getSelection()
              if (!selection || !selection.anchorNode || selection.type != 'Range') {
                /* 
                  editor가 select 인 경우 자체 복사 기능이 없으므로 this.copy()를 사용하도록 하기 위해서, selection.type != 'Range' 조건을 추가했다.
                  select인 경우에는 selection.type 이 'Caret' 이었다.
                 */
                this.copy()
                e.preventDefault()
              }
              break
            case 'v':
              this.paste()
              break
          }
        }
        return
    }
  } else {
    switch (key) {
      case 'Up':
      case 'ArrowUp':
        if (e.shiftKey) {
          let { start } = this._selectBlock || {}
          let focused = this.focusedField
          if (focused && focused.rowIndex > 0) {
            this.setSelectBlock(start || focused, this.getFieldByIndex(focused.rowIndex - 1, focused.columnIndex))
          }
        } else {
          this.setSelectBlock()
          if (e.metaKey || e.ctrlKey) {
            row = 0
          } else {
            row = Math.max(0, row - 1)
          }
        }
        break

      case 'Down':
      case 'ArrowDown':
        if (e.shiftKey) {
          let { start } = this._selectBlock || {}
          let focused = this.focusedField
          if (focused) {
            let end = this.getFieldByIndex(focused.rowIndex + 1, focused.columnIndex)
            if (end) {
              this.setSelectBlock(start || focused, end)
            }
          }
        } else {
          this.setSelectBlock()
          if (e.metaKey || e.ctrlKey) {
            row = maxrow
          } else {
            row = Math.min(maxrow, row + 1)
          }
        }
        break

      case 'Enter':
        this.setSelectBlock()
        this.startEditTarget(row, column, null)
        return

      case 'Left':
      case 'ArrowLeft':
      case 'Backspace':
        if (e.shiftKey) {
          let { start } = this._selectBlock || {}
          let focused = this.focusedField
          if (focused && focused.columnIndex > 0) {
            this.setSelectBlock(start || focused, this.getFieldByIndex(focused.rowIndex, focused.columnIndex - 1))
          }
        } else {
          this.setSelectBlock()
          if (e.metaKey || e.ctrlKey) {
            column = 0
          } else {
            column = Math.max(0, column - 1)
          }
        }
        break

      case 'Right':
      case 'ArrowRight':
        if (e.shiftKey) {
          let { start } = this._selectBlock || {}
          let focused = this.focusedField
          if (focused) {
            let end = this.getFieldByIndex(focused.rowIndex, focused.columnIndex + 1, false)
            if (end) {
              this.setSelectBlock(start || focused, end)
            }
          }
        } else {
          this.setSelectBlock()
          if (e.metaKey || e.ctrlKey) {
            column = maxcolumn
          } else {
            column = Math.min(maxcolumn, column + 1)
          }
        }
        break

      case 'Tab':
        this.editTarget = null
        this.setSelectBlock()
        if (e.shiftKey) {
          column = Math.max(0, column - 1)
        } else {
          column = Math.min(maxcolumn, column + 1)
        }
        this.focus()
        break

      case 'PageUp':
        /* TODO 페이지당 레코드의 수를 계산해서 증감시켜야 한다. */
        this.setSelectBlock()
        row = Math.max(0, row - 10)
        break

      case 'PageDown':
      case ' ':
        this.setSelectBlock()
        row = Math.min(maxrow, row + 10)
        break

      case 'Esc':
      case 'Escape':
        this.setSelectBlock()
        return

      case 'End':
        this.setSelectBlock()
        row = maxrow
        break

      case 'Home':
        this.setSelectBlock()
        row = 0
        break

      default:
        if (e.metaKey || e.ctrlKey) {
          switch (e.key) {
            case 'c':
              const selection = window.getSelection()
              if (!selection || !selection.anchorNode || selection.type != 'Range') {
                /* 
                  에디터 상태가 아닌 경우에도, selection.type != 'Range' 조건을 추가해서,
                  셀렉션이 된 상태에서는 기본 동작으로 텍스트 복사를 할 수 있도록 했다.
                  보통은, 싱글 필드가 셀렉션박스로 지정된 경우에 자체 텍스트의 일부를 복사하기 위해서 필요하다.
                 */
                this.copy()
                e.preventDefault()
              }
              break
            case 'v':
              this.paste()
              break
          }
          return
        } else if (key.length === 1) {
          this.setSelectBlock()
          this.startEditTarget(row, column, key)
        }
    }
  }

  if (!this.focused || this.focused.row !== row || this.focused.column !== column) {
    this.dispatchEvent(new CustomEvent('focus-change', { bubbles: true, composed: true, detail: { row, column } }))
  }

  /* arrow key에 의한 scrollbar의 자동 움직임을 하지 못하도록 한다. */
  e.preventDefault()
}
