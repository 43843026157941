import { DataGridBody } from '../data-grid-body'

/**
 * ox-grid-body 의 focus-change handler
 *
 * - handler의 this 는 ox-grid-body임.
 */
export async function dataGridBodyFocusChangeHandler(this: DataGridBody, e: Event): Promise<void> {
  const { row: rowIndex, column: columnIndex } = (e as CustomEvent).detail

  const target = this.getFieldByIndex(rowIndex, columnIndex)

  var { column, record } = target || {}

  /* do column focus handler */
  if (column) {
    var { focus } = column.handlers
    focus && focus(this.columns, this.data, column, record, rowIndex, target, e)
  }

  /* do rows focus handler */
  var { focus: rowsFocus } = this.config.rows.handlers
  rowsFocus && rowsFocus(this.columns, this.data, column, record, rowIndex, target, e)
}
