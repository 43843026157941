import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-text')
export class OxGristEditorText extends OxGristEditor {
  get inlineEditable() {
    return true
  }

  get editorTemplate() {
    return html` <input type="text" .value=${this.value} /> `
  }
}
