import '@operato/data-grist'

import gql from 'graphql-tag'
import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { client } from '@operato/graphql'
import { i18next } from '@operato/i18n'
import { isMobileDevice } from '@operato/utils'

@customElement('my-login-history')
class MyLoginHistory extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      background-color: var(--md-sys-color-background);
      padding: var(--spacing-large);
      overflow: auto;
    }
    ox-grist {
      flex: 1;
    }
  `

  @property({ type: Array }) histories: any[] = []
  @property({ type: Number }) limit?: number

  render() {
    if (!this.histories?.length) return html``

    const config = {
      rows: { appendable: false },
      pagination: { infinite: true },
      columns: [
        { type: 'gutter', gutterName: 'sequence' },
        {
          type: 'object',
          name: 'accessDomain',
          header: i18next.t('field.domain'),
          record: { editable: false },
          width: 200
        },
        {
          type: 'datetime',
          name: 'accessedAt',
          header: i18next.t('field.accessed-at'),
          record: { editable: false },
          width: 200
        },
        {
          type: 'string',
          name: 'accessorIp',
          header: i18next.t('field.ip_address'),
          record: { editable: false },
          width: 200
        }
      ]
    }

    return html`
      <ox-grist
        .mode=${isMobileDevice() ? 'LIST' : 'GRID'}
        .config=${config}
        .data="${{ records: this.histories }}"
      ></ox-grist>
    `
  }

  firstUpdated() {
    this.fetchLoginHistories()
  }

  async fetchLoginHistories() {
    try {
      const response = await client.query({
        query: gql`
          query myLoginHistories($limit: Float!) {
            myLoginHistories(limit: $limit) {
              accessDomain {
                name
              }
              accessorIp
              accessedAt
            }
          }
        `,
        variables: { limit: this.limit || 10 }
      })

      if (response.errors?.length) return
      this.histories = response.data.myLoginHistories
    } catch (e: any) {
      this.showToast('message' in e ? e.message : e)
    }
  }

  showToast(message) {
    document.dispatchEvent(new CustomEvent('notify', { detail: { message } }))
  }
}
