/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'

import { css, html, PropertyValues } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { ScrollbarStyles } from '@operato/styles'
import { FileDropHelper } from '@operato/utils'

import { OxFormField } from './ox-form-field.js'

@customElement('ox-input-file')
export class OxInputFile extends OxFormField {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        border-radius: var(--md-sys-shape-corner-small);
        align-items: center;
        justify-content: center;
        padding: var(--padding-default, 9px);
        min-height: 100px;
        text-transform: capitalize;

        border: 1px dashed var(--md-sys-color-outline-variant);
        background-color: var(--md-sys-color-surface);
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      }
      :host > span {
        color: var(--md-sys-color-primary);
        font-weight: var(--md-sys-typescale-label-medium-weight, var(--md-ref-typeface-weight-medium, 500));
      }

      :host > md-icon {
        color: var(--md-sys-color-primary);
        --md-icon-size: var(--file-uploader-icon-size, 36px);
      }

      :host(.candrop) {
        background-color: var(--md-sys-color-primary-fixed);
        border: 1px dashed var(--md-sys-color-primary-fixed-dim);
      }

      #input-file {
        display: none;
      }

      label {
        position: relative;
        width: auto;
        border: none;
        text-transform: capitalize;

        padding: var(--file-uploader-label-padding);
        border-radius: var(--file-uploader-label-border-radius);
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-on-surface-variant);
      }

      ul {
        max-width: 500px;
        width: 100%;
        list-style: none;
        margin: 5px 0 0 0;
        padding: 0;
        max-height: 46px;
        overflow: auto;
        background-color: var(--md-sys-color-on-primary);
      }
      li {
        text-align: left;

        padding: 3px 5px 2px 5px;
        border-bottom: var(--file-uploader-li-border-bottom);
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-on-primary-container);
        display: flex;
        align-items: center;
      }
      li md-icon {
        cursor: pointer;
        font-size: var(--icon-size-small);
        margin-left: auto;
      }
      li md-icon:hover,
      li md-icon:active {
        color: var(--md-sys-color-error);
      }
    `
  ]

  @property({ type: Boolean }) multiple?: boolean
  @property({ type: String }) accept?: string
  @property({ type: String }) icon?: string = 'upload'
  @property({ type: String }) label?: string
  @property({ type: String }) description?: string = 'drop files here!'
  @property({ type: Boolean, reflect: true, attribute: 'hide-filelist' })
  hideFileList: boolean = false
  @property({ type: Boolean, reflect: true, attribute: 'attach-filelist' })
  attachFileList: boolean = false

  @query('#input-file') fileInput!: HTMLInputElement

  render() {
    var files: File[] = this.value || []

    return html`
      <md-icon>${this.icon || 'upload'}</md-icon>

      <span>${this.description || 'drop files here!'}</span>

      <input
        id="input-file"
        type="file"
        accept=${this.accept}
        ?multiple=${this.multiple}
        hidden
        capture="environment"
        @change=${(e: Event) => this._onChangeValue(e)}
        ?disabled=${this.disabled}
      />

      <label for="input-file">${this.label || 'select files'}</label>

      ${!this.hideFileList && files?.length
        ? html` <ul>
            ${files.map(
              file => html`
                <li>
                  - ${file.name}
                  <md-icon
                    @click=${(e: Event) => {
                      if (this.disabled) {
                        return
                      }
                      files.splice(files.indexOf(file), 1)
                      this.value = [...files]
                      this._notifyChange()
                    }}
                    >delete_outline</md-icon
                  >
                </li>
              `
            )}
          </ul>`
        : html``}
    `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('value') && !(this.value instanceof Array)) {
      this.value = !this.value ? [] : [this.value]
    }
  }

  firstUpdated() {
    FileDropHelper.set(this)

    this.addEventListener('file-drop', (e: Event) => {
      if (this.disabled) {
        return
      }

      const detail = (e as CustomEvent).detail
      this.value = this.multiple ? detail : detail[0] ? [detail[0]] : []

      this._notifyChange()
    })
  }

  get files() {
    return this.value
  }

  reset() {
    this.fileInput.value = ''
    this.value = []

    this._notifyChange()
  }

  _onChangeValue(e: Event) {
    const fileInput = e.currentTarget as HTMLInputElement
    const files = this.value || []

    this.value = this.attachFileList
      ? [...files, ...Array.from(fileInput.files as FileList)]
      : [...Array.from(fileInput.files as FileList)]

    fileInput.files = null

    this._notifyChange()
  }

  _notifyChange() {
    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }
}
