import { DataReportBody } from '../data-report-body'
import { DataReportField } from '../data-report-field'

/**
 * ox-report-body 의 dblclick handler
 */
export async function dataReportBodyDblclickHandler(this: DataReportBody, e: Event) {
  e.stopPropagation()

  var target = e.target as DataReportField
  var { record, rowIndex, column } = target

  if (!column) {
    /* 여백 컬럼이 클릭된 경우 */
    return
  }

  /* do column dblclick handler */
  var { dblclick } = column.handlers
  dblclick && dblclick(this.columns, this.data, column, record, rowIndex, target)

  /* do rows dblclick handler */
  var { dblclick: rowsDblclick } = this.config.rows.handlers
  rowsDblclick && rowsDblclick(this.columns, this.data, column, record, rowIndex, target)
}
