import json5 from 'json5'
import pickBy from 'lodash-es/pickBy'

import { FilterValue, SortersConfig } from '../types'

export function convertListParamToSearchString({
  filters,
  sorters,
  page,
  limit,
  base
}: {
  filters?: FilterValue[]
  sorters?: SortersConfig
  page?: number
  limit?: number
  base?: string
}) {
  const urlParams = new URLSearchParams(base)

  if (sorters) {
    if (sorters.length > 0) {
      urlParams.set('sorters', json5.stringify(sorters))
    } else {
      urlParams.delete('sorters')
    }
  }

  if (filters) {
    if (filters.length > 0) {
      urlParams.set('filters', json5.stringify(filters))
    } else {
      urlParams.delete('filters')
    }
  }

  if (!isNaN(page as any)) {
    urlParams.set('page', String(page))
  }

  if (!isNaN(limit as any)) {
    urlParams.set('limit', String(limit))
  }

  return String(urlParams)
}

export function convertSearchStringToListParam(searchParams: string): {
  filters?: FilterValue[]
  sorters?: SortersConfig
  pagination?: {
    page?: number
    limit?: number
  }
} {
  const urlParams = new URLSearchParams(searchParams)

  return pickBy(
    {
      filters: json5.parse(urlParams.get('filters') || '[]'),
      sorters: json5.parse(urlParams.get('sorters') || '[]'),
      pagination: pickBy(
        {
          page: Number(urlParams.get('page')),
          limit: Number(urlParams.get('limit'))
        },
        v => !isNaN(v)
      )
    },
    v => v !== undefined && !(typeof v === 'object' && Object.keys(v).length == 0)
  )
}
