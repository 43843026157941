import { FilterConfigObject, FilterSelectRenderer } from '../types'

import { html } from 'lit-html'

function formatDate(type: string, date: Date) {
  var year = date.getFullYear()
  var month = ('0' + (date.getMonth() + 1)).slice(-2)
  var day = ('0' + date.getDate()).slice(-2)
  var hours = ('0' + date.getHours()).slice(-2)
  var minutes = ('0' + date.getMinutes()).slice(-2)

  var formatDate = year + '-' + month + '-' + day

  return type == 'date' ? formatDate : formatDate + 'T' + hours + ':' + minutes
}

export const FilterRangeDate: FilterSelectRenderer = (column, value, owner) => {
  const filter = column.filter as FilterConfigObject

  var [from, to] = value instanceof Array ? value : []

  var type: any = filter?.type || column.type
  if (type === 'datetime') {
    type = 'datetime-local'
  }

  from = formatDate(type, new Date(from))
  to = formatDate(type, new Date(to))

  return html`
    <input
      name=${column.name}
      type=${type}
      .value=${from}
      from
      @keyup=${(e: KeyboardEvent) => {
        if (e.key !== 'Enter') {
          return
        }
        ;(e.target as HTMLElement).dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: Array.from(
                (e.target as HTMLElement).parentElement!.querySelectorAll('input[from], input[to]')
              ).map(input => (input as HTMLInputElement).value)
            }
          })
        )
      }}
    />
    ~
    <input
      name=${column.name}
      type=${type}
      .value=${to}
      to
      @keyup=${(e: KeyboardEvent) => {
        if (e.key !== 'Enter') {
          return
        }
        ;(e.target as HTMLElement).dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: Array.from(
                (e.target as HTMLElement).parentElement!.querySelectorAll('input[from], input[to]')
              ).map(input => (input as HTMLInputElement).value)
            }
          })
        )
      }}
    />
  `
}
