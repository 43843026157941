import '@material/web/icon/icon.js'
import './record-view'

import { css, html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { OxPopup } from '@operato/popup'

import { DataGrist } from '../data-grist'
import { ColumnConfig, GristRecord, ValidationReason } from '../types'
import { RecordView } from './record-view'

@customElement('ox-record-creator')
export class OxRecordCreator extends LitElement {
  static styles = [
    css`
      ::slotted([slot='popup']) {
        display: none;
      }
    `
  ]

  @state() grist?: DataGrist

  @property({ type: Object }) callback?: (record: GristRecord) => boolean
  @property({ type: Object }) customPopupCallback?: (popup: any) => boolean
  @property({ type: Boolean, attribute: 'light-popup' }) lightPopup: boolean = false
  @property({ type: Boolean, attribute: 'prevent-close-on-blur' }) preventCloseOnBlur = false

  constructor() {
    super()

    this.addEventListener('click', (e: Event) => {
      e.preventDefault()
      e.stopPropagation()

      if (this.lightPopup) {
        this.openLightPopup()
      } else {
        this.openPopup()
      }
    })
  }

  connectedCallback(): void {
    super.connectedCallback()

    this.grist = this.closest('ox-grist') as DataGrist
  }

  render() {
    return html`
      <slot></slot>
      <slot name="popup"></slot>
    `
  }

  validateRecord(record: GristRecord): { field: string; reason: ValidationReason }[] {
    const columns = this.grist!.compiledConfig.columns
    const invalidFields: { field: string; reason: ValidationReason }[] = []

    columns.forEach(column => {
      if (
        column.record?.mandatory &&
        (record[column.name] === undefined || record[column.name] === null || record[column.name] === '')
      ) {
        invalidFields.push({
          field: column.name,
          reason: ValidationReason.MANDATORY
        })
      }
    })

    return invalidFields
  }

  openLightPopup() {
    const slot = this.renderRoot?.querySelector(`slot[name='popup']`) as HTMLSlotElement
    const slottedElements = slot?.assignedElements({ flatten: true })
    const originalContent = slottedElements?.[0] as HTMLElement

    if (originalContent) {
      this.lightPopupCustomCreator(originalContent)
    } else {
      this.lightPopupRecordView()
    }
  }

  openPopup() {
    const slot = this.renderRoot?.querySelector(`slot[name='popup']`) as HTMLSlotElement
    const slottedElements = slot?.assignedElements({ flatten: true })
    const originalContent = slottedElements?.[0] as HTMLElement

    if (originalContent) {
      this.popupCustomCreator(originalContent)
    } else {
      this.popupRecordView()
    }
  }

  lightPopupRecordView() {
    const config = this.grist!.compiledConfig
    var title = 'create'
    const rowIndex = -1
    var record: GristRecord = {}
    const columns = config.columns

    var popup = OxPopup.open({
      template: html`
        <div title>${title}</div>
        <ox-record-view
          @field-change=${(e: CustomEvent) => {
            const view = e.currentTarget as RecordView

            var { after, before, column, record, row } = (e as CustomEvent).detail as {
              after: any
              before: any
              column: ColumnConfig
              record: GristRecord
              row: number
            }

            var validation = column.validation
            if (validation && typeof validation == 'function') {
              if (!validation.call(this, after, before, record, column)) {
                return
              }
            }

            view.record = {
              ...record,
              [column.name]: after
            }
          }}
          .columns=${columns}
          .record=${record}
          .rowIndex=${rowIndex}
          @reset=${(e: Event) => {
            const view = e.currentTarget as RecordView
            view.record = {}
          }}
          @cancel=${(e: Event) => {
            popup.close()
          }}
          @ok=${async (e: Event) => {
            const view = e.currentTarget as RecordView

            // 레코드 밸리데이션 체크
            const invalidFields = await this.validateRecord(view.record)
            if (invalidFields.length > 0) {
              // const firstInvalidField = invalidFields[0];
              // if (firstInvalidField) {
              //   view.setFocus(firstInvalidField.field)
              // }
              view.setFocusOnInvalid(invalidFields)
              return false
            }

            popup.close()

            this.dispatchEvent(
              new CustomEvent('ok', {
                bubbles: true,
                composed: true,
                detail: view.record
              })
            )
          }}
        ></ox-record-view>
      `,
      parent: document.body,
      preventCloseOnBlur: this.preventCloseOnBlur
    })
  }

  popupRecordView() {
    const config = this.grist!.compiledConfig
    const rowIndex = -1
    var record: GristRecord = {}
    const columns = config.columns

    var title = 'create'

    var recordView = document.createElement('ox-record-view') as RecordView

    recordView.columns = columns
    recordView.record = record
    recordView.rowIndex = rowIndex

    document.dispatchEvent(
      new CustomEvent('open-popup', {
        detail: {
          template: recordView,
          options: {
            backdrop: true,
            size: 'large',
            title
          },
          callback: (popup: any) => {
            recordView.addEventListener('reset', (e: Event) => {
              const view = e.currentTarget as RecordView
              view.record = {}
            })

            recordView.addEventListener('cancel', (e: Event) => {
              popup.close()
            })

            recordView.addEventListener('ok', async (e: Event) => {
              const view = e.currentTarget as RecordView

              // 레코드 밸리데이션 체크
              const invalidFields = await this.validateRecord(view.record)
              if (invalidFields.length > 0) {
                const firstInvalidField = invalidFields[0]
                if (firstInvalidField) {
                  const fieldElement = view.renderRoot?.querySelector(`[name="${firstInvalidField}"]`)
                  if (fieldElement) {
                    ;(fieldElement as HTMLElement).focus()
                  }
                }
                return false
              }

              if (await this.callback?.(view.record)) {
                popup.close()
              } else {
                // 밸리데이션 실패 시 처리
                console.error('레코드 밸리데이션 실패')
                // 여기에 사용자에게 오류 메시지를 표시하는 로직을 추가할 수 있습니다.
              }
            })

            recordView.addEventListener('field-change', async (e: Event) => {
              const view = e.currentTarget as RecordView

              var { after, before, column, record, row } = (e as CustomEvent).detail as {
                after: any
                before: any
                column: ColumnConfig
                record: GristRecord
                row: number
              }

              var validation = column.validation
              if (validation && typeof validation == 'function') {
                if (!(await validation.call(this, after, before, record, column))) {
                  return
                }
              }

              view.record = {
                ...record,
                [column.name]: after
              }
            })

            popup.onclosed = () => {}
          }
        }
      })
    )
  }

  lightPopupCustomCreator(originalContent: HTMLElement) {
    const title = 'create'
    const popupContent = originalContent.cloneNode(true) as HTMLElement
    popupContent.removeAttribute('slot')

    OxPopup.open({
      template: html`
        <div title>${title}</div>
        ${popupContent}
      `,
      parent: document.body,
      preventCloseOnBlur: this.preventCloseOnBlur
    })

    this.customPopupCallback?.(popupContent) // 사용자 정의 팝업용 콜백 실행
  }

  popupCustomCreator(originalContent: HTMLElement) {
    const title = 'create'
    const popupContent = originalContent.cloneNode(true) as HTMLElement
    popupContent.removeAttribute('slot')

    document.dispatchEvent(
      new CustomEvent('open-popup', {
        detail: {
          template: popupContent,
          options: {
            backdrop: true,
            size: 'large',
            title
          },
          callback: this.customPopupCallback
        }
      })
    )
  }
}
