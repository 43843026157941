import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { OxGristRenderer } from './ox-grist-renderer'

@customElement('ox-grist-progress-renderer')
export class OxGristRendererProgress extends OxGristRenderer {
  static styles = css`
    :host {
      display: block;
      position: relative;

      width: 100%;
    }

    #border {
      display: block;
      position: absolute;

      height: 100%;
      width: 100%;
      box-sizing: border-box;

      border: var(--grist-input-progress-border);
      background-color: var(--grist-input-progress-background);
    }

    #bar {
      background-color: var(--grist-input-progress-bar-background);
      text-align: left;
      color: var(--grist-input-progress-bar-color);
    }
  `

  get rendererTemplate() {
    var { min = 0, max = 100 } = this.column.record.options || {}
    var value = Number(this.value)
    var progress = isNaN(value) ? 0 : Math.min(100, Math.max(0, ((value - min) * 100) / (max - min)))

    return html`
      <div id="border"></div>
      <div id="bar" style="width:${progress}%">&nbsp;${progress}</div>
    `
  }
}
