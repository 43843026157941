import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-email')
export class OxGristEditorEmail extends OxGristEditor {
  get editorTemplate() {
    return html` <input type="email" .value=${this.value} /> `
  }
}
