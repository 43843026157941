import { FieldRenderer } from '../types'
import { html } from 'lit'

// TODO 로케일 설정에 따라서 포맷이 바뀌도록 해야한다.
const DATETIME_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false
}

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}

const TIME_OPTIONS = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
}

const OPTIONS = {
  datetime: DATETIME_OPTIONS,
  time: TIME_OPTIONS,
  date: DATE_OPTIONS
}

function getsec(time: string): number {
  var arr = time.split(':')
  return Number(arr[0]) * 3600 + Number(arr[1]) * 60 + (Number(arr[2]) || 0)
}

/* 
  TODO date, time, month, week 등 datetime 이 외의 타입인 경우에는 문자열로 처리하도록 한다. 
  따라서, 이 경우는 타임존에 대한 처리가 불필요하다.
*/
export const OxGristRendererDate: FieldRenderer = (value, column, record, rowIndex, field) => {
  if (!value) {
    return ''
  }

  if (!isNaN(Number(value))) value = Number(value)

  var options = column.record.options || OPTIONS[column.type as 'datetime' | 'date' | 'time']
  var formatter = new Intl.DateTimeFormat(navigator.language, options)

  switch (column.type) {
    case 'datetime':
    case 'date':
      value = formatter.format(new Date(value))
      break
    case 'time':
    default:
  }

  return value ? html`<span>${value}</span>` : html`<span>&nbsp</span>`
}
