import { TemplateResult } from 'lit-html'

import { DataCardField } from './data-card/data-card-field'
import { DataCardGutter } from './data-card/data-card-gutter'
import { RecordCard } from './data-card/record-card'
import { DataGridField } from './data-grid/data-grid-field'
import { DataListField } from './data-list/data-list-field'
import { DataListGutter } from './data-list/data-list-gutter'
import { RecordPartial } from './data-list/record-partial'
import { DataReportField } from './data-report/data-report-field'
import { OxGristEditor } from './editors'
import { QueryFilter, OxFiltersForm } from './filters'
import { OxGristRenderer } from './renderers/ox-grist-renderer'

/**
 * Configuration object for defining the structure and behavior of a Grist table.
 *
 * @typedef {Object} GristConfig
 * @property {ColumnConfig[]} columns - An array of column configurations.
 * @property {RowsConfig} rows - Configuration for rows and their behavior.
 * @property {ListConfig} list - Configuration for the list view.
 * @property {TreeConfig} tree - Configuration for tree-related settings.
 * @property {PaginationConfig} [pagination] - Configuration for pagination settings (optional).
 * @property {SortersConfig} [sorters] - Configuration for sorting settings (optional).
 * @property {FiltersConfig} [filters] - Configuration for filtering settings (optional).
 */
export type GristConfig = {
  columns: ColumnConfig[]
  rows: RowsConfig
  list: ListConfig
  tree: TreeConfig
  pagination?: PaginationConfig
  sorters?: SortersConfig
  filters?: FiltersConfig
}

/**
 * Configuration object for specifying sorting options.
 *
 * @typedef {Object} SorterConfig
 * @property {string} name - The name of the sorting option.
 * @property {boolean} [desc] - Optional. Indicates whether the sorting is in descending order (optional).
 */
export type SorterConfig = { name: string; desc?: boolean }

/**
 * Configuration array for specifying multiple sorting options.
 *
 * @typedef {SorterConfig[]} SortersConfig
 */
export type SortersConfig = SorterConfig[]

/**
 * Enumeration of filter operators used to define filter conditions.
 *
 * @typedef {'search' | 'eq' | 'between' | 'gte' | 'lte' | 'is_not_true' | 'in' | 'like' | 'i_like' | 'noteq' |
 * 'is_empty_num_id' | 'is_blank' | 'is_present' | 'is_not_false' | 'is_true' | 'is_false' | 'is_not_null' |
 * 'is_null' | 'notin_with_null' | 'notin' | 'gt' | 'lt' | 'i_nlike' | 'nlike'} FilterOperator
 */
export type FilterOperator =
  | 'search'
  | 'eq'
  | 'between'
  | 'gte'
  | 'lte'
  | 'is_not_true'
  | 'in'
  | 'like'
  | 'i_like'
  | 'noteq'
  | 'is_empty_num_id'
  | 'is_blank'
  | 'is_present'
  | 'is_not_false'
  | 'is_true'
  | 'is_false'
  | 'is_not_null'
  | 'is_null'
  | 'notin_with_null'
  | 'notin'
  | 'gt'
  | 'lt'
  | 'i_nlike'
  | 'nlike'

export type SelectOptionObject =
  | {
      name: string
      display?: never
      value: string
    }
  | {
      display: string
      name?: never
      value: string
    }

export type SelectOption = SelectOptionObject | string

export type FilterChangedCallback = (value: any, form: OxFiltersForm) => boolean

/**
 * Configuration object for specifying filter conditions.
 *
 * @typedef {Object} FilterConfigObject
 * @property {string} type - The type of the filter condition.
 * @property {FilterOperator} [operator] - The filter operator used to compare values (optional).
 * @property {Object} [options] - Additional options or parameters for the filter condition (optional).
 * @property {boolean|undefined} [hidden] - The hidden flag for the filter condition (optional).
 * @property {string|number|boolean|string[]|number[]|undefined} [value] - The value to compare with in the filter condition (optional).
 * @property {string} [label] - The label to display for the filter condition (optional).
 */
export type FilterConfigObject = {
  type: string
  operator?: FilterOperator
  options?: { [key: string]: any }
  value?: string | number | boolean | string[] | number[] | undefined
  label?: string
  boundTo?: string[]
  hidden?: boolean
  onchange?: FilterChangedCallback
}

/**
 * Configuration option for specifying filter conditions.
 *
 * The `FilterConfig` type can take one of the following forms:
 * - A `FilterConfigObject` representing a specific filter condition.
 * - A `FilterOperator` representing a predefined filter operator.
 * - A boolean value to enable or disable filtering for a specific column.
 *
 * @typedef {Object|FilterOperator|boolean} FilterConfig
 */
export type FilterConfig = FilterConfigObject | FilterOperator | boolean

/**
 * Represents a filter condition with the following properties:
 *
 * - `name`: The name of the column or field being filtered.
 * - `operator`: The filter operator used for comparison.
 * - `value`: The value or values to compare against.
 *
 * @typedef {Object} FilterValue
 * @property {string} name - The name of the column or field.
 * @property {FilterOperator} operator - The filter operator.
 * @property {string|number|boolean|string[]|number[]|undefined} value - The value(s) to compare.
 */
export type FilterValue = {
  name: string
  operator: FilterOperator
  value: string | number | boolean | string[] | number[] | undefined
}

/**
 * Configuration options for filters.
 *
 * @typedef {Object} FiltersConfig
 * @property {boolean} [header] - Specifies whether to provide filtering functionality in the header.
 */
export type FiltersConfig = {
  header?: boolean
}

/**
 * Configuration options for pagination.
 *
 * @typedef {Object} PaginationConfig
 * @property {number} [page] - The current page number.
 * @property {number} [limit] - The maximum number of records to display per page.
 * @property {number[]} [pages] - An array of available page numbers.
 * @property {boolean} [infinite] - Specifies whether to use infinite scrolling for pagination.
 */
export type PaginationConfig = {
  page?: number
  limit?: number
  pages?: number[]
  infinite?: boolean
}

/**
 * Option to specify the inheritance behavior for including data from parent domains.
 *
 * - 'None': Do not include data from the parent domain.
 * - 'Include': Include data from the parent domain along with current domain data.
 * - 'Only': Include data only from the parent domain, excluding current domain data.
 *
 * @enum {string}
 */
export enum InheritedValueType {
  None = 'None',
  Include = 'Include',
  Only = 'Only'
}

/**
 * Configuration options for fetching data from a data source.
 *
 * @typedef {Object} FetchOption
 * @property {number} [page] - The page number to retrieve.
 * @property {number} [limit] - The maximum number of records to retrieve per page.
 * @property {SortersConfig} [sorters] - An array of sorting configurations for data. Only one of these options needs to be set.
 * @property {SortersConfig} [sortings] - An array of sorting configurations for data. Only one of these options needs to be set.
 * @property {QueryFilter[]} [filters] - An array of filtering criteria.
 * @property {InheritedValueType} [inherited] - Specifies the inheritance behavior for including data from parent domains.
 * @property {object} [options] - Additional options for customizing data fetching behavior.
 */
export type FetchOption = {
  page?: number
  limit?: number
  sorters?: SortersConfig
  sortings?: SortersConfig
  filters?: QueryFilter[]
  inherited?: InheritedValueType
  options?: object
}

/**
 * Represents the result of a data fetching operation.
 *
 * @typedef {Object} FetchResult
 * @property {number} [page] - The page number of the fetched data.
 * @property {number} [limit] - The maximum number of records per page.
 * @property {number} total - The total number of records in the data source.
 * @property {GristRecord[]} records - An array of fetched data records.
 */
export type FetchResult = {
  page?: number
  limit?: number
  total: number
  records: GristRecord[]
} | void

/**
 * A function that handles data fetching operations.
 *
 * @param param - The fetch options specifying how data should be retrieved.
 * @returns A promise that resolves to a `FetchResult`.
 */
export type FetchHandler = (param: FetchOption) => Promise<FetchResult>

/**
 * Represents a Grist event handler function.
 *
 * @typedef {Function} GristEventHandler
 * @param {ColumnConfig[]} columns - An array of column configurations.
 * @param {GristData} [data] - The Grist data associated with the event.
 * @param {ColumnConfig} [column] - The column configuration related to the event (if applicable).
 * @param {GristRecord} [record] - The Grist record related to the event (if applicable).
 * @param {number} [rowIndex] - The index of the row related to the event (if applicable).
 * @param {any} [target] - The target element or object related to the event (if applicable).
 * @param {Event} [e] - The event object (if applicable).
 * @returns {void}
 */
export type GristEventHandler = (
  columns: ColumnConfig[],
  data?: GristData,
  column?: ColumnConfig,
  record?: GristRecord,
  rowIndex?: number,
  target?: any,
  e?: Event
) => void

/**
 * Represents an accumulator function for data aggregation and accumulation.
 *
 * @typedef {string|'sum'|'avg'|'count'|'min'|'max'|Function} AccumulatorFunc
 * @param {GristData} data - The Grist data for aggregation.
 * @param {ColumnConfig} column - The column configuration for aggregation.
 * @returns {string|number} - The aggregated result.
 */
export type AccumulatorFunc =
  | 'sum'
  | 'avg'
  | 'count'
  | 'min'
  | 'max'
  | ((data: GristData, column: ColumnConfig) => string | number)

/**
 * Type representing an accumulator function or configuration object.
 *
 * An accumulator is used for data aggregation and accumulation purposes. It can be either
 * a predefined accumulator function (e.g., 'sum', 'avg', 'count', etc.) or a configuration
 * object specifying the type of accumulator and labeling options.
 *
 * @typedef {AccumulatorFunc|{type: AccumulatorFunc, tag?: boolean}} AccumulatorObject
 * @property {AccumulatorFunc} [type] - The type of accumulator function or predefined function name.
 * @property {boolean} [tag] - An option for adding a label to represent the result of an aggregation function. This label is added in front of the aggregated value.
 *
 * @example
 * // Using a predefined accumulator function:
 * const accumulator: AccumulatorObject = 'sum'; // Represents a 'sum' accumulator.
 *
 * // Using an accumulator configuration object:
 * const accumulatorConfig: AccumulatorObject = {
 *   type: 'avg', // Specifies the type of accumulator ('avg' for average).
 *   tag: true,   // Option for adding a label to represent the result of an aggregation function. This label is added in front of the aggregated value.
 * };
 */
export type AccumulatorObject =
  | AccumulatorFunc
  | {
      type: AccumulatorFunc
      tag?: boolean
    }

/**
 * Type representing column configuration information.
 *
 * @typedef {object} ColumnConfig
 * @property {string} type - Represents the data type of the column (e.g., 'text', 'number', 'date', 'gutter', etc.).
 * @property {string} name - Represents the name of the column.
 * @property {('button'|'sequence'|'row-selector'|'dirty')} [gutterName] - If the column type is 'gutter', specifies the name of one of the registered gutters (only set when the type is 'gutter').
 * @property {boolean} [fixed] - In case of a grid type column, specifies whether the column's position should be fixed and unaffected by horizontal scrolling (optional).
 * @property {HeaderConfig} header - Object representing the header configuration of the column.
 * @property {RecordConfig} record - Object representing the record configuration of the column.
 * @property {GristEventHandlerSet} handlers - Object representing the Grist event handler settings.
 * @property {LabelConfig} label - Object representing the label configuration of the column. This property is used to represent the label of the column's information in list mode, card mode, and when displaying the column in a filter form (ox-filters-form).
 * @property {boolean} [hidden] - Indicates whether the column is hidden or not (optional).
 * @property {boolean} [sortable] - Indicates whether the column is sortable or not (optional). If this setting is true, the header provides the ability to toggle the sorting direction.
 * @property {boolean} [resizable] - Indicates whether the column is resizable or not (optional). This setting determines whether the column's width can be adjusted.
 * @property {(number|string|ColumnWidthCallback)} [width] - Represents the width of the column (e.g., 100, '50%', or a callback function to calculate width).
 * @property {boolean} [forList] - Indicates whether the column is intended for display in list mode (optional, applicable only to gutter type columns).
 * @property {boolean} [forCard] - Indicates whether the column is intended for display in card mode (optional, applicable only to gutter type columns).
 * @property {ValidationCallback} [validation] - Callback function for column validation (optional). This function validates whether the value of the column is valid when edited.
 * @property {AccumulatorObject} [accumulator] - Object used for accumulating values of the column (optional, applicable only in grid mode). It specifies a function to automatically aggregate the values of the column for all records. The aggregated result is displayed in a row below the last record (accumulation record).
 * @property {FilterConfig} [filter] - Object representing the filter configuration of the column.
 * @property {(ImexConfig|boolean)} [imex] - Object or boolean value representing the configuration for importing and exporting column data.
 * @property {boolean} [multiple] - Indicates whether the column allows multiple selections or not (optional).
 * @property {boolean} [rowCount] - Indicates whether the column shows the row count or not (optional).
 */
export type ColumnConfig = {
  type: string
  name: string
  /**
   * "gutter" 타입의 컬럼인 경우, 등록된 gutter들 중의 하나의 이름을 명시합니다(type이 gutter인 경우에만 설정합니다).
   * (e.g., 'button', 'sequence', 'row-selector', 'dirty', etc)
   *
   * "Gutter"는 테이블 형태에서 각 행의 왼쪽 또는 오른쪽에 위치한 추가적인 열(column)을 가리킵니다.
   * 이 열은 주로 각 행(row)을 식별하거나 특정 작업을 수행하기 위한 버튼 또는 링크와 같은 요소를 표시하는 데 사용됩니다.
   * Gutter 열은 주로 데이터 행에 대한 조작 또는 행의 상태를 나타내는 데 사용됩니다.
   * 예를 들어, 테이블에서 각 행이 특정 작업을 수행하는 버튼을 포함할 때, 이러한 버튼이 Gutter 열에 배치될 수 있습니다.
   * 또한 Gutter 열은 행을 선택하거나 행의 상태를 나타내는 체크박스를 포함할 수도 있습니다.
   */
  gutterName?: 'button' | 'sequence' | 'row-selector' | 'dirty'
  title?: string
  fixed?: boolean
  /** [begin] only for button gutter column */
  icon?: string
  iconOnly?: boolean
  danger?: boolean
  /** [end] only for button gutter column */
  header: HeaderConfig
  record: RecordConfig
  handlers: GristEventHandlerSet
  label: LabelConfig
  hidden?: boolean
  sortable?: boolean
  resizable?: boolean
  width?: number | string | ColumnWidthCallback
  forList?: boolean
  forCard?: boolean
  validation?: ValidationCallback
  accumulator?: AccumulatorObject
  filter?: FilterConfig
  imex?: ImexConfig | boolean
  multiple?: boolean
  rowCount?: boolean
  /** 특정 도구에 의해서 사용되는 옵션으로, 일반적인 용도로 사용되지 않음을 표현할 수 있다. */
  unusable?: boolean
}

/**
 * Callback function type for column validation.
 *
 * This function is used to validate whether the value of a column is valid when edited.
 *
 * @callback ValidationCallback
 * @param {any} after - The new value of the column after editing.
 * @param {any} before - The previous value of the column before editing.
 * @param {GristRecord} record - The record being edited.
 * @param {ColumnConfig} column - The configuration of the column being edited.
 * @returns {boolean} - Returns `true` if the value is valid, `false` otherwise.
 */
export type ValidationCallback = (
  after: any,
  before: any,
  record: GristRecord,
  column: ColumnConfig
) => boolean | Promise<boolean>

/**
 * Configuration options for column labels.
 *
 * The label for a column can be customized using various options.
 *
 * @typedef {string|boolean|Object} LabelConfig
 * @property {LabelRenderer} [renderer] - A custom renderer function for the column label.
 *
 * @example
 * // Using a string as a label:
 * const label: LabelConfig = "Column Label"; // Sets the label as "Column Label".
 *
 * // Using a boolean to determine label visibility:
 * const label: LabelConfig = true; // Shows the default label based on the header configuration.
 *
 * // Using a custom renderer function for the label:
 * const label: LabelConfig = {
 *   renderer: customLabelRenderer, // Specifies a custom renderer function.
 * };
 */
export type LabelConfig =
  | string
  | boolean
  | {
      renderer: LabelRenderer
    }

/**
 * Custom label renderer function for generating column labels.
 *
 * @callback LabelRenderer
 * @param {ColumnConfig} column - The column configuration object for which the label is being generated.
 * @returns {void}
 */
export type LabelRenderer = (column: ColumnConfig) => void

/**
 * Custom callback function for calculating column width.
 *
 * @callback ColumnWidthCallback
 * @param {ColumnConfig} column - The column configuration object for which the width is being calculated.
 * @returns {string} - The calculated width as a string (e.g., '100', '50%').
 */
export type ColumnWidthCallback = (column: ColumnConfig) => string

/**
 * Configuration for defining the header of a column.
 *
 * @typedef {Object} HeaderConfig
 * @property {HeaderRenderer} renderer - The function used to render the column header.
 * @property {string} [style] - Optional CSS style for the column header.
 * @property {string} [group] - Optional group identifier for the column header.
 * @property {string} [groupStyle] - Optional CSS style for the header group.
 */
export type HeaderConfig = {
  renderer: HeaderRenderer
  style?: string
  group?: string
  groupStyle?: string
}

/**
 * Function type for rendering column headers.
 *
 * @callback HeaderRenderer
 * @param {ColumnConfig} column - The column configuration object.
 * @returns {*} - The rendered header content.
 */
export type HeaderRenderer = (column: ColumnConfig) => any

/**
 * Configuration for specifying default values for fields in a record.
 * Default values can be set using predefined value generator functions or custom user-defined functions.
 * This configuration allows you to define the default value generation method and any additional parameters.
 *
 * @typedef {Object|Function} DefaultValueFnConfig
 * @property {string} [name] - The name of the default value function to be used for the column.
 * @property {any[]} [params] - The parameters to be passed to the default value function.
 * @property {Function} [Function] - A custom user-defined function for generating default values.
 */
export type DefaultValueFnConfig =
  | {
      /**
       * The name of the default value function to be used for the column.
       */
      name: string

      /**
       * The parameters to be passed to the default value function.
       */
      params?: any[]
    }
  | Function

/**
 * Configuration for defining fields within a record.
 * Each field can have various settings such as rendering, editing, alignment, etc.
 * The 'defaultValue' property is used to specify the default value generation method and parameters.
 *
 * @typedef {Object} RecordConfig
 * @property {FieldRenderer} renderer - The rendering function for the field.
 * @property {FieldEditor|undefined} [editor] - The editing function for the field (optional).
 * @property {boolean|Function} [editable] - Specifies if the field is editable. It can be a boolean or a function (optional).
 * @property {boolean} [mandatory] - Specifies if the field is mandatory (optional).
 * @property {GristClassifier} classifier - A classifier function for the field.
 * @property {'left'|'right'|'center'} [align] - The alignment of the field's content (optional).
 * @property {*} options - Additional options for the field.
 * @property {string|undefined} [rowOptionField] - The name of a field for row-level options associated with this field (optional).
 * @property {DefaultValueFnConfig|undefined} [defaultValue] - Configuration for specifying the default value for the field.
 *                                                             It can be a predefined value generator function or a custom user-defined function (optional).
 * @property {Object.<string, *>|undefined} [extended] - Additional extended properties that can be added as needed for specific field configurations (optional).
 * @property {boolean} [wide] - Specifies if the field should occupy a larger width within the viewer or editor.
 *                              When set to `true`, the field will attempt to span across multiple columns or occupy more space horizontally.
 */
export type RecordConfig = {
  renderer: FieldRenderer
  editor?: FieldEditor
  editable?: boolean | Function
  mandatory?: boolean
  classifier: GristClassifier
  align?: 'left' | 'right' | 'center'
  options: any
  rowOptionField?: string
  defaultValue?: DefaultValueFnConfig
  wide?: boolean
  [extended: string]: any
}

/**
 * A function type used for rendering the content of a field in various components.
 *
 * @callback FieldRenderer
 * @param {*} value - The value of the field to be rendered.
 * @param {ColumnConfig} column - The configuration of the column to which the field belongs.
 * @param {GristRecord} record - The record object containing the field's data.
 * @param {number} rowIndex - The index of the row to which the field belongs.
 * @param {DataGridField|RecordCard|DataCardGutter|DataCardField|DataListGutter|DataListField|RecordPartial|DataReportField|Element} owner - The component or element that owns the field.
 * @returns {OxGristRenderer|TemplateResult|string|void} - The rendered content, which can be of different types depending on the implementation.
 */
export type FieldRenderer = (
  value: any,
  column: ColumnConfig,
  record: GristRecord,
  rowIndex: number,
  owner:
    | DataGridField
    | RecordCard
    | DataCardGutter
    | DataCardField
    | DataListGutter
    | DataListField
    | RecordPartial
    | DataReportField
    | Element
) => OxGristRenderer | TemplateResult | string | void

/**
 * A function type used for defining a custom editor for a field in a data grid.
 *
 * @callback FieldEditor
 * @param {*} value - The current value of the field.
 * @param {ColumnConfig} column - The configuration of the column to which the field belongs.
 * @param {GristRecord} record - The record object containing the field's data.
 * @param {number} rowIndex - The index of the row to which the field belongs.
 * @param {DataGridField} field - The data grid field that owns the editor.
 * @returns {OxGristEditor} - The custom editor component.
 */
export type FieldEditor = (
  value: any,
  column: ColumnConfig,
  record: GristRecord,
  rowIndex: number,
  field: DataGridField
) => OxGristEditor

/**
 * A function type used for rendering the filter select options in a filter form.
 *
 * @callback FilterSelectRenderer
 * @param {ColumnConfig} column - The configuration of the column for which the filter is being rendered.
 * @param {(string|number|boolean|string[]|number[]|any|undefined)} value - The current value of the filter.
 * @param {Element} owner - The DOM element that owns the filter select renderer.
 * @returns {(TemplateResult|string|void)} - The rendered filter select options as a Lit HTML template result, a string, or nothing.
 */
export type FilterSelectRenderer = (
  column: ColumnConfig,
  value: string | number | boolean | string[] | number[] | any | undefined,
  owner: Element
) => TemplateResult | string | void

/**
 * An object representing a set of event handler functions for various events in Grist.
 *
 * @typedef {Object} GristEventHandlerSet
 * @property {GristEventHandler} [click] - The click event handler function.
 * @property {GristEventHandler} [dblclick] - The double-click event handler function.
 * @property {GristEventHandler} [focus] - The focus event handler function.
 * @property {GristEventHandler} [contextmenu] - The context menu event handler function.
 */
export type GristEventHandlerSet = {
  click?: GristEventHandler
  dblclick?: GristEventHandler
  focus?: GristEventHandler
  contextmenu?: GristEventHandler
}

/**
 * Configuration options for defining how records are displayed in list mode in a Grist.
 *
 * @typedef {Object} ListConfig
 * @property {string} [thumbnail] - The name of the field to use as a thumbnail image for records in list mode.
 * @property {string[]} fields - An array of field names to display in the list mode.
 * @property {string[]} details - An array of field names to display as additional details in the list mode.
 */
export type ListConfig = {
  thumbnail?: string
  fields: string[]
  details: string[]
}

/**
 * Configuration options for managing tree-like structures in a Grist table.
 *
 * @typedef {Object} TreeConfig
 * @property {string} [childrenProperty] - The property name that specifies the children of a record.
 * @property {boolean | Function} [expanded] - Specifies whether tree nodes are initially expanded or a function to determine the initial expansion state for a given record.
 */
export type TreeConfig = {
  childrenProperty?: string
  expanded?: boolean | ((x: GristRecord) => boolean)
}

/**
 * Configuration options for importing and exporting column data.
 *
 * @typedef {Object} ImexConfig
 * @property {string} header - The header text for the column in import/export settings.
 * @property {string} key - The key or identifier for the column in import/export settings.
 * @property {number} width - The width of the column in import/export settings.
 * @property {string} type - The data type of the column in import/export settings.
 */
export type ImexConfig = {
  header: string
  key: string
  width: number
  type: string
}

/**
 * Configuration options for defining rows within a Grist table or view.
 *
 * @typedef {Object} RowsConfig
 * @property {boolean} [accumulator] - Specifies whether an accumulator row should be displayed.
 * @property {boolean} appendable - Indicates whether new rows can be appended to the table.
 * @property {boolean} insertable - Indicates whether new rows can be inserted into the table.
 * @property {RowSelectableConfig} [selectable] - Configuration for row selection options.
 * @property {GroupConfig[]} groups - An array of group configuration options.
 * @property {string[]} totals - An array of field names for which totals should be calculated and displayed.
 * @property {GristClassifier} classifier - A function that classifies rows based on their attributes.
 * @property {GristEventHandlerSet} handlers - An object containing event handler functions for various row-level events.
 */
export type RowsConfig = {
  accumulator?: boolean
  appendable: boolean
  editable: boolean
  insertable: boolean
  selectable?: RowSelectableConfig
  groups: GroupConfig[]
  totals: string[]
  classifier: GristClassifier
  handlers: GristEventHandlerSet
}

/**
 * A function used to classify and style individual records in a Grist table.
 *
 * @typedef {Function} GristClassifier
 *
 * @param {GristRecord} record - The record to be classified.
 * @param {number} rowIndex - The index of the row in the table.
 *
 * @returns {Object|void} An object containing classification properties or `void`.
 * - `emphasized` (optional): A boolean, string, or array of strings indicating the emphasis style.
 * - Additional properties can be added as needed for custom styling.
 */
export type GristClassifier = (
  record: GristRecord,
  rowIndex: number
) => { emphasized?: boolean | string | string[]; [key: string]: any } | void

/**
 * Configuration options for defining group headers in a Grist table.
 *
 * @typedef {Object} GroupConfig
 *
 * @property {string} align - The alignment of the group header ('left', 'center', or 'right').
 * @property {ColumnConfig} [titleColumn] - The column configuration used as the title of the group (optional).
 * @property {string} title - The title of the group.
 * @property {string} [value] - A value associated with the group (optional).
 * @property {string} [groupName] - The name of the group (optional).
 * @property {number} [row] - The row index where the group header should appear (optional).
 * @property {string|number} column - The column name or index where the group header should appear.
 * @property {number} rowspan - The number of rows the group header should span.
 * @property {number} [colspan] - The number of columns the group header should span (optional).
 */
export type GroupConfig = {
  align: string
  titleColumn?: ColumnConfig
  title: string
  value?: string
  groupName?: string
  row?: number
  column: string | number
  rowspan: number
  colspan?: number
}

/**
 * Configuration options for specifying row selectability in a Grist table.
 *
 * @typedef {Object} RowSelectableConfig
 *
 * @property {boolean} [multiple] - Indicates whether multiple rows can be selected (optional).
 */
export type RowSelectableConfig = {
  multiple?: boolean
}

/**
 * Represents a data record in Grist.
 *
 * @typedef {Object} GristRecord
 * @property {string} [id] - The unique identifier for the record.
 * @property {string} [name] - The name or label associated with the record.
 * @property {GristRecord[]} [children] - An array of child records, if applicable.
 * @property {number} [__seq__] - A numeric sequence identifier for the record.
 * @property {string} [__dirty__] - Indicates if the record is dirty (changed).
 * @property {boolean} [__selected__] - Indicates if the record is selected.
 * @property {object[]} [__changes__] - An array of changes made to the record.
 * @property {object} [__dirtyfields__] - An object representing dirty (changed) fields in the record.
 * @property {any} [__origin__] - The original data or source of the record.
 * @property {boolean} [__collapsed__] - Indicates if the record is collapsed (e.g., in a tree view).
 * @property {number} [__depth__] - The depth or level of the record in a hierarchical structure.
 * @property {'checked' | 'half-checked' | 'unchecked'} [__check_in_tree__] - Indicates the check state of the record in a tree view.
 * @property {GristRecord[]} [__children__] - An array of child records in a tree view.
 * @property {any} [key] - Additional custom properties specific to the record.
 */
export type GristRecord = {
  id?: string
  name?: string
  children?: GristRecord[]
  __seq__?: number
  __dirty__?: string
  __selected__?: boolean
  __changes__?: object[]
  __dirtyfields__?: { [key: string]: any }
  __origin__?: any
  __collapsed__?: boolean
  __depth__?: number
  __check_in_tree__?: 'checked' | 'half-checked' | 'unchecked'
  __children__?: GristRecord[]
  [key: string]: any
}

/**
 * Represents a set of data retrieved from a Grist table.
 *
 * @typedef {Object} GristData
 *
 * @property {number} [page] - The page number of the retrieved data (optional).
 * @property {number} [total] - The total number of records in the data source (optional).
 * @property {number} [limit] - The maximum number of records per page (optional).
 * @property {GristRecord[]} records - An array of fetched data records.
 */
export type GristData = {
  page?: number
  total?: number
  limit?: number
  records: GristRecord[]
}

/**
 * A function that determines whether a Grist record should be selected or not.
 *
 * @callback GristSelectFunction
 * @param {GristRecord} record - The Grist record to evaluate.
 * @returns {boolean} - `true` if the record should be selected, `false` otherwise.
 */
export type GristSelectFunction = (record: GristRecord) => boolean

/**
 * Defines a type for personalized grid settings. It includes individual column settings and additional configurations specific to a user's grid view.
 *
 * @typedef {Object} PersonalGristPreference
 * @property {Partial<ColumnConfig>[]} [columns] - Partially defines the configuration for each column in the grid.
 *   Each element can include only some properties of the `ColumnConfig`, used for optionally overriding column settings.
 * @property {any} [key] - Allows for storing additional user-defined properties with dynamic keys.
 *   This property can include various custom settings beyond the grid configuration, and the keys can be freely defined.
 */
export type PersonalGristPreference = {
  columns?: Partial<ColumnConfig>[]
  filters?: FilterPreference[]
  pagination?: PaginationConfig
  sorters?: SortersConfig
  mode?: 'GRID' | 'LIST' | 'CARD'
  [key: string]: any
}

export type FilterPreference = {
  name: string
  hidden?: boolean
  value?: any
}

export enum ValidationReason {
  MANDATORY = 'MANDATORY',
  FORMAT = 'FORMAT',
  RANGE = 'RANGE',
  UNIQUE = 'UNIQUE'
}

