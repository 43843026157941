import { css, html, LitElement, TemplateResult } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { ColumnConfig, GristRecord } from '../types'

@customElement('ox-card-field')
export class DataCardField extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        white-space: nowrap;

        font: inherit;
      }

      :host([thumbnail]) {
        flex-direction: column;
        justify-content: center;
      }

      :host([thumbnail]) > * {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }

      :host > * {
        margin: var(--spacing-none);

        overflow: hidden;

        text-overflow: ellipsis;
        text-align: left;
      }

      :host > *[center] {
        flex: none;
        margin: var(--spacing-none) auto;
      }

      :host([name]) label {
        display: none;
      }

      label {
        flex: none;
        width: 33%;
        padding-bottom: 2px;
        font: var(--data-card-item-etc-label-font);
        text-transform: capitalize;
      }

      :host([name]) > span,
      label + a {
        display: block;
        min-width: 100%;
      }

      @media only screen and (max-width: 460px) {
        *[center] {
          margin: initial;
        }
      }
    `
  ]

  @property({ attribute: true }) align?: string
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex: number = -1
  @property({ type: Object }) value?: object

  render(): TemplateResult {
    var { value, column, record, rowIndex } = this

    var {
      label,
      record: { renderer: recordRenderer }
    } = column

    if (typeof label == 'object') {
      let { renderer: labelRenderer } = label
      return html`<label>${labelRenderer(column)}</label>${recordRenderer(value, column, record, rowIndex, this)}`
    } else {
      return html`${recordRenderer(value, column, record, rowIndex, this)}`
    }
  }
}
