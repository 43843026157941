import { formatter } from './formatter'

export type RelativeDateParams = {
  relativeDays?: number
  timeZone?: string
  locale?: string
  format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions
}

export const getRelativeDate = (params: RelativeDateParams = {}): Date | number | string => {
  const { relativeDays = 0, timeZone, locale, format } = params
  const relativeSeconds = relativeDays * 24 * 60 * 60

  const now = new Date()
  let currentDate: Date

  if (timeZone) {
    const options: Intl.DateTimeFormatOptions = { timeZone }
    const currentDateISOString = now.toLocaleString('en-US', options).split(',')[0]
    currentDate = new Date(currentDateISOString)
  } else {
    currentDate = now
  }

  const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
  const targetTime = startOfDay.getTime() + relativeSeconds * 1000

  return formatter(targetTime, params)
}
