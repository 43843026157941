import { DataGridField } from '../data-grid/data-grid-field'
import { ColumnConfig, GristData, GristRecord } from '../types'

/*
 * handler들은 ox-grid-field 로부터 호출되는 것을 전제로 하며,
 * 전반적인 처리를 위해서, columns 및 data 정보를 포함해서 제공할 수 있어야 한다.
 */

export const MoveUp = function (
  columns: ColumnConfig[],
  data: GristData,
  column: ColumnConfig,
  record: GristRecord,
  rowIndex: number,
  field: DataGridField
): void {
  var moveTo = rowIndex - 1
  var length = data.records.length

  if (rowIndex >= length || moveTo < 0 || moveTo >= length) {
    return
  }

  data.records.splice(rowIndex, 1)
  data.records.splice(moveTo, 0, record)

  field.dispatchEvent(
    new CustomEvent('record-change', {
      bubbles: true,
      composed: true
    })
  )

  field.dispatchEvent(
    new CustomEvent('focus-change', {
      bubbles: true,
      composed: true,
      detail: {
        row: moveTo,
        column: column
      }
    })
  )
}
