import { ColumnConfig } from '../types'
import { GutterButton } from './gutter-button'
import { GutterDirty } from './gutter-dirty'
import { GutterRowSelector } from './gutter-row-selector'
import { GutterSequence } from './gutter-sequence'

var GUTTERS: { [name: string]: any } = {
  sequence: GutterSequence,
  'row-selector': GutterRowSelector,
  button: GutterButton,
  dirty: GutterDirty
}

export function registerGutter(name: string, gutter: any) {
  GUTTERS[name] = gutter
}

export function unregisterGutter(name: string) {
  delete GUTTERS[name]
}

export function getGutters() {
  return { ...GUTTERS }
}

export const generateGutterColumn = (config: ColumnConfig) => {
  var clazz = GUTTERS[config.gutterName || '']

  if (clazz) {
    return clazz.instance(config)
  }
}
