import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit'
// import { guard } from 'lit/directives/guard.js'
import { customElement, property } from 'lit/decorators.js'

import { TooltipReactiveController } from '@operato/utils'

import { ZERO_COLUMN } from '../configure/zero-config'
import { ColumnConfig, GristRecord } from '../types'

const DEFAULT_TEXT_ALIGN = 'left'

@customElement('ox-grid-field')
export class DataGridField extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;

        align-items: center;
        justify-content: var(--data-grid-field-justify-content, flex-start);
        position: relative;

        white-space: nowrap;
        background-color: var(--grid-record-background-color);
        padding: var(--grid-record-padding);
        border: 1px solid transparent;
        border-width: 1px 0;
        border-bottom: var(--grid-record-border-bottom);

        font-size: var(--grid-record-wide-fontsize);
        min-height: 30px;
        box-sizing: border-box;
      }

      :host([gutter]) {
        padding: var(--grid-gutter-padding);
        text-align: center;
      }

      :host([gutter]) * {
        cursor: default;
      }

      :host([editing]) {
        border-top: var(--grid-record-editing-border);
        border-bottom: var(--grid-record-editing-border);
      }

      :host([focused]) {
        --grid-record-border-bottom: var(--grid-record-focused-cell-border);
      }

      :host([dirty])::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;

        width: 0px;
        height: 0px;
        border-top: 9px solid red;
        border-left: 9px solid transparent;
      }

      span,
      pre {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      * {
        margin: 0;
        text-align: var(--data-grid-field-text-align);
      }

      *[center] {
        flex: none;
        margin: 0 auto;
      }

      :host > button {
        display: flex;
        gap: var(--spacing-small);
        border: 0;
        align-items: center;
        padding: var(--spacing-small);

        color: var(--md-sys-color-on-primary);
        background-color: var(--md-sys-color-primary);
        border-radius: var(--data-card-item-btn-border-radius, var(--md-sys-shape-corner-tiny));

        font-size: 0.8rem;
        line-height: 0.8rem;

        md-icon {
          border: 0;
          padding: 0;
          background-color: unset;
        }
      }

      :host([danger]) > button {
        color: var(--md-sys-color-on-primary);
        background-color: var(--md-sys-color-error);
        border-color: var(--md-sys-color-error);
      }

      :host([danger]) > button:hover {
        color: var(--md-sys-color-on-secondary);
        background-color: var(--md-sys-color-secondary);
        border-color: var(--md-sys-color-secondary);
      }
    `
  ]

  @property({ type: String, attribute: true }) align = DEFAULT_TEXT_ALIGN
  @property({ type: Object }) record: GristRecord = {}
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex = 0
  @property({ type: Number }) columnIndex = 0
  @property({ type: Boolean }) editing = false
  @property({ type: Object }) value = {}
  @property({ type: String }) valueWith: string | null = null
  @property({ attribute: false }) emphasized: any = false
  @property({ type: String, attribute: true }) fixed?: string
  @property({ type: String }) type?: string
  @property({ type: Boolean }) isWorking: boolean = false

  /* check in tree의 변화에 대응하기 위함임. 만일, record control 오브젝트로 통일되면 대체될 것임. */
  @property({ type: String }) checked?: string

  private _editCancelled?: boolean
  private _onFieldChange: (e: Event) => void = e => {}
  private _onKeydownInEditingMode: (e: KeyboardEvent) => void = e => {}

  private tooltipController?: TooltipReactiveController = new TooltipReactiveController(this)

  render(): TemplateResult {
    if (!this.column) {
      return html``
    }

    var { value, column, record, rowIndex } = this
    var { renderer, editor } = column.record

    return html`
      ${this.editing
        ? editor?.call(this, value, column, record, rowIndex, this)
        : renderer?.call(this, value, column, record, rowIndex, this)}
    `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('editing')) {
      if (this.editing) {
        this._onKeydownInEditingMode = ((e: KeyboardEvent) => {
          if (e.key === 'Esc' || e.key === 'Escape') {
            /* 편집 취소 */
            this._editCancelled = true
          }
        }).bind(this)

        this._onFieldChange = ((e: Event) => {
          this._editCancelled && e.stopPropagation()
        }).bind(this)

        delete this._editCancelled
        this.addEventListener('field-change', this._onFieldChange)
        this.addEventListener('keydown', this._onKeydownInEditingMode)
      } else {
        this.removeEventListener('field-change', this._onFieldChange)
        this.removeEventListener('keydown', this._onKeydownInEditingMode)
      }
    }

    if (changes.has('column')) {
      var align = this.column.record.align || DEFAULT_TEXT_ALIGN
      if (align != DEFAULT_TEXT_ALIGN) {
        let justify = 'center'
        switch (align) {
          case 'right':
            justify = 'flex-end'
            break
        }
        this.style.setProperty('--data-grid-field-justify-content', justify)
        this.style.setProperty('--data-grid-field-text-align', align)
      }

      const { danger } = this.column
      if (danger) {
        this.setAttribute('danger', '')
      } else {
        this.removeAttribute('danger')
      }
    }

    if (changes.has('fixed')) {
      if (this.fixed) {
        this.style.left = this.fixed + 'px'
      } else {
        this.style.left = 'unset'
      }
    }

    const emphasized =
      this.column.record.classifier.call(this, this.record, this.rowIndex)?.emphasized || this.emphasized

    if (!!emphasized) {
      this.setAttribute('emphasized-row', '')

      if (Symbol.iterator in Object(emphasized)) {
        const [backgroundColor, foregroundColor] = emphasized as string[]
        backgroundColor && this.style.setProperty('--grid-record-emphasized-background-color', backgroundColor)
        foregroundColor && this.style.setProperty('--grid-record-emphasized-color', foregroundColor)
      }
    } else {
      this.removeAttribute('emphasized-row')
    }
  }

  get editableOnClick() {
    const renderer = this.renderRoot.firstElementChild as HTMLElement
    //@ts-ignore
    return renderer && 'editableOnClick' in renderer ? renderer.editableOnClick : true
  }
}
