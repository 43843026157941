/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'
import '@operato/popup/ox-popup-list.js'
import './ox-checkbox.js'

import { css, html, render, PropertyValues } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'

import { OxPopupList } from '@operato/popup'

import { OxFormField } from './ox-form-field.js'

@customElement('ox-select')
export class OxSelect extends OxFormField {
  static styles = [
    css`
      :host {
        display: block;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        --ox-select-padding: var(--spacing-tiny);
        --ox-select-font: var(--input-font);
        --ox-select-color: var(--input-color, var(--md-sys-color-on-surface-variant));
        --ox-select-icon-color: var(--theme-primary-text-color, var(--md-sys-color-on-surface-variant));
        --ox-select-icon-hover-color: var(--md-sys-color-on-primary-container, #3c3938);
      }

      div {
        width: 100%;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: var(--ox-select-padding);
        font: var(--ox-select-font);
        color: var(--ox-select-color);
      }

      span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        gap: 4px;
      }

      md-icon {
        --md-icon-size: 16px;
        display: block;
        text-align: right;
        color: var(--ox-select-icon-color);
        opacity: 0.7;
      }

      div:hover md-icon {
        color: var(--md-sys-color-on-primary-container);
      }
    `
  ]

  @property({ type: String }) name: string = ''
  @property({ type: String }) placeholder: string = ''

  @state() label: string | string[] = ''
  @state() popupContainer: HTMLElement | null = null
  @state() observer: MutationObserver | null = null

  render() {
    const label =
      (this.label instanceof Array ? this.label.join(', ') : this.label?.trim()) ||
      (this.value instanceof Array ? this.value.join(', ') : this.value?.trim()) ||
      this.placeholder ||
      ''

    return html`
      <div @click=${this.expand}>
        <span data-reactive-tooltip>${label}</span>
        <md-icon>expand_more</md-icon>
      </div>

      <slot></slot>
    `
  }

  connectedCallback() {
    super.connectedCallback()

    this.setAttribute('tabindex', '0')

    this.addEventListener('keydown', (e: KeyboardEvent) => {
      e.preventDefault()

      if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'ArrowDown') {
        this.expand()
      }
    })

    this.addEventListener('click', () => this.expand())
  }

  async updated(changes: PropertyValues<this>) {
    if (changes.has('value')) {
      const popupList = (this.popupContainer?.querySelector('ox-popup-list') ||
        this.querySelector('ox-popup-list')) as OxPopupList

      if (popupList) {
        popupList.value = this.value
        await this.requestUpdate()

        this.label = popupList.getSelectedLabels()
      }
    }
  }

  setOptions(
    options: string[] | { display: string; value: string }[],
    opt: { multiple?: boolean; withSearch?: boolean } = {}
  ) {
    const objOptions = options.map(option => {
      return typeof option == 'string' ? { display: option, value: option } : option
    })

    const { multiple, withSearch } = opt || {}

    const template = html`
      <ox-popup-list
        align-left
        nowrap
        ?multiple=${multiple}
        attr-selected=${multiple ? 'checked' : ''}
        ?with-search=${withSearch}
      >
        ${multiple
          ? html`<ox-checkbox
                @change=${(e: Event) => {
                  const target = e.target as HTMLInputElement
                  const options = Array.from(target.parentElement!.querySelectorAll('[option]')).filter(
                    option => !option.hasAttribute('hidden')
                  )
                  options.forEach(option => ((option as HTMLInputElement).checked = target.checked))

                  this.value = options
                    .map(option =>
                      (option as HTMLInputElement).checked ? (option as HTMLInputElement).value : undefined
                    )
                    .filter(Boolean)
                }}
                >set all</ox-checkbox
              >
              ${objOptions.map(
                option => html` <ox-checkbox option value=${option.value}>${option.display}</ox-checkbox> `
              )}`
          : html`${objOptions.map(option => html` <div option value=${option.value}>${option.display}</div> `)}`}
      </ox-popup-list>
    `

    render(template, this)
  }

  expand() {
    if (this.disabled) {
      return
    }

    const slotContent = this.renderRoot.querySelector('slot')?.assignedNodes() || []

    if (slotContent.length > 0) {
      const popupList = slotContent.find(content => content instanceof OxPopupList) as OxPopupList

      if (popupList) {
        const { left, bottom } = this.getBoundingClientRect()

        popupList.value = this.value
        popupList.style.width = `${this.offsetWidth}px`
        popupList.style.textWrap = 'nowrap'

        const selectHandler = async (e: Event) => {
          this.value = (e as CustomEvent).detail

          if (popupList) {
            await this.requestUpdate()
            this.label = popupList.getSelectedLabels()
          }
        }
        popupList.addEventListener('select', selectHandler)

        const closeHandler = (e: Event) => {
          /* popup이 close될 때 change 이벤트를 발생시킨다. */
          this.dispatchEvent(
            new CustomEvent('change', {
              bubbles: true,
              composed: true,
              detail: this.value
            })
          )
          popupList.removeEventListener('select', selectHandler)
          popupList.removeEventListener('close', closeHandler)

          this.appendChild(popupList)
        }
        popupList.addEventListener('close', closeHandler, { once: true })

        document.body.appendChild(popupList)

        popupList.open({
          left,
          top: bottom
        })
      }
    }
  }
}
