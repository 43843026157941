import { NOOP } from '../const'
import { RowsConfig } from '../types'
import { getGristEventHandler } from '../handlers'

export const buildRowsOptions = (rows: any): RowsConfig => {
  var {
    appendable = true,
    editable = true,
    insertable = false,
    selectable,
    groups = [],
    totals = [],
    classifier,
    accumulator
  } = rows

  /* handler */
  var { click, dblclick, focus } = rows.handlers || {}
  if (typeof classifier !== 'function') {
    classifier = NOOP
  }

  return {
    accumulator,
    appendable,
    editable,
    insertable,
    selectable,
    groups,
    totals,
    handlers: {
      click: getGristEventHandler(click),
      dblclick: getGristEventHandler(dblclick),
      focus: getGristEventHandler(focus)
    },
    classifier
  }
}
