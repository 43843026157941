import { GristEventHandler } from '../types'
import { ContextMenuTreeMutation } from './contextmenu-tree-mutation'
import { MoveDown } from './move-down'
import { MoveUp } from './move-up'
import { RecordCopy } from './record-copy'
import { RecordDelete } from './record-delete'
import { RecordViewHandler } from './record-view-handler'
import { SelectRow } from './select-row'
import { SelectRowToggle } from './select-row-toggle'

var HANDLERS: {
  [name: string]: GristEventHandler
} = {
  'record-view': RecordViewHandler as GristEventHandler,
  'select-row': SelectRow as GristEventHandler,
  'select-row-toggle': SelectRowToggle as GristEventHandler,
  'move-up': MoveUp as GristEventHandler,
  'move-down': MoveDown as GristEventHandler,
  'record-copy': RecordCopy as GristEventHandler,
  'record-delete': RecordDelete as GristEventHandler,
  'contextmenu-tree-mutation': ContextMenuTreeMutation as GristEventHandler
}

export function registerGristEventHandler(type: string, handler: GristEventHandler) {
  HANDLERS[type] = handler
}

export function unregisterGristEventHandler(type: string) {
  delete HANDLERS[type]
}

export function getGristEventHandlers() {
  return { ...HANDLERS }
}

export function getGristEventHandler(type: string | GristEventHandler): GristEventHandler {
  if (typeof type == 'function') {
    return type
  }

  return HANDLERS[type]
}
