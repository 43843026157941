export type RelativeWeekDateParams = {
  timeZone?: string
  locale?: string
  format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions
  relativeWeeks?: number
  relativeDays?: number
}

export const getRelativeWeekDate = (params: RelativeWeekDateParams = {}): Date | number | string => {
  const { relativeWeeks = 0, relativeDays = 0, timeZone, format, locale } = params

  const now = new Date()
  let currentDate: Date

  if (timeZone) {
    const options: Intl.DateTimeFormatOptions = { timeZone }
    const currentDateISOString = now.toLocaleString('en-US', options).split(',')[0]
    currentDate = new Date(currentDateISOString)
  } else {
    currentDate = now
  }

  const startOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay() + 1
  )

  const targetDate =
    startOfWeek.getTime() + relativeWeeks * 7 * 24 * 60 * 60 * 1000 + relativeDays * 24 * 60 * 60 * 1000

  if (format === 'date') {
    return new Date(targetDate)
  } else if (format === 'timestamp') {
    return targetDate
  } else if (format || locale) {
    try {
      const safeLocale = locale || 'en-US'
      const formatter = new Intl.DateTimeFormat(safeLocale, format)
      return formatter.format(new Date(targetDate))
    } catch (e) {
      console.warn('Invalid locale or format options provided. Falling back to default.', e)

      const fallbackFormatter = new Intl.DateTimeFormat('en-US')
      return fallbackFormatter.format(new Date(targetDate))
    }
  } else {
    return targetDate
  }
}
