/**
 * ox-grid-body 의 click handler
 *
 * - handler의 this 는 ox-grid-body임.
 */

import { DataGridBody } from '../data-grid-body'
import { DataGridField } from '../data-grid-field'

export function dataGridBodyClickHandler(this: DataGridBody, e: Event): void {
  e.stopPropagation()

  if ((e as MouseEvent).detail === 2) {
    return;
  }

  if (this.editTarget) {
    /* editTarget이 새로 설정되지 않았다면, 이후 기능이 실행된다. */
    return
  }

  /* target should be 'ox-grid-field' */
  var target = (e.target as Element).closest('ox-grid-field') as DataGridField
  var { column, record, rowIndex, columnIndex } = target || {}

  // if (!column) {
  //   /* 여백 컬럼이 클릭된 경우 */
  //   return
  // }

  // this.dispatchEvent(
  //   new CustomEvent('focus-change', {
  //     bubbles: true,
  //     composed: true,
  //     detail: {
  //       row: rowIndex,
  //       column: columnIndex
  //     }
  //   })
  // )

  /* 만약, 클릭 이벤트에 포커스만 바꾸고 싶다면, 아래 코멘트를 제거한다. */
  /*  
    this.resetEdit()
  */

  /* 만약, 클릭 이벤트에 포커스만 바꾸고 싶다면, 아래 부분을 코멘트처리한다. */
  // if (!isNaN(rowIndex) && !isNaN(columnIndex)) {
  //   this.startEditTarget(rowIndex, columnIndex)
  //   if (this.editTarget) {
  //     /* editTarget이 새로 설정되지 않았다면, 이후 기능이 실행된다. */
  //     return
  //   }
  // } else {
  //   console.error('should not be here.')
  //   return
  // }
  /* 만약, 클릭 이벤트에 포커스만 바꾸고 싶다면, 여기까지 코멘트 처리한다. */

  /* do column click handler */
  if (column) {
    var { click } = column.handlers
    click && click(this.columns, this.data, column, record, rowIndex, target, e)
  }

  /* do rows click handler */
  var { click: rowsClick } = this.config.rows.handlers
  rowsClick && rowsClick(this.columns, this.data, column, record, rowIndex, target, e)
}
