import { html } from 'lit'
import { customElement } from 'lit/decorators.js'

import { OxGristEditor } from './ox-grist-editor.js'

@customElement('ox-grist-editor-file')
export class OxGristEditorFile extends OxGristEditor {
  _onchange(e: Event) {
    e.stopPropagation()

    const input = e.target as HTMLInputElement

    this._dirtyValue = this.formatFromEditor(input.files)

    this._onfocusout()
  }

  formatFromEditor(value: any): any {
    return value
  }

  get editorTemplate() {
    var value = typeof this.value === 'string' ? null : this.value
    var { multiple = false } = this.column.record || {}

    return html` <input style="opacity: 1" type="file" ?multiple=${multiple} /> `
  }
}
