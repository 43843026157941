const ERROR = '[ERROR]'
const WARN = '[WARN]'
const DEBUG = '[DEBUG]'

/**
 * Logs an error message with optional stack trace.
 *
 * @param {...any} args - The error message and optional additional data.
 */
export var error = (...args: any[]) => {
  var trace = [] as string[]
  args.forEach(arg => arg && arg.stack && trace.push(arg.stack))
  console.error(ERROR, ...args, trace.join(' '))
}

/**
 * Logs a warning message with optional stack trace.
 *
 * @param {...any} args - The warning message and optional additional data.
 */
export var warn = (...args: any[]) => {
  var trace = [] as string[]
  args.forEach(arg => arg && arg.stack && trace.push(arg.stack))
  console.warn(WARN, ...args, trace.join(' '))
}

/**
 * Logs a debug message.
 *
 * @param {...any} args - The debug message and optional additional data.
 */
export var debug = (...args: any[]) => console.log(DEBUG, ...args)
