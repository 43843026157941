import { auth } from './auth'

var profileResolved = false
var user
var languages
var domain
var domains

const profileReady = new Promise<void>(resolve => {
  auth.on('profile', (data: { credential; domains; domain; languages }) => {
    profileResolved = true

    user = data.credential
    languages = data.languages
    domain = data.domain
    domains = data.domains

    resolve()
  })
})

export async function hasPrivilege({
  privilege,
  category,
  domainOwnerGranted,
  superUserGranted
}: {
  privilege?: string
  category?: string
  domainOwnerGranted?: boolean
  superUserGranted?: boolean
}) {
  if (!profileResolved) {
    await profileReady
  }

  const { privileges, owner, super: superUser, unsafeIP } = user

  if (unsafeIP) {
    if (privilege && category) {
      // unsafeIP 상황에서는 ownership granted는 적용되지 않는다.
      return (privileges || []).find(p => p.privilege == privilege && p.category == category)
    }

    // privileage, category가 설정되지 않은 경우에는 ownership granted가 설정되었다면 허가하지 않는다.
    return !domainOwnerGranted && !superUserGranted
  } else {
    if (!privilege || !category) {
      // privileage, category가 설정되지 않은 경우에는 ownership granted만을 적용한다.
      return (domainOwnerGranted && owner) || (superUserGranted && superUser)
    }

    if ((domainOwnerGranted && owner) || (superUserGranted && superUser)) {
      return true
    }

    return (privileges || []).find(p => p.privilege == privilege && p.category == category)
  }
}

export async function getLanguages() {
  if (!profileResolved) {
    await profileReady
  }

  return languages
}

export async function getDomain() {
  if (!profileResolved) {
    await profileReady
  }

  return domain
}

export async function getDomains() {
  if (!profileResolved) {
    await profileReady
  }

  return domains
}
