import '@operato/popup/ox-popup.js'
import '@material/web/icon/icon.js'

import { css, html, LitElement, PropertyValues, nothing, TemplateResult } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import throttle from 'lodash-es/throttle'

import { OxPopup } from '@operato/popup'
import { TooltipReactiveController, closestElement } from '@operato/utils'

import { ZERO_COLUMNS, ZERO_DATA } from '../configure/zero-config'
import { FilterStyles } from '../filters/filter-styles'
import { getFilterRenderer } from '../filters/registry'
import { ColumnConfig, FilterConfigObject, FilterValue, GristData, SortersConfig } from '../types'

@customElement('ox-grid-header')
export class DataGridHeader extends LitElement {
  static styles = [
    FilterStyles,
    css`
      :host {
        position: relative;
        display: grid;
        grid-template-columns: var(--grid-template-columns);

        border-top: var(--grid-header-top-border);
        overflow: hidden;

        font-variation-settings: 'FILL' 1;

        user-select: none;
      }

      :host([raised]) [fixed] {
        /* 고정 컬럼이 살짝 올라와 있는 느낌을 표현 */
        box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
      }

      div[column] {
        display: flex;

        white-space: nowrap;
        overflow: hidden;
        background-color: var(--grid-header-background-color);
        border-bottom: var(--grid-header-bottom-border);
        padding: var(--grid-header-padding);

        text-overflow: ellipsis;
        font: var(--grid-header-font);
        color: var(--grid-header-color);
      }

      div[gutter] {
        padding: var(--spacing-small) 0;
        text-align: center;
      }

      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
      }

      span[for-title] {
        flex: 1;
        text-overflow: ellipsis;
        line-height: 1.6;
        text-transform: var(--grid-header-text-transform, capitalize);
        align-self: center;
      }

      span[for-title] * {
        vertical-align: middle;
      }

      span[for-title] md-icon {
        font-size: 1.2em;
      }

      span[sorter],
      span[filter] {
        display: flex;
        align-self: center;

        padding: 0;
        border: 0;
      }

      span[sorter] md-icon {
        font-size: var(--grid-header-sorter-size);
      }

      span[filter] > md-icon {
        font-size: var(--fontsize-default);
        line-height: 20px;
      }

      span[splitter] {
        cursor: col-resize;
        border-right: var(--grid-header-splitter-border);
        margin-right: 0;
      }

      span[splitter]:hover {
        border-right: var(--grid-header-splitter-border-hover);
      }

      [filter-title] {
        color: var(--grid-header-filter-title-color);
        font: var(--grid-header-filter-title-font);
        text-transform: capitalize;
      }

      [filter-title] * {
        vertical-align: middle;
      }

      [filter-title] md-icon {
        opacity: 0.7;
        color: var(--grid-header-filter-title-icon-color);
      }

      [filter] input[type='checkbox'] {
        margin-left: var(--spacing-medium);
        margin-bottom: var(--spacing-small);
      }

      [fixed] {
        position: sticky;
        z-index: 2;
      }

      .span-both {
        grid-row: 1 / span 2; /* 1단과 2단에 걸쳐 표시 */
      }

      .group-header {
        grid-row: 1; /* 1행에 배치 */
        grid-column: var(--group-start) / span var(--group-size); /* 2열부터 시작하여 2열에 걸쳐 표시 */
      }

      @media print {
        :host {
          grid-template-columns: var(--grid-template-print-columns);
        }
      }
    `
  ]

  @property({ type: Array }) columns: ColumnConfig[] = ZERO_COLUMNS
  @property({ type: Object }) data: GristData = ZERO_DATA
  @property({ type: Array }) sorters: SortersConfig = []
  @property({ type: Array }) filters: FilterValue[] = []
  @property({ type: Boolean, attribute: 'filtering-feature' }) filteringFeature: boolean = false

  @state() private row1: {
    index: number
    column: ColumnConfig
    clazz?: string
    start?: number
    size?: number
    align?: string
    group?: string
  }[] = []

  @state() private row2: {
    index: number
    column: ColumnConfig
  }[] = []

  private _lastAccVal?: number
  private _throttledNotifier?: any

  private tooltipController?: TooltipReactiveController = new TooltipReactiveController(this)

  connectedCallback() {
    super.connectedCallback()

    const grid = closestElement('ox-grist', this)

    this.addEventListener('scroll', function () {
      this.scrollLeft == 0 ? this.removeAttribute('raised') : this.setAttribute('raised', '')
    })

    if (this.filteringFeature) {
      this?.addEventListener('filter-change', (e: Event) => {
        const { name, operator, value } = (e as CustomEvent).detail
        const filters = this.filters instanceof Array ? [...this.filters] : []

        if (value == null) {
          const index = filters.findIndex(filter => filter.name === name)
          if (index === -1) {
            return
          }

          filters.splice(index, 1)
        } else {
          const index = filters.findIndex(filter => filter.name === name)
          if (index === -1) {
            filters.push({ name, operator, value })
          } else {
            filters.splice(index, 1, { name, operator, value })
          }
        }

        grid?.dispatchEvent(
          new CustomEvent('fetch-params-change', {
            detail: {
              filters,
              from: 'data-grid-header'
            }
          })
        )
      })
    }
  }

  render() {
    const clazz = this.row2.length > 0 ? 'span-both' : undefined

    return html`
      ${this.row1.map(({ column, clazz, start, size, align, index, group }) =>
        this.renderHeaderColumn({ column, clazz, start, size, align, index, group })
      )}

      <div column class=${ifDefined(clazz)}></div>

      ${this.row2.map(({ column, index }) => this.renderHeaderColumn({ column, index }))}
    `
  }

  renderHeaderColumn({ column, clazz, start, size, align, index, group }: any): TemplateResult {
    if (column.hidden) {
      return html`${nothing}`
    }

    return html`
      <div
        ?gutter=${column.type == 'gutter'}
        ?fixed=${column.fixed}
        column
        class=${ifDefined(clazz)}
        style=${group
          ? `--group-start:${start};--group-size:${size};${column.header?.style || ''}`
          : `${column.header?.style || ''}`}
      >
        <span
          for-title
          data-reactive-tooltip
          style="text-align:${align || column.record.align || 'left'};"
          @click=${(e: MouseEvent) => this._changeSort(column)}
          >${this._renderHeader(column)}
        </span>

        ${!group && column.sortable
          ? html`
              <span sorter @click=${(e: MouseEvent) => this._changeSort(column)}>
                ${this._renderSortHeader(column)}
              </span>
            `
          : nothing}
        ${!group &&
        this.filteringFeature &&
        column.filter &&
        (column.filter as FilterConfigObject).operator !== 'search'
          ? html` <span filter> ${this._renderFilterHeader(column)} </span> `
          : nothing}
        ${column.resizable !== false
          ? html`
              <span splitter draggable="false" @pointerdown=${(e: PointerEvent) => this._pointerdown(e, index)}
                >&nbsp;</span
              >
            `
          : nothing}
      </div>
    `
  }

  notifyFixedLeftChange() {
    const fixedHeaders = Array.from(this.renderRoot.querySelectorAll('div[fixed]')) as HTMLElement[]
    const fixedLefts = [] as number[]
    var left = 0
    fixedHeaders.forEach((header: HTMLElement) => {
      header.style.left = left + 'px'
      fixedLefts.push(left)

      const width = header.offsetWidth
      left += width
    })

    this.dispatchEvent(
      new CustomEvent('fixed-lefts-change', {
        detail: fixedLefts
      })
    )
  }

  updated(changes: PropertyValues) {
    var isColumnWidthChangePossible = false

    if (changes.has('columns')) {
      this.row2 = this.columns.reduce((row2, column, index) => {
        if (column.hidden || !column.header?.group) {
          return row2
        }
        return row2.concat({
          index,
          column
        } as any)
      }, [] as any[])

      const clazz = this.row2.length > 0 ? 'span-both' : undefined

      var columnNo = 0

      this.row1 = this.columns.reduce((row1, column, index) => {
        if (column.hidden) {
          return row1
        }

        columnNo++
        if (!column.header?.group) {
          return row1.concat({
            index,
            column,
            clazz
          } as any)
        }
        const { group, groupStyle } = column.header
        const last = row1[row1.length - 1] as any

        if (!last || group !== last.group) {
          return row1.concat({
            index,
            column: {
              ...column,
              header: {
                renderer: () => group,
                style: groupStyle
              }
            },
            group,
            align: 'center',
            clazz: 'group-header',
            start: columnNo,
            size: 1
          } as any)
        }

        last.size++

        return row1
      }, [] as any[])

      isColumnWidthChangePossible = true
    }

    if (changes.has('data')) {
      isColumnWidthChangePossible = true
    }

    if (isColumnWidthChangePossible) {
      // requestAnimationFrame(() => this.notifyFixedLeftChange())
      this.notifyFixedLeftChange()
    }
  }

  _renderHeader(column: ColumnConfig) {
    var { renderer } = column.header || {}
    var title = renderer.call(this, column)

    return html`${column?.record?.mandatory ? '*' : ''} ${title} `
  }

  _renderSortHeader(column: ColumnConfig) {
    var sorters = this.sorters || []

    var sorter = sorters.find(sorter => column.type !== 'gutter' && column.name == sorter.name)
    if (!sorter) {
      return html`<md-icon style="opacity: 0.2;">unfold_more</md-icon>`
    }

    if (sorters.length > 1) {
      var rank = sorters.indexOf(sorter) + 1
      return sorter.desc
        ? html` <md-icon>keyboard_arrow_down</md-icon><sub>${rank}</sub> `
        : html` <md-icon>keyboard_arrow_up</md-icon><sub>${rank}</sub> `
    } else {
      return sorter.desc ? html` <md-icon>keyboard_arrow_down</md-icon> ` : html` <md-icon>keyboard_arrow_up</md-icon> `
    }
  }

  _renderFilterHeader(column: ColumnConfig) {
    const name = column.name
    const filter = column.filter as FilterConfigObject
    const type = filter.type
    const value = this.filters.find(filter => filter.name === name)?.value
    const idx = filter!.operator === 'between' ? 1 : 0
    const renderer = getFilterRenderer(type)[idx]

    return html`
      <md-icon
        @click=${(e: Event) => {
          const parent = (e.target as HTMLElement).closest('[column]') as HTMLElement
          const popup = parent.querySelector('ox-popup, ox-popup-list') as OxPopup | null
          // const popup = (e.target as HTMLElement).nextSibling as OxPopupList | null

          // absolute position인 popup의 위치 부모는 grist 이므로,
          // data-grid-header 의 포지션 부모(grist)의 위치로부터 계산해야함.
          // this의 position을 relative로 하지 못하는 이유 : ox-popup-list가 grid body에 덮히기 때문.
          // const top = parent.offsetTop + parent.offsetHeight
          // const right = this.clientWidth - (parent.offsetLeft + parent.offsetWidth - this.scrollLeft)

          popup?.open({
            right: 0,
            top: parent.offsetHeight,
            fixed: true
          })
        }}
        >filter_alt</md-icon
      >

      ${!renderer
        ? html``
        : type !== 'select'
          ? html` <ox-popup
              ><div filter-title><md-icon>filter_alt</md-icon> filter by <strong>${column.name}</strong></div>
              ${renderer(column, value, this)}</ox-popup
            >`
          : filter!.operator === 'in'
            ? html`<ox-popup-list
                multiple
                attr-selected="checked"
                .value=${value}
                with-search
                @select=${(e: CustomEvent) =>
                  e.target?.dispatchEvent(
                    new CustomEvent('filter-change', {
                      bubbles: true,
                      composed: true,
                      detail: {
                        name,
                        operator: filter!.operator,
                        value: !e.detail
                          ? undefined
                          : e.detail instanceof Array && e.detail.length === 0
                            ? undefined
                            : e.detail
                      }
                    })
                  )}
                ><div filter-title slot="header">
                  <md-icon>filter_alt</md-icon> filter by <strong>${column.name}</strong>
                </div>
                ${renderer(column, value, this)}</ox-popup-list
              >`
            : html`<ox-popup-list
                .value=${value}
                with-search
                @select=${(e: CustomEvent) =>
                  e.target?.dispatchEvent(
                    new CustomEvent('filter-change', {
                      bubbles: true,
                      composed: true,
                      detail: {
                        name,
                        operator: filter!.operator,
                        value: e.detail ? e.detail : undefined
                      }
                    })
                  )}
                ><div filter-title slot="header">
                  <md-icon>filter_alt</md-icon> filter by <strong>${column.name}</strong>
                </div>
                ${renderer(column, value, this)}</ox-popup-list
              >`}
    `
  }

  _changeSort(column: ColumnConfig) {
    if (!column.sortable) {
      return
    }

    var sorters = [...this.sorters]

    var idx = sorters.findIndex(sorter => sorter.name == column.name)
    if (idx !== -1) {
      let sorter = sorters[idx]
      if (sorter.desc) {
        sorters.splice(idx, 1)
      } else {
        sorters.splice(idx, 1, {
          ...sorter,
          desc: true
        })
      }
    } else {
      var sorter = {
        name: column.name,
        desc: false
      }

      sorters.push(sorter)
    }

    this.sorters = sorters

    this.dispatchEvent(
      new CustomEvent('fetch-params-change', {
        bubbles: true,
        composed: true,
        detail: {
          sorters: this.sorters,
          from: 'data-grid-header'
        }
      })
    )
  }

  _accumalate(x: number) {
    this._lastAccVal = (this._lastAccVal ?? 0) + x
    return this._lastAccVal
  }

  _notifyWidthChange(idx: number, width: number) {
    if (!this._throttledNotifier) {
      this._throttledNotifier = throttle((idx: number, width: number) => {
        this.dispatchEvent(
          new CustomEvent('column-width-change', {
            bubbles: true,
            composed: true,
            detail: {
              idx,
              width
            }
          })
        )

        this.notifyFixedLeftChange()

        this._lastAccVal = 0
      }, 100)
    }

    this._throttledNotifier(idx, width)
  }

  _pointerdown(e: MouseEvent, idx: number) {
    e.stopPropagation()
    e.preventDefault()

    var pointermoveHandler = ((e: MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      let column = this.columns[idx]

      let width = Math.max(0, Number(column.width || 100) + this._accumalate(e.movementX))
      if (width == 0) {
        /* CLARIFY-ME 왜 마지막 이벤트의 offsetX로 음수 값이 오는가 */
        return
      }

      this._notifyWidthChange(idx, width)
    }).bind(this)

    var pointerupHandler = ((e: MouseEvent) => {
      document.removeEventListener('pointermove', pointermoveHandler)
      document.removeEventListener('pointerup', pointerupHandler)

      pointermoveHandler(e)
    }).bind(this)

    document.addEventListener('pointermove', pointermoveHandler)
    document.addEventListener('pointerup', pointerupHandler)
  }
}
