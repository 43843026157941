import './ox-input-tree'

import { html, css } from 'lit'
import { customElement, query } from 'lit/decorators.js'

import { OxGristEditor } from './ox-grist-editor.js'

@customElement('ox-grist-editor-tree')
export class OxGristEditorTree extends OxGristEditor {
  static styles = [
    css`
      :host {
        flex: 1;
      }

      ox-input-tree {
        flex: 1;
      }
    `
  ]

  get editorTemplate() {
    var { selectable } = this.column.record.options || {}

    return html`<ox-input-tree .value=${this.value} .record=${this.record} ?selectable=${selectable}></ox-input-tree>`
  }
}
