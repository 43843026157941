// Test via a getter in the options object to see if the passive property is accessed
export var supportsPassive = false

try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassive = true
    }
  })

  window.addEventListener('testPassive', () => {}, opts)
  window.removeEventListener('testPassive', () => {}, opts)
} catch (e) {}
