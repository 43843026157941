import './data-grid-field'
import './data-grid-accum-field'

import { css, html, LitElement, nothing, PropertyValues } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import debounce from 'lodash-es/debounce'

import { sleep, parseToNumberOrNull } from '@operato/utils'

import { ZERO_CONFIG, ZERO_DATA } from '../configure/zero-config'
import { RecordViewHandler } from '../record-view/record-view-handler'
import { ColumnConfig, GristConfig, GristData, GristRecord } from '../types'
import { supportsPassive } from '../utils'
import { dataGridBodyStyle } from './data-grid-body-style'
import { DataGridField } from './data-grid-field'
import { dataGridBodyClickHandler } from './event-handlers/data-grid-body-click-handler'
import { dataGridBodyDblclickHandler } from './event-handlers/data-grid-body-dblclick-handler'
import { dataGridBodyFocusChangeHandler } from './event-handlers/data-grid-body-focus-change-handler'
import { dataGridBodyContextMenuHandler } from './event-handlers/data-grid-body-contextmenu-handler'
import { dataGridBodyKeydownHandler } from './event-handlers/data-grid-body-keydown-handler'
import { accumulate } from '../accumulator/accumulator'

const THRESHOLD = 300
const DATA_PADDING = 3
const ROW_HEIGHT = 40
const GAP_SIZE = 1

function calcScrollPos(parent: DataGridBody, child: Element) {
  /* getBoundingClientRect는 safari에서 스크롤 상태에서 다른 브라우저와는 다른 값을 리턴함 - 사파리는 약간 이상 작동함. */
  var { top: ct, left: cl, right: cr, bottom: cb } = child.getBoundingClientRect()
  var { top: pt, left: pl, right: pr, bottom: pb } = parent.getBoundingClientRect()
  var { scrollLeft, scrollTop } = parent
  var scrollbarWidth = parent.clientWidth - parent.offsetWidth
  var scrollbarHeight = parent.clientHeight - parent.offsetHeight

  return {
    left: cl < pl ? scrollLeft - (pl - cl) : cr > pr ? scrollLeft - (pr - cr) - scrollbarWidth : undefined,
    top: ct < pt ? scrollTop - (pt - ct) : cb > pb ? scrollTop - (pb - cb) - scrollbarHeight : undefined
  }
}

const ZERO_FOCUS = {
  row: 0,
  column: 0
}

@customElement('ox-grid-body')
export class DataGridBody extends LitElement {
  debounce = debounce((scrollTop: number, clientHeight: number) => {
    const maxVisibleRows = Math.ceil(clientHeight / (ROW_HEIGHT + GAP_SIZE))
    const from = Math.max(0, Math.floor(scrollTop / (ROW_HEIGHT + GAP_SIZE)) - maxVisibleRows * DATA_PADDING)
    const to = Math.min(this.data.records?.length || 0, from + maxVisibleRows * (DATA_PADDING * 2 + 1))

    this.from = from
    this.to = to
  }, THRESHOLD)

  static styles = [
    dataGridBodyStyle,
    css`
      :host {
        font-variation-settings: 'FILL' 1;

        overscroll-behavior: none;
        user-select: none;
      }

      [select-block] {
        position: absolute;
        left: var(--select-box-left);
        top: var(--select-box-top);
        width: var(--select-box-width);
        height: var(--select-box-height);
        border: var(--grid-record-focused-cell-border);
        background-image: var(--focused-background-image);
        pointer-events: none;

        z-index: 5;
      }

      [fixed] {
        position: sticky;
        background-color: var(--grid-record-background-color);
        z-index: 2; /* 고정된 열을 다른 열 위에 표시. */
      }

      :host([raised]) [fixed] {
        /* 고정 컬럼이 살짝 올라와 있는 느낌을 표현 */
        box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
      }

      ox-grid-accum-field {
        position: sticky;
        bottom: 0;
        z-index: 1;
      }

      ox-grid-accum-field[fixed] {
        background-color: var(--md-sys-color-primary-container);
      }
    `
  ]

  @property({ type: Object }) config: GristConfig = ZERO_CONFIG
  @property({ type: Array }) columns: ColumnConfig[] = []
  @property({ type: Object }) data: GristData = ZERO_DATA
  @property({ type: Object }) focused: { row: number; column: number } = ZERO_FOCUS
  @property({ type: Object }) editTarget: { row: number; column: number; valueWith: string | null } | null = null
  @property({ type: Number }) from = -1
  @property({ type: Number }) to = -1
  @property({ type: Array }) fixedLefts: number[] = []

  @state() _selectBlock?: {
    start: DataGridField
    end?: DataGridField
  }

  @query('[select-block]') selectBlock?: HTMLDivElement
  @query('ox-grid-field[focused]') focusedField?: DataGridField

  private _focusedListener?: (e: KeyboardEvent) => void
  private _recordView?: any
  private _recordViewRow?: number
  private _draggable?: boolean

  resetEdit() {
    this.editTarget = null
  }

  handleOnScroll(e: WheelEvent) {
    const { scrollTop, clientHeight } = e.target as HTMLElement
    this.debounce(scrollTop, clientHeight)
  }

  // issue #13
  // renderOptimisticRow() {
  //   return
  // }

  render() {
    // block이 선택되어 있으면, focused row/column은 표현하지 않는다.
    var { row: focusedRow, column: focusedColumn } = (!this._selectBlock && this.focused) || {}
    var { row: editingRow, column: editingColumn, valueWith = null } = this.editTarget || {}

    var columns = this.columns.filter(column => !column.hidden)
    var data = this.data
    var { records } = data
    var { appendable, classifier, accumulator } = this.config.rows
    const { start, end } = this._selectBlock || {}

    /*
     * 레코드를 추가할 수 있는 경우에는 항상 추가 레코드를 보여준다.
     * 만약, 이전 방식처럼, 커서를 옮겨야만 새로운 레코드가 보이게 하고 싶다면, 조건부를 다음의 코드로 대체한다.
     * -- if (focusedRow == records.length)
     */
    if (appendable) {
      records = [...records, { __dirty__: '+' }]
    }

    if (accumulator) {
      var accumRecord = this.buildAccumulatorRecord()
    }

    return html`
      ${records.map((record, idxRow) => {
        var attrFocusedRow = idxRow === focusedRow
        var attrSelected = record['__selected__']
        var attrOdd = idxRow % 2
        var dirtyFields = record['__dirtyfields__'] || {}
        var { emphasized } = classifier.call(null, record, idxRow) || {}

        return html`
          ${columns.map(
            (column, idxColumn) => html`
              <ox-grid-field
                .data=${data}
                .type=${column.type}
                .rowIndex=${idxRow}
                .columnIndex=${idxColumn}
                .column=${column}
                .record=${record}
                .checked=${record.__selected__ ? 'checked' : record.__check_in_tree__}
                .emphasized=${emphasized}
                ?gutter=${column.type == 'gutter'}
                ?odd=${attrOdd}
                ?focused-row=${attrFocusedRow}
                ?selected-row=${attrSelected}
                ?focused=${idxRow === focusedRow && idxColumn === focusedColumn}
                ?editing=${idxRow === editingRow && idxColumn === editingColumn}
                .valueWith=${valueWith}
                .value=${record[column.name]}
                ?dirty=${!!dirtyFields[column.name]}
                fixed=${ifDefined(this.fixedLefts[idxColumn])}
              ></ox-grid-field>
            `
          )}
          <ox-grid-field
            .data=${data}
            .rowIndex=${idxRow}
            .columnIndex=${-1}
            .record=${record}
            .emphasized=${emphasized}
            ?odd=${attrOdd}
            ?focused-row=${attrFocusedRow}
            ?selected-row=${attrSelected}
          ></ox-grid-field>
        `
      })}
      ${accumulator
        ? html`
            ${columns.map(
              (column, idxColumn) => html`
                <ox-grid-accum-field
                  .data=${data}
                  .columnIndex=${idxColumn}
                  .rowIndex=${records.length}
                  .column=${column}
                  .record=${accumRecord!}
                  .value=${accumRecord[column.name]}
                  fixed=${ifDefined(this.fixedLefts[idxColumn])}
                ></ox-grid-accum-field>
              `
            )}
            <ox-grid-accum-field
              .data=${data}
              .columnIndex=${-1}
              .rowIndex=${records.length}
              .record=${accumRecord!}
            ></ox-grid-accum-field>
          `
        : nothing}
      ${start && end && start !== end ? html` <div select-block></div> ` : html``}
      <slot></slot>
    `
  }

  firstUpdated() {
    // TODO issue #13
    // this.addEventListener('scroll', this.handleOnScroll.bind(this))

    /* focus() 를 받을 수 있도록 함. */
    this.setAttribute('tabindex', '-1')

    /*
     * focusout 으로 property를 변경시키는 경우, focusout에 의해 update가 발생하는 경우에는,
     * 그리드 내부의 컴포넌트가 갱신되는 현상을 초래하게 된다.
     * 따라서, focusout 핸들러에서 update를 유발하는 코드는 강력하게 금지시킨다.
     */
    this.addEventListener('focusout', e => {
      if (this._focusedListener) {
        this.removeEventListener('keydown', this._focusedListener)
        delete this._focusedListener
      }
    })

    this.addEventListener('focusin', e => {
      if (!this._focusedListener) {
        this._focusedListener = dataGridBodyKeydownHandler.bind(this)
        this.addEventListener('keydown', this._focusedListener)
      }
    })

    this.addEventListener('set-select-block', async e => {
      e.stopPropagation()

      const { startRow = -1, startColumn = -1, endRow = -1, endColumn = -1 } = ((e as CustomEvent).detail as any) || {}

      const start = this.getFieldByIndex(startRow, startColumn) as DataGridField
      const end = this.getFieldByIndex(endRow, endColumn) as DataGridField

      this.setSelectBlock(start, end)
    })

    this.renderRoot.addEventListener('contextmenu', (event: Event) => {
      const e = event as MouseEvent
      this.setSelectBlock()

      this._draggable = false

      var target = (e.target as Element).closest('ox-grid-field') as DataGridField
      var { rowIndex, columnIndex } = target || {}

      this.dispatchEvent(
        new CustomEvent('focus-change', {
          bubbles: true,
          composed: true,
          detail: {
            row: rowIndex,
            column: columnIndex
          }
        })
      )
    })

    this.renderRoot.addEventListener('pointerdown', (e: Event) => {
      this.setSelectBlock()

      if ('buttons' in e && e.buttons !== 1) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      this._draggable = true

      var target = (e.target as Element).closest('ox-grid-field') as DataGridField
      var { rowIndex, columnIndex } = target || {}

      this.dispatchEvent(
        new CustomEvent('focus-change', {
          bubbles: true,
          composed: true,
          detail: {
            row: rowIndex,
            column: columnIndex
          }
        })
      )

      if (columnIndex >= 0 && target.editableOnClick && !isNaN(rowIndex) && !isNaN(columnIndex)) {
        this.startEditTarget(rowIndex, columnIndex)
      }
    })

    this.renderRoot.addEventListener('pointermove', (event: Event) => {
      const e = event as MouseEvent
      if (('buttons' in e && e.buttons !== 1) || !this._draggable) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      const field = e.target as DataGridField
      if (!this._selectBlock) {
        this.setSelectBlock(this.focusedField || field, this.focusedField || field)

        return
      }

      var { start, end } = this._selectBlock || {}

      if (start && end !== field) {
        end = field

        this.setSelectBlock(start, end)
      }
    })

    this.renderRoot.addEventListener('pointerup', (event: Event) => {
      event.preventDefault()
      event.stopPropagation()

      this._draggable = false
    })

    this.renderRoot.addEventListener('click', dataGridBodyClickHandler.bind(this))
    this.renderRoot.addEventListener('dblclick', dataGridBodyDblclickHandler.bind(this))
    this.renderRoot.addEventListener('contextmenu', dataGridBodyContextMenuHandler.bind(this))

    this.addEventListener('focus-change', dataGridBodyFocusChangeHandler.bind(this))

    requestAnimationFrame(() => {
      const primaryColor = getComputedStyle(this).getPropertyValue('--md-sys-color-primary')

      this.style.setProperty(
        '--focused-background-image',
        `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='${primaryColor}' fill-opacity='0.2' x='0' y='0' width='100%' height='100%'/></svg>")`
      )
    })

    this.addEventListener('show-record-view', (e: Event) =>
      this.popupRecordView((e as CustomEvent).detail as { row: number; record: GristRecord })
    )

    this.addEventListener('scroll', function () {
      this.scrollLeft == 0 ? this.removeAttribute('raised') : this.setAttribute('raised', '')
    })
  }

  getFieldByIndex(rowIndex: number, columnIndex: number, residue: boolean = true) {
    if (rowIndex < 0) {
      return
    }

    var columns = this.columns.filter(column => !column.hidden).length
    if (!residue && columnIndex >= columns) {
      return
    }

    return this.renderRoot.children.item(
      rowIndex * (columns + 1) /* 1 means last dummy column */ + ((columnIndex + columns) % columns)
    ) as DataGridField
  }

  startEditTarget(row: number, column: number, valueWith: string | null = null) {
    var { editable } = this.columns.filter(column => !column.hidden)[column].record
    if (typeof editable === 'function') {
      const curRow = this.data.records[row] || {}
      const curCol = this.columns[column + 1]
      editable = editable.call(this, curRow[curCol.name], curCol, curRow, row, this)
    }

    if (!editable) {
      return
    }

    if (this.editTarget && this.editTarget.row == row && this.editTarget.column == column) {
      return
    }

    this.editTarget = {
      row,
      column,
      valueWith
    }
  }

  shouldUpdate(changes: any) {
    if (!changes.has('editTarget')) {
      /*
       * 큰 변화에 대해서는 실제 update가 발생되기 전에 editTarget을 초기화한다.
       */
      this.editTarget = null
    }

    return super.shouldUpdate(changes)
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('focused')) {
      let element = this.renderRoot?.querySelector('[focused]')
      if (!element) {
        return
      }

      let { top, left } = calcScrollPos(this, element)
      // TODO this.scroll()을 사용하면, 효과가 좋으나 left 계산에 문제가 있는 것 같음.
      // this.scroll({
      //   top,
      //   left,
      //   behavior: 'smooth'
      // })
      if (top !== undefined) {
        this.scrollTop = top
      }
      if (left !== undefined) {
        this.scrollLeft = left
      }
    }

    if (this._recordView) {
      this._recordView.record = this.data.records[this._recordViewRow!]
    }
  }

  focus() {
    super.focus()

    if (this.focused === ZERO_FOCUS) {
      let { records } = this.data
      let row = records.findIndex(record => record['__selected__'])

      this.focused = { row: row == -1 ? 0 : row, column: 0 }
    }
  }

  popupRecordView({ record, row }: { row: number; record: GristRecord }) {
    var titleField = this.config.list.fields[0] || 'name'
    var title = record[titleField]

    /* field가 오브젝트형인 경우에는 렌더러를 타이틀로 사용한다. */
    if (typeof title == 'object') {
      var column = this.config.columns.find(column => column.name == titleField)
      title = column?.record.renderer(title, column, record, row, this /* cautious */)
    }

    this._recordViewRow = row
    this._recordView = RecordViewHandler(
      this.config.columns,
      record,
      row,
      this,
      {
        title
      },
      () => {
        delete this._recordView
        delete this._recordViewRow
      }
    )
  }

  getSelectedBlockValues(): Array<Array<any>> | any | undefined {
    var { start, end } = this._selectBlock || {}

    if (!(start && end)) {
      start = this.focusedField

      end = start
    }

    if (start && end) {
      const startRowIndex = start.rowIndex < end.rowIndex ? start.rowIndex : end.rowIndex
      const endRowIndex = start.rowIndex < end.rowIndex ? end.rowIndex : start.rowIndex
      const startColumnIndex = start.columnIndex < end.columnIndex ? start.columnIndex : end.columnIndex
      const endColumnIndex = start.columnIndex < end.columnIndex ? end.columnIndex : start.columnIndex

      const columnArray = new Array(endColumnIndex - startColumnIndex + 1).fill(startColumnIndex)
      const columns = this.columns.filter(column => !column.hidden)

      return (
        '<table>' +
        new Array(endRowIndex - startRowIndex + 1)
          .fill(startRowIndex)
          .map((start, index) => {
            const rowIndex = start + index
            const record = this.data.records[rowIndex]

            const tds = columnArray
              .map((start, index) => {
                const columnIndex = start + index
                const column = columns[columnIndex]
                const value = record?.[column.name]
                const type = typeof value
                const text =
                  value === undefined || value === null ? '' : type == 'object' ? JSON.stringify(value) : value

                return `<td type=${type}>${text}</td>`
              })
              .join('')
            return `<tr>${tds}</tr>`
          })
          .join('') +
        '</table>'
      )
    }
  }

  async copy() {
    const copied = this.getSelectedBlockValues()

    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([copied], { type: 'text/html' }),
        'text/plain': new Blob([copied], { type: 'text/plain' })
      })
    ])

    const selectBlock = this.selectBlock || this.focusedField
    if (selectBlock) {
      const backgroundColor = selectBlock.style.backgroundColor
      const opacity = selectBlock.style.opacity

      selectBlock.setAttribute('data-tooltip', 'copied to clipboard!')
      const rect = selectBlock.getBoundingClientRect()
      selectBlock.style.setProperty('--tooltip-top', `${rect.top}px`)
      selectBlock.style.setProperty('--tooltip-left', `${rect.left}px`)

      selectBlock.style.backgroundColor = 'red'
      selectBlock.style.opacity = '0.5'
      await sleep(500)
      selectBlock.removeAttribute('data-tooltip')
      selectBlock.style.backgroundColor = backgroundColor
      selectBlock.style.opacity = opacity
    }
  }

  async paste() {
    try {
      const selection = window.getSelection()

      const clipboardItems = await navigator.clipboard.read()
      if (!clipboardItems) {
        return
      }

      var type: string | undefined
      var content: string | undefined

      for (const clipboardItem of clipboardItems) {
        try {
          var blob = await clipboardItem.getType('text/html')
          content = blob && (await blob.text())
          type = 'text/html'
        } catch (e) {
          try {
            blob = await clipboardItem.getType('text/plain')
            content = blob && (await blob.text())
            type = 'text/plain'
          } catch (e) {}
        }

        break
      }

      if (!content) {
        return
      }

      const { row, column } = this.focused
      const { records } = this.data
      const columns = this.columns.filter(column => !column.hidden)

      if (type === 'text/html') {
        const div = document.createElement('div')
        div.innerHTML = content!.trim()
        const table = div.querySelector('table') as HTMLTableElement
        if (!table) {
          return
        }

        if (selection) {
          this.resetEdit()
          selection.removeAllRanges()

          // 포커스가 빠지기 전에 isWorking으로 focusout 이벤트에 대한 값 변경을 막음
          this.focusedField!.isWorking = true
          await this.updateComplete
          this.focusedField!.isWorking = false
        }
        const rows = table.querySelectorAll('tr')

        rows.forEach((record, rowIndex) => {
          if (!(record instanceof HTMLTableRowElement)) {
            return
          }

          var targetRecord = records[row + rowIndex] || { __dirty__: '+' }
          if (row + rowIndex >= records.length) {
            records.push(targetRecord)
          }

          const cells = record.querySelectorAll('td')
          cells.forEach((item, columnIndex) => {
            const targetColumn = columns[column + columnIndex]
            var value = item.textContent?.trim() as any
            let type = targetColumn.type || item.getAttribute('type') || 'string'
            type = type.includes('object') ? 'object' : type // 오브젝트 타입 예외처리
            let { editable } = targetColumn.record
            if (typeof editable === 'function') {
              editable = editable.call(this, value, targetColumn, targetRecord, row, this)
            }

            switch (type) {
              case 'object':
              case 'parameters':
                try {
                  value = JSON.parse(value || 'null')
                } catch (err) {}
                break
              case 'boolean':
              case 'checkbox':
                value = !!value && !!String(value).match(/true/i)
                break
              case 'number':
              case 'float':
              case 'integer':
              case 'progress':
                value = parseToNumberOrNull(value)
                break
              default:
                value = value
            }

            if (targetColumn && !targetColumn.gutterName && editable) {
              this.dispatchEvent(
                new CustomEvent('field-change', {
                  bubbles: true,
                  composed: true,
                  detail: {
                    before: targetRecord[targetColumn.name],
                    after: value,
                    column: targetColumn,
                    record: targetRecord,
                    row: row + rowIndex
                  }
                })
              )
            }
          })
        })

        return
      } else if (!selection && type === 'text/plain') {
        const targetRecord = records[row] || { __dirty__: '+' }
        const targetColumn = columns[column]
        let { editable } = targetColumn.record
        if (typeof editable === 'function') {
          editable = editable.call(this, content, targetColumn, targetRecord, row, this)
        }

        if (targetColumn && !targetColumn.gutterName && editable) {
          this.dispatchEvent(
            new CustomEvent('field-change', {
              bubbles: true,
              composed: true,
              detail: {
                before: targetRecord[targetColumn.name],
                after: content,
                column: targetColumn,
                record: targetRecord,
                row: row
              }
            })
          )
        }
      }
    } catch (e) {
      console.log('e : ', e)
    }
  }

  setSelectBlock(start?: DataGridField, end?: DataGridField) {
    if (start?.columnIndex == -1) {
      start = this.getFieldByIndex(start.rowIndex, this.columns.filter(column => !column.hidden).length - 1)
    }

    if (end?.columnIndex == -1) {
      end = this.getFieldByIndex(end.rowIndex, this.columns.filter(column => !column.hidden).length - 1)
    }

    this._selectBlock = start && { start, end }

    if (start && end) {
      window.getSelection()?.removeAllRanges()

      if (start !== end) {
        const left = start.columnIndex < end.columnIndex ? start : end
        const right = left === start ? end : start
        const top = start.rowIndex < end.rowIndex ? start : end
        const bottom = top === start ? end : start

        const { offsetLeft } = left
        const { offsetTop } = top
        const width = right.offsetLeft - offsetLeft + right.offsetWidth
        const height = bottom.offsetTop - offsetTop + bottom.offsetHeight

        this.style.setProperty('--select-box-left', offsetLeft - 1 + 'px')
        this.style.setProperty('--select-box-top', offsetTop - 1 + 'px')
        this.style.setProperty('--select-box-width', width + 'px')
        this.style.setProperty('--select-box-height', height + 'px')
      }

      this.focus()
    }
  }

  buildAccumulatorRecord(): GristRecord {
    var columns = this.columns.filter(column => !column.hidden)

    return columns.reduce((record, column) => {
      if (column.accumulator) {
        record[column.name] = accumulate(this.data, column, column.accumulator)
      }
      return record
    }, {} as GristRecord)
  }
}
