import { FieldRenderer } from '../types'
import { html } from 'lit'

const IMAGE_FALLBACK = new URL('../../../assets/images/no-image.png', import.meta.url).href

export const OxGristRendererImage: FieldRenderer = (value, column, record, rowIndex, field) => {
  let src: string

  if (!value) {
    src = IMAGE_FALLBACK
  } else if (typeof value === 'string') {
    src = value
  } else {
    src = URL.createObjectURL(value)
    requestAnimationFrame(() => URL.revokeObjectURL(src))
  }

  const { width, height } = column.record.options || {}

  return html` <img
    src=${src}
    width=${width}
    height=${height}
    style="object-fit: contain; max-width: 100%;"
    onerror="this.src !== '${IMAGE_FALLBACK}' && (this.src = '${IMAGE_FALLBACK}')"
  />`
}
