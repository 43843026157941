import '@operato/input/ox-input-barcode.js'

import { html } from 'lit-html'

import { FilterConfigObject, FilterSelectRenderer } from '../types.js'

export const FilterInputBarcode: FilterSelectRenderer = (column, value, owner) => {
  const filter = column.filter as FilterConfigObject
  const { englishOnly, selectAfterChange } = filter.options || {}

  return html`
    <ox-input-barcode
      name=${column.name}
      ?english-only=${englishOnly}
      ?select-after-change=${selectAfterChange}
      .value=${value == null ? '' : value}
      @change=${(e: CustomEvent) => {
        const input = e.target as HTMLInputElement
        input.dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: input.value
            }
          })
        )
      }}
      filter-input
    ></ox-input-barcode>
  `
}
