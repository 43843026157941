import { FilterSelectRenderer } from '../types.js'
import { FilterCheckbox } from './filter-checkbox.js'
import { FilterInputBarcode } from './filter-input-barcode.js'
import { FilterInput } from './filter-input.js'
import { FilterRangeDate } from './filter-range-date.js'
import { FilterRangeNumber } from './filter-range-number.js'
import { FilterSelectButtons } from './filter-select-buttons.js'
import { FilterSelect } from './filter-select.js'

var RENDERERS: {
  [name: string]: FilterSelectRenderer[]
} = {
  string: [FilterInput],
  text: [FilterInput],
  email: [FilterInput],
  tel: [FilterInput],
  integer: [FilterInput, FilterRangeNumber],
  float: [FilterInput, FilterRangeNumber],
  number: [FilterInput, FilterRangeNumber],
  select: [FilterSelect],
  'select-buttons': [FilterSelectButtons],
  boolean: [FilterCheckbox],
  checkbox: [FilterCheckbox],
  month: [FilterInput, FilterRangeDate],
  week: [FilterInput, FilterRangeDate],
  date: [FilterInput, FilterRangeDate],
  time: [FilterInput, FilterRangeDate],
  datetime: [FilterInput, FilterRangeDate],
  color: [FilterInput],
  progress: [FilterInput, FilterRangeNumber],
  link: [FilterInput],
  image: [FilterInput],
  json5: [FilterInput],
  scanner: [FilterInputBarcode],
  barcode: [FilterInputBarcode]
}

export function registerFilterRenderer(type: string, renderer: FilterSelectRenderer[]) {
  RENDERERS[type] = renderer
}

export function unregisterFilterRenderer(type: string): void {
  delete RENDERERS[type]
}

export function getFilterRenderers(): { [name: string]: FilterSelectRenderer[] } {
  return { ...RENDERERS }
}

export function getFilterRenderer(type: string): FilterSelectRenderer[] {
  if (typeof type === 'function') {
    return type
  }

  return RENDERERS[type || 'text'] || [FilterInput]
}
