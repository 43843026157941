import { css } from 'lit'

export const FilterStyles = css`
  :host {
    --ox-filters-input-placeholder-color: var(--input-placeholder-color, var(--md-sys-color-on-surface));

    --ox-filters-input-border: var(--input-border, 1px solid rgba(0, 0, 0, 0.2));
    --ox-filters-input-focus-border: var(--input-focus-border, 1px solid var(--md-sys-color-outline-variant));
    --ox-filters-input-font: var(--input-font, normal 14px var(--theme-font));
    --ox-filters-input-color: var(--input-color, var(--md-sys-color-on-surface));
    --ox-filters-input-focus-color: var(--input-focus-color, var(--md-sys-color-on-surface-variant));
    --ox-filters-label-font: var(--label-font, normal 14px var(--theme-font));
    --ox-filters-label-color: var(--label-color, var(--md-sys-color-on-surface));
    --ox-filters-input-background-color: transparent;

    --ox-filters-form-gap: var(--input-gap-vertical, 8px) var(--input-gap-horizontal, 16px);
    --ox-filters-input-padding: var(--spacing-small, 4px);

    --ox-select-padding: var(--ox-filters-input-padding);
    --ox-checkbox-background-color: var(--ox-filters-input-background-color, transparent);
  }

  label {
    color: var(--ox-filters-label-color, var(--md-sys-color-on-primary-container));
  }

  span {
    font-weight: var(--md-sys-typescale-label-medium-weight);
    text-transform: capitalize;
  }

  input::placeholder {
    color: var(--ox-filters-input-placeholder-color, var(--md-sys-color-surface-dim));
    opacity: 0.6;
  }

  input,
  ox-input-search,
  [filter-input] {
    padding: var(--ox-filters-input-padding);
  }

  ox-select,
  ox-input-search,
  input,
  [filter-input] {
    border: none;
    border-bottom: var(--ox-filters-input-border, var(--md-sys-color-outline));
    font: var(--ox-filters-input-font);
    color: var(--ox-filters-input-color, var(--md-sys-color-on-surface));
    background-color: var(--ox-filters-input-background-color, transparent);
    vertical-align: middle;
  }

  ox-select:focus,
  input:focus,
  [filter-input]:focus {
    outline: none;
    border-bottom: var(--ox-filters-input-focus-border, var(--md-sys-color-outline-variant));
    color: var(--ox-filters-input-focus-color, var(--md-sys-color-on-surface-variant));
  }

  ox-select {
    min-width: 90px;
    max-width: 170px;
  }

  ox-input-search {
    max-width: 150px;
  }

  input[type='number'] {
    max-width: 90px;
  }

  input[type*='date'],
  input[type*='time'],
  input[type='week'],
  input[type='month'] {
    max-width: 170px;
  }

  [filter-input] {
    min-width: 140px;
    max-width: 170px;
  }

  @media only screen and (max-width: 460px) {
    :host {
      --ox-filters-form-label-font: bold 13px var(--theme-font);
      --ox-filters-input-font: normal 16px var(--theme-font);
    }

    ox-input-barcode {
      max-width: unset;
      flex: 1;
    }

    ox-input-search {
      max-width: unset;
    }

    ox-select {
      max-width: unset;
    }

    ox-checkbox {
      max-width: unset;
    }

    input[type='number'] {
      max-width: unset;
    }

    input {
      flex: 1;
    }
  }
`
