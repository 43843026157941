import JSON5 from 'json5'
import { html } from 'lit'

import { FieldRenderer } from '../types'

function onmouseover(e: Event) {
  const element = e.target as HTMLPreElement
  var parsed
  try {
    parsed = JSON5.parse(element.textContent!)
  } catch {}

  element.setAttribute('data-tooltip', JSON5.stringify(parsed, null, 2))

  const rect = element.getBoundingClientRect()
  element.style.setProperty('--tooltip-top', `${rect.top + rect.height}px`)
  element.style.setProperty('--tooltip-left', `${rect.left}px`)
}

function onmouseout(e: Event) {
  const element = e.target as HTMLPreElement
  element.removeAttribute('data-tooltip')
}

export const OxGristRendererJson5: FieldRenderer = (value, column, record, rowIndex, field) => {
  var parsed: any = value

  if (typeof value === 'string') {
    try {
      parsed = JSON5.parse(value)
    } catch {}
  }

  const text = parsed == null ? '' : JSON5.stringify(parsed)
  return html`<pre @mouseover=${onmouseover} @mouseout=${onmouseout}>${text}</pre>`
}
