import '@operato/input/ox-select.js'
import '@operato/input/ox-checkbox.js'
import '@operato/popup/ox-popup-list.js'

import { html, css } from 'lit'
import { customElement } from 'lit/decorators.js'

import { OxGristEditor } from './ox-grist-editor.js'

@customElement('ox-grist-editor-multiple-select')
export class OxGristEditorMultipleSelect extends OxGristEditor {
  static styles = [
    ...OxGristEditor.styles,
    css`
      :host {
        overflow: visible;
      }
    `
  ]

  get editorTemplate() {
    var rowOptionField = this.record[this.column.record.rowOptionField || '']
    var { options = [] } = rowOptionField ? rowOptionField : this.column.record

    if (typeof options == 'function') {
      options = options.call(null, this.value, this.column, this.record, this.rowIndex, this.field) || []
    }

    options = options.map((option: any) => {
      switch (typeof option) {
        case 'string':
          return {
            display: option,
            value: option
          }
        case 'object':
          return {
            display: option.display,
            value: option.value
          }
        default:
          return option
      }
    })

    return html`
      <ox-select .value=${this.value} style="width: 100%;">
        <ox-popup-list multiple attr-selected="checked">
          ${options.map(
            (option: { display: string; value: string }) =>
              html` <ox-checkbox option value=${option.value}>${option.display}</ox-checkbox> `
          )}
        </ox-popup-list>
      </ox-select>
    `
  }
}
