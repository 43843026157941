/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html } from 'lit'
import { customElement, property, queryAll } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field.js'

@customElement('ox-input-quantifier')
export class OxInputQuantifier extends OxFormField {
  static styles = css`
    :host {
      display: flex;
      gap: var(--spacing-medium);
      align-items: center;

      width: 100%;
      user-select: text;
    }

    input[type='number'] {
      width: 48px;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: var(--spacing-tiny);
      font: var(--input-font);
      color: var(--md-sys-color-on-primary-container);
    }
    input[type='number']:focus {
      outline: none;
      border-bottom: 1px solid var(--md-sys-color-on-primary-container);
    }

    input:focus {
      outline: none;
      opacity: 1;
    }
  `

  @property({ type: Array }) value: number[] = [1, 1]

  render() {
    const [min = 1, max = 1] = this.value || []

    return html`
      <span>
        <input type="number" .value=${min} @change=${(e: Event) => this.onChange(e)} ?disabled=${this.disabled} />
        -
        <input type="number" .value=${max} @change=${(e: Event) => this.onChange(e)} ?disabled=${this.disabled} />
      </span>
    `
  }

  @queryAll('input') inputs?: HTMLInputElement[]

  onChange(e: Event) {
    e.stopPropagation()
    this.value = Array.from(this.inputs || []).map(input => input.valueAsNumber) || [1, 1]
    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true }))
  }
}
