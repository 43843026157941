import { GristConfig, ColumnConfig } from '../types'
import { buildColumn } from './column-builder'
import { buildImexOptions } from './imex-option-builder'
import { buildListOptions } from './list-option-builder'
import { buildTreeOptions } from './tree-option-builder'
import { buildRowsOptions } from './rows-option-builder'
import { buildFiltersOptions } from './filters-option-builder'

function findLastIndexWithCondition(arr: ColumnConfig[], condition: (x: ColumnConfig) => boolean) {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (condition(arr[i])) {
      return i
    }
  }
  return -1
}

export const buildConfig = (config: any): GristConfig => {
  var { columns = [], rows = {}, pagination, sorters, filters, list, tree, imex } = config

  /* 마지막 fixed 컬럼 앞의 모든 컬럼의 fixed를 true로 설정한다. */
  const lastFixedIndex = findLastIndexWithCondition(columns, x => !!x.fixed)
  if (lastFixedIndex >= 0) {
    for (let i = 0; i < lastFixedIndex; i++) {
      columns[i].fixed = true
    }
  }

  return {
    ...config,
    columns: columns.map((column: any) => buildColumn(column)),
    rows: buildRowsOptions(rows),
    pagination,
    sorters,
    filters: buildFiltersOptions(filters),
    list: buildListOptions(list),
    tree: buildTreeOptions(tree),
    imex: buildImexOptions(imex)
  }
}
