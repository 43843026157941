import { formatter } from './formatter'

export type RelativeHourTimeParams = {
  timeZone?: string
  locale?: string
  format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions
  relativeHour?: number
  relativeSeconds?: number
}

export const getRelativeHourTime = (params: RelativeHourTimeParams = {}): Date | number | string => {
  const { relativeHour = 0, relativeSeconds = 0, timeZone, format, locale } = params

  const now = new Date()
  let currentDate: Date

  if (timeZone) {
    const options: Intl.DateTimeFormatOptions = { timeZone }
    const currentDateISOString = now.toLocaleString('en-US', options).split(',')[0]
    currentDate = new Date(currentDateISOString)
  } else {
    currentDate = now
  }

  const startOfHour = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    0,
    0,
    0
  )
  const targetTime = startOfHour.getTime() + relativeHour * 60 * 60 * 1000 + relativeSeconds * 1000

  return formatter(targetTime, params)
}
