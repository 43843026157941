/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-direction')
export class OxInputDirection extends OxFormField {
  static styles = css`
    :host {
      --md-icon-size: 1.4em;
    }

    div {
      display: flex;
      justify-content: space-between;
      max-width: 140px;
    }

    md-icon {
      color: var(--md-sys-color-on-background);
      background-color: var(--md-sys-color-background);
    }

    md-icon[selected] {
      color: var(--md-sys-color-on-primary);
      background-color: var(--md-sys-color-primary);
    }
  `

  @property({ type: String }) value?: string

  render() {
    const value = this.value

    return html`
      <div @click=${this.onClick.bind(this)}>
        <md-icon ?selected=${value == 'W'} data-value="W">arrow_back</md-icon>
        <md-icon ?selected=${value == 'N'} data-value="N">arrow_upward</md-icon>
        <md-icon ?selected=${value == 'S'} data-value="S">arrow_downward</md-icon>
        <md-icon ?selected=${value == 'E'} data-value="E">arrow_forward</md-icon>
      </div>
    `
  }

  onClick(e: MouseEvent) {
    e.stopPropagation()

    if (this.disabled) {
      return
    }

    const target = e.target as Element
    const value = target.getAttribute('data-value') as string

    this.value = this.value === value ? undefined : value

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }
}
