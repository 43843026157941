import { html, nothing } from 'lit'

import { FieldRenderer } from '../types'

function download(file: { mimetype: string; name: string; fullpath: string }) {
  const element = document.createElement('a')
  element.setAttribute('href', file.fullpath)
  element.setAttribute('download', file.name!)
  document.body.appendChild(element)
  element.click()
}

export const OxGristRendererFile: FieldRenderer = (value, column, record, rowIndex, field) => {
  let text: string

  if (!value) {
    return html`<span>&nbsp;</span>`
  } else if (typeof value === 'string') {
    return html`<span>${value}</span>`
  } else {
    const files = value as { mimetype: string; name: string; fullpath: string }[]
    return html`<span
      >${files.map(
        (file, idx) => html`
          <a @click=${() => download(file)} file><md-icon>description</md-icon>${file.name}</a>
          ${files.length - 1 == idx ? html`</br>` : nothing}
        `
      )}</span
    >`
  }
}
