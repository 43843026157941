/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import './ox-input-color'

import { css, html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { keyed } from 'lit/directives/keyed.js'
import { ifDefined } from 'lit/directives/if-defined.js'

import { OxFormField } from './ox-form-field'

type ValueMap = { default?: any; [key: string]: any }
type ArrayedValueMap = { key: string; value: any }

/**
key-value map editor element

Example:

  <ox-input-value-map 
    value=${map}
    keytype=${keytype}
    valuetype=${valuetype}>
  </ox-input-value-map>
*/
@customElement('ox-input-value-map')
export class OxInputValueMap extends OxFormField {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: var(--spacing-small) var(--spacing-small) 0 var(--spacing-small);

      --md-icon-size: var(--fontsize-default, 14px);
    }

    div {
      display: flex;
      flex-flow: row nowrap;
      gap: var(--padding-default);
    }

    div:last-child {
      margin-bottom: var(--spacing-small);
    }

    div > * {
      min-width: 0px;
      margin: 2px;
      padding: 0;
    }

    button {
      border: var(--button-border);
      border-radius: var(--border-radius);
      background-color: var(--button-background-color);
      padding: var(--spacing-small) var(--padding-default);
      line-height: 0.8;
      color: var(--button-color);
      cursor: pointer;
    }
    button + button {
      margin-left: -5px;
    }

    button:focus,
    button:hover,
    button:active {
      border: var(--button-activ-border);
      background-color: var(--button-background-focus-color);
      color: var(--md-sys-color-primary-container);
    }

    [data-key],
    [data-value] {
      flex: 1;
    }

    input {
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: var(--spacing-tiny);
      font: var(--input-font);
      color: var(--md-sys-color-on-primary-container);
    }

    input:focus {
      outline: none;
      border-bottom: 1px solid var(--md-sys-color-on-primary-container);
    }

    button.hidden {
      opacity: 0;
      cursor: default;
    }

    ox-input-color {
      margin: 1px;
      width: 10px;
      height: 10px;
    }

    input[type='checkbox'] {
      flex: none;
      width: initial;
    }
  `

  @property({ type: Object }) value: ValueMap = {}
  @property({ type: String }) valuetype: 'string' | 'boolean' | 'color' | 'number' | 'date' | string = 'string'
  @property({ type: String }) keytype: 'string' | 'number' = 'number'
  @property({ type: String, attribute: 'default-value' }) defaultValue?: string

  private _changingNow: boolean = false

  firstUpdated() {
    this.renderRoot.addEventListener('change', this._onChange.bind(this))
  }

  updated() {
    const inputs = this.renderRoot.querySelectorAll('[data-record-new] > :not([style*="display: none"])') as NodeListOf<
      HTMLInputElement & { value: any }
    >

    inputs[0].focus()
  }

  valueInputTemplate(value?: any) {
    return this.valuetype == 'boolean'
      ? html`
          <input
            type="checkbox"
            data-value
            .checked=${!!value}
            data-value-type=${this.valuetype}
            ?disabled=${this.disabled}
          />
        `
      : this.valuetype == 'color'
        ? html` <ox-input-color data-value value=${ifDefined(value)} ?disabled=${this.disabled}> </ox-input-color> `
        : html`
            <input
              type="text"
              data-value
              placeholder="value"
              value=${ifDefined(value)}
              data-value-type=${this.valuetype}
              ?disabled=${this.disabled}
            />
          `
  }

  render() {
    return html`
      ${this._toArray(this.value).map(
        item => html`
          <div data-record>
            <input type="text" data-key placeholder="key" .value=${item.key} ?disabled=${this.disabled} />
            ${this.valueInputTemplate(item.value)}
            <button
              class="record-action"
              @click=${(e: MouseEvent) => this._delete(e)}
              tabindex="-1"
              ?disabled=${this.disabled}
            >
              <md-icon>remove</md-icon>
            </button>
          </div>
        `
      )}
      ${this.disabled
        ? nothing
        : keyed(
            Date.now(),
            html`
              <div data-record-new>
                <input type="text" data-key placeholder="key" value="" />

                ${this.valueInputTemplate()}
                <button class="record-action" @click=${(e: MouseEvent) => this._add()} tabindex="-1">
                  <md-icon>add</md-icon>
                </button>
              </div>
            `
          )}

      <div data-record>
        <input type="text" data-key data-default="" value="default" disabled />

        ${this.valueInputTemplate((this.value && this.value.default) || this._defaultValue())}
        <button class="record-action" @click=${() => this._sort()} tabindex="-1">
          <md-icon>chevron_right</md-icon>
        </button>
      </div>
    `
  }

  _defaultValue(type?: 'color' | 'boolean' | 'checkbox' | string) {
    switch (type || this.valuetype) {
      case 'color':
        return this.defaultValue || '#000000'
      case 'boolean':
      case 'checkbox':
        return this.defaultValue ? this.defaultValue === 'true' : false
      default:
        return this.defaultValue || ''
    }
  }

  _onChange(e: Event) {
    e.stopPropagation()

    if (this._changingNow) {
      return
    }

    this._changingNow = true

    const input = e.target as HTMLInputElement
    var value

    if (input.type == 'checkbox') {
      value = Boolean(input.checked)
    } else {
      value = input.value
    }

    const div = input.parentElement as HTMLDivElement

    if (div.hasAttribute('data-record') && input.hasAttribute('data-value')) {
      var dataList = div.querySelectorAll('[data-value]:not([hidden])') as NodeListOf<HTMLElement & { value: any }>

      for (var i = 0; i < dataList.length; i++) {
        if (dataList[i] !== input) {
          dataList[i].value = value || this._defaultValue()
        }
      }
    }

    if (div.hasAttribute('data-record')) {
      this._build()
    } else if (div.hasAttribute('data-record-new') && input.hasAttribute('data-value')) {
      this._add()
    }

    this._changingNow = false
  }

  _build(includeNewRecord?: boolean) {
    if (includeNewRecord) {
      var records = this.renderRoot.querySelectorAll('[data-record],[data-record-new]') as NodeListOf<HTMLElement>
    } else {
      var records = this.renderRoot.querySelectorAll('[data-record]') as NodeListOf<HTMLElement>
    }

    var newmap: ValueMap = {}

    for (var i = 0; i < records.length; i++) {
      var record = records[i]

      const key = (record.querySelector('[data-key]') as HTMLInputElement).value
      const inputs = record.querySelectorAll('[data-value]:not([style*="display: none"])') as NodeListOf<
        HTMLInputElement & { value: any }
      >

      if (!inputs || inputs.length == 0) {
        continue
      }

      var input = inputs[inputs.length - 1]

      var value

      if (input.type == 'checkbox') {
        value = Boolean(input.checked)
      } else {
        value = input.value
      }

      if (key) {
        newmap[key] = value || this._defaultValue()
      }
    }

    this.value = newmap

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }

  /* default를 제외한 map아이템들을 template(dom-repeat)용 배열로 변환하는 함수 */
  _toArray(map: ValueMap) {
    var array: ArrayedValueMap[] = []

    for (var key in map) {
      if (key == 'default') continue
      array.push({
        key: key,
        value: map[key]
      })
    }

    return array
  }

  _sort() {
    const sorter =
      this.keytype === 'number'
        ? function (a: ArrayedValueMap, b: ArrayedValueMap) {
            return parseFloat(b.key) < parseFloat(a.key) ? 1 : -1
          }
        : function (a: ArrayedValueMap, b: ArrayedValueMap) {
            return b.key < a.key ? 1 : -1
          }

    var map = this._toArray(this.value)
      .sort(sorter)
      .reduce(function (sum, i) {
        sum[i.key] = i.value
        return sum
      }, {} as ValueMap)

    map.default = this.value.default

    this.value = map
    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }

  _add() {
    this._build(true)
  }

  _delete(e: MouseEvent) {
    const record = (e.target as HTMLElement).parentElement

    ;(record!.querySelector('[data-key]') as HTMLInputElement)!.value = ''

    this._build()
  }
}
