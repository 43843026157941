import { css } from 'lit'

export const dataGridBodyStyle = css`
  :host {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    grid-auto-rows: var(--grid-record-height, min-content);

    overflow: auto;
    outline: none;
    color: var(--grid-record-color);
    position: relative;
    border-bottom: var(--grid-body-bottom-border);
  }

  ox-grid-field[odd] {
    background-color: var(--grid-record-odd-background-color);
  }

  ox-grid-field[selected-row] {
    background-color: var(--grid-record-selected-background-color);
    color: var(--grid-record-selected-color);
  }

  ox-grid-field[focused-row] {
    box-shadow: var(--grid-record-focused-box-shadow);
    font-weight: bold;
    color: var(--grid-record-focused-color);
    background-image: var(--focused-background-image);
    background-blend-mode: darken;
  }

  ox-grid-field[focused] {
    border: var(--grid-record-focused-cell-border);
  }

  ox-grid-field[emphasized-row],
  ox-grid-field[emphasized-row][focused] {
    background-color: var(--grid-record-emphasized-background-color);
    color: var(--grid-record-emphasized-color);
  }

  [editing] {
    background-color: var(--grid-record-editing-background-color);
  }

  @media print {
    :host {
      grid-template-columns: var(--grid-template-print-columns);
    }
    ox-grid-field[focused] {
      border: none;
    }

    ox-grid-field[selected-row] {
      background-color: transparent;
    }

    ox-grid-field[emphasized-row] {
      background-color: transparent;
      color: initial;
    }

    ox-grid-field[focused-row] {
      background-color: transparent;
      color: initial;
    }

    ox-grid-field[editing] {
      background-color: transparent;
    }
  }

  @media (prefers-color-scheme: dark) {
    ox-grid-field[focused-row] {
      background-blend-mode: lighten;
    }
  }

  @media (prefers-color-scheme: light) {
    ox-grid-field[focused-row] {
      background-blend-mode: darken;
    }
  }
`
