import { DataGridField } from '../../data-grid/data-grid-field'
import { RecordViewBody } from '../record-view-body'

/**
 * ox-record-view-body 의 click handler
 *
 * - handler의 this 는 ox-record-view-body임.
 */
export function recordViewBodyClickHandler(this: RecordViewBody, e: Event): void {
  e.stopPropagation()

  /* target should be 'ox-grid-field' */
  var target = e.target as DataGridField

  if (this.currentTarget) {
    this.focus()
    this.currentTarget.removeAttribute('editing')
  }

  let editable = target?.column?.record.editable
  if (typeof editable === 'function') {
    editable = editable.call(this, target.value, target.column, target.record, target.rowIndex, this)
  }

  if (target.tagName !== 'OX-GRID-FIELD' || !editable) {
    this.focus()
    this.currentTarget = null
    return
  }

  this.currentTarget = target
  target.setAttribute('editing', 'true')
}
