import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-week')
export class OxGristEditorWeek extends OxGristEditor {
  get editorTemplate() {
    return html` <input type="week" .value=${this.value} /> `
  }
}
