import { formatter } from './formatter'

export type RelativeMinuteTimeParams = {
  timeZone?: string
  format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions
  locale?: string
  relativeMinutes?: number
  relativeSeconds?: number
}

export const getRelativeMinuteTime = (params: RelativeMinuteTimeParams = {}): Date | number | string => {
  const { relativeMinutes = 0, relativeSeconds = 0, timeZone, format, locale } = params

  const now = new Date()
  let currentDate: Date

  if (timeZone) {
    const options: Intl.DateTimeFormatOptions = { timeZone }
    const currentDateISOString = now.toLocaleString('en-US', options).split(',')[0]
    currentDate = new Date(currentDateISOString)
  } else {
    currentDate = now
  }

  const startOfMinute = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    0,
    0
  )
  const targetTime = startOfMinute.getTime() + relativeMinutes * 60 * 1000 + relativeSeconds * 1000

  return formatter(targetTime, params)
}
