/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'

import { css, html, nothing } from 'lit'
import { customElement, property, queryAll } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

type PartitionKeys = { [key: string]: any }
type ArrayedPartitionKeys = { key: string; value: any }

/**
 key-value map for partitions editor element
 
 Example:
 
   <ox-input-partition-keys 
     value=${map}
   </ox-input-partition-keys>
 */
@customElement('ox-input-partition-keys')
export class OxInputPartitionKeys extends OxFormField {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-bottom: var(--spacing-large);

      --md-icon-size: var(--fontsize-default, 14px);
    }

    div {
      display: flex;
      flex-flow: row nowrap;
      gap: var(--spacing-medium);
      margin-bottom: var(--spacing-small);
    }

    button {
      border: var(--button-border);
      border-radius: var(--border-radius);
      background-color: var(--button-background-color);
      padding: var(--spacing-small) var(--padding-default);
      line-height: 0.8;
      color: var(--button-color);
      cursor: pointer;
    }
    button + button {
      margin-left: -5px;
    }

    button:focus,
    button:hover,
    button:active {
      border: var(--button-activ-border);
      background-color: var(--button-background-focus-color);
      color: var(--md-sys-color-primary-container);
    }

    input {
      flex: 1;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: var(--spacing-tiny);
      font: var(--input-font);
      color: var(--md-sys-color-on-primary-container);
    }
    input:focus {
      outline: none;
      border-bottom: 1px solid var(--md-sys-color-on-primary-container);
    }
    button.hidden {
      opacity: 0;
      cursor: default;
    }
  `

  @property({ type: Object }) value: PartitionKeys = {}

  private _changingNow: boolean = false

  firstUpdated() {
    this.renderRoot.addEventListener('change', this._onChange.bind(this))
  }

  render() {
    const value = !this.value || typeof this.value !== 'object' ? {} : this.value

    return html`
      ${this._toArray(value).map(
        item => html`
          <div data-record>
            <input type="text" data-key placeholder="key" .value=${item.key} ?disabled=${this.disabled} />
            <input
              type="text"
              data-value
              placeholder="value"
              .value=${item.value}
              list="value-template"
              ?disabled=${this.disabled}
            />
            <button
              class="record-action"
              @click=${(e: MouseEvent) => this._delete(e)}
              tabindex="-1"
              ?disabled=${this.disabled}
            >
              <md-icon>remove</md-icon>
            </button>
            <button
              class="record-action"
              @click=${(e: MouseEvent) => this._up(e)}
              tabindex="-1"
              ?disabled=${this.disabled}
            >
              <md-icon>arrow_upward</md-icon>
            </button>
            <button
              class="record-action"
              @click=${(e: MouseEvent) => this._down(e)}
              tabindex="-1"
              ?disabled=${this.disabled}
            >
              <md-icon>arrow_downward</md-icon>
            </button>
          </div>
        `
      )}
      ${this.disabled
        ? nothing
        : html`
            <div data-record-new>
              <input type="text" data-key placeholder="key" value="" />
              <input type="text" data-value placeholder="value" value="" list="value-template" />
              <button class="record-action" @click=${(e: MouseEvent) => this._add()} tabindex="-1">
                <md-icon>add</md-icon>
              </button>
              <button class="hidden"><md-icon>add</md-icon></button>
              <button class="hidden"><md-icon>add</md-icon></button>
            </div>
          `}

      <datalist id="value-template">
        <option value="%YYYY">year</option>
        <option value="%MM">month</option>
        <option value="%DD">day</option>
        <option value="%YYYYMMDD">date</option>
      </datalist>
    `
  }

  _onChange(e: Event) {
    if (this._changingNow) {
      return
    }

    this._changingNow = true

    const input = e.target as HTMLInputElement
    var value = input.value

    const record = (e.target as Element).closest('[data-record],[data-record-new]') as HTMLElement

    if (record.hasAttribute('data-record')) {
      this._build()
    } else if (record.hasAttribute('data-record-new') && input.hasAttribute('data-value')) {
      this._add()
    }

    this._changingNow = false
  }

  _build(includeNewRecord?: boolean) {
    if (includeNewRecord) {
      var records = this.renderRoot.querySelectorAll('[data-record],[data-record-new]') as NodeListOf<HTMLElement>
    } else {
      var records = this.renderRoot.querySelectorAll('[data-record]') as NodeListOf<HTMLElement>
    }

    var newmap: PartitionKeys = {}

    for (var i = 0; i < records.length; i++) {
      var record = records[i]

      const key = (record.querySelector('[data-key]') as HTMLInputElement).value
      const inputs = record.querySelectorAll(
        '[data-value]:not([style*="display: none"])'
      ) as NodeListOf<HTMLInputElement>

      if (!inputs || inputs.length == 0) {
        continue
      }

      var input = inputs[inputs.length - 1]

      var value = input.value

      if (key) {
        newmap[key] = value || ''
      }
    }

    this.value = newmap
    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }

  /* map아이템들을 template(dom-repeat)용 배열로 변환하는 함수 */
  _toArray(map: PartitionKeys) {
    var array: ArrayedPartitionKeys[] = []

    for (var key in map) {
      array.push({
        key: key,
        value: map[key]
      })
    }

    return array
  }

  _add() {
    this._build(true)

    const inputs = this.renderRoot.querySelectorAll(
      '[data-record-new] input:not([style*="display: none"])'
    ) as NodeListOf<HTMLInputElement & { value: any }>

    for (var i = 0; i < inputs.length; i++) {
      let input = inputs[i]

      if (input.type == 'checkbox') input.checked = false
      else input.value = ''
    }

    inputs[0].focus()
  }

  _delete(e: MouseEvent) {
    const record = (e.target as Element).closest('[data-record]') as HTMLElement

    ;(record!.querySelector('[data-key]') as HTMLInputElement)!.value = ''

    this._build()
  }

  @queryAll('[data-record]') records!: NodeListOf<HTMLElement>

  _up(e: MouseEvent) {
    const record = (e.target as Element).closest('[data-record]') as HTMLElement
    const array = Array.from(this.records)
    const index = array.indexOf(record) - 1

    if (index < 0) {
      return
    }

    const deleted = array.splice(index, 1)
    array.splice(index + 1, 0, ...deleted)

    this.value = array.reduce(
      (sum, record) => {
        const key = (record.querySelector('[data-key]') as HTMLInputElement).value
        const value = (record.querySelector('[data-value]') as HTMLInputElement).value

        sum[key] = value
        return sum
      },
      {} as { [key: string]: string }
    )

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }

  _down(e: MouseEvent) {
    const record = (e.target as Element).closest('[data-record]') as HTMLElement
    const array = Array.from(this.records)
    const index = array.indexOf(record)

    if (index > array.length) {
      return
    }

    array.splice(index, 1)
    array.splice(index + 1, 0, record)

    this.value = array.reduce(
      (sum, record) => {
        const key = (record.querySelector('[data-key]') as HTMLInputElement).value
        const value = (record.querySelector('[data-value]') as HTMLInputElement).value

        sum[key] = value
        return sum
      },
      {} as { [key: string]: string }
    )

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
  }
}
