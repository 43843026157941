/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html, PropertyValues } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-textarea')
export class OxInputTextarea extends OxFormField {
  static styles = [
    css`
      :host {
        display: flex;
      }

      textarea {
        flex: 1;
        height: auto;
        resize: none;
        overflow: hidden;
        border: none;
        outline: none;
        background-color: transparent;

        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    `
  ]

  @property({ type: String }) value: string = ''

  @query('textarea') textarea!: HTMLInputElement

  render() {
    return html`
      <textarea
        .value=${this.value}
        @input=${this.onInput.bind(this)}
        @keydown=${this.onKeydown.bind(this)}
        @change=${this.onChange.bind(this)}
        ?disabled=${this.disabled}
      ></textarea>
    `
  }

  connectedCallback() {
    super.connectedCallback()
    requestAnimationFrame(() => {
      this.textarea.focus()
    })
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('value')) {
      this.textarea.style.height = 'auto'
      this.textarea.style.height = this.textarea.scrollHeight + 'px'
    }
  }

  onInput(e: Event) {
    this.textarea.style.height = 'auto'
    this.textarea.style.height = this.textarea.scrollHeight + 'px'
  }

  onKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  onChange(e: Event) {
    this.value = this.textarea.value

    this.dispatchEvent(
      new CustomEvent('change', {
        detail: this.value,
        bubbles: true,
        composed: true
      })
    )
  }
}
