import '@material/web/icon/icon.js'
import './record-partial'

import { css, html, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { DataManipulator } from '../data-manipulator'
import { GristRecord } from '../types'

@customElement('ox-list')
export class DataList extends DataManipulator {
  static styles = [
    css`
      :host {
        background-color: var(--data-list-background-color);
        overflow-y: auto;
      }

      ox-record-partial:nth-child(even) {
        background-color: var(--grid-record-odd-background-color);
      }

      [selected-row] {
        background-color: var(--data-list-selected-background-color);
      }

      #upward {
        --md-icon-size: var(--icon-size-large);
        position: absolute;
        top: var(--data-list-fab-position-vertical);
        right: var(--data-list-fab-position-horizontal);
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: var(--md-sys-shape-corner-large);
        color: var(--data-list-fab-color);
        box-shadow: var(--data-list-fab-shadow);
        padding: var(--spacing-small);
      }

      slot {
        width: 100%;
      }

      ox-empty-note {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      ox-record-partial[emphasized-row],
      ox-record-partial[emphasized-row][focused] {
        background-color: var(--grid-record-emphasized-background-color);
        color: var(--grid-record-emphasized-color);
      }
    `
  ]

  @property({ type: Boolean }) empty?: boolean

  @state() private _records: GristRecord[] = []
  @state() private isTop: boolean = false

  private _total: number = 0
  private _limit: number = 20
  private _page: number = 1

  firstUpdated(changes: PropertyValues<this>) {
    this.isTop = true

    /* infinite scrolling */
    this.addEventListener('scroll', e => {
      const totalScrollHeight = this.scrollHeight
      const screenHeight = this.offsetHeight
      const currentScrollTop = this.scrollTop

      if (totalScrollHeight <= screenHeight + currentScrollTop + 1) {
        /* 마지막 페이지까지 계속 페이지를 증가시킨다. */
        var lastPage = Math.ceil(this._total / this._limit)

        if (this._page < lastPage) {
          this.dispatchEvent(new CustomEvent('attach-page', { bubbles: true, composed: true }))
        }
      }

      this.isTop = this.scrollTop == 0
    })
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('config')) {
      this._records = []
      this._page = 1
    }

    if (changes.has('data')) {
      this._records = this.data.records
      this._total = this.data.total || 0
      this._limit = this.data.limit || 20
      this._page = this.data.page || 0
    }
  }

  render() {
    var { classifier } = this.config.rows

    var records = this._records || []

    if (this.config && this.config.rows.appendable) {
      records = [...records, { __dirty__: '+' }]
    }

    return html`
      ${records.map((record, rowIndex) => {
        var { emphasized } = classifier.call(null, record, rowIndex) || {}

        return html`
          <ox-record-partial
            .config=${this.config}
            .data=${this.data}
            .record=${record}
            .rowIndex=${rowIndex}
            .emphasized=${emphasized}
            ?selected-row=${record['__selected__']}
            ?dirty=${record['__dirty__']}
          ></ox-record-partial>
        `
      })}
      ${this.empty ? html` <ox-empty-note title="NO RECORDS"></ox-empty-note> ` : html``}
      ${this.isTop
        ? html``
        : html` <md-icon id="upward" @click=${(e: Event) => this.gotoTop(e)}>arrow_upward</md-icon> `}
    `
  }

  gotoTop(e: Event) {
    this.scrollTop = 0

    e.stopPropagation()
  }

  get pullToRefreshTarget() {
    return this
  }
}
