/**
 * inspired by https://github.com/jiangfengming/pull-to-refresh
 */

import './pull-to-refresh-control'

function ontouchpan({
  element,
  animates,
  onpanstart,
  onpanmove,
  onpanend
}: {
  element: HTMLElement
  animates: HTMLElement
  onpanstart?: any
  onpanmove: any
  onpanend: any
}): boolean | (() => void) {
  let touchId: number, startX: number, startY: number, panstartCalled: boolean

  function calcMovement(e: any) {
    const touch = Array.prototype.slice.call(e.changedTouches).filter(touch => touch.identifier === touchId)[0]
    if (!touch) return false

    e.deltaX = touch.screenX - startX
    e.deltaY = touch.screenY - startY
    return true
  }

  function touchstart(e: TouchEvent) {
    const touch = e.changedTouches[0]
    touchId = touch.identifier
    startX = touch.screenX
    startY = touch.screenY
  }

  function touchmove(e: TouchEvent) {
    if (calcMovement(e)) {
      if (onpanstart && !panstartCalled) {
        onpanstart(e)
        panstartCalled = true
      }

      onpanmove(e)
    }
  }

  function touchend(e: TouchEvent) {
    if (calcMovement(e)) onpanend(e)
  }

  element.addEventListener('touchstart', touchstart)
  if (onpanmove) element.addEventListener('touchmove', touchmove)
  if (onpanend) element.addEventListener('touchend', touchend)

  return function () {
    element.removeEventListener('touchstart', touchstart)
    if (onpanmove) element.removeEventListener('touchmove', touchmove)
    if (onpanend) element.removeEventListener('touchend', touchend)

    animates && animates.remove()
  }
}

export function pulltorefresh(opts: any) {
  opts = Object.assign(
    {
      // https://bugs.chromium.org/p/chromium/issues/detail?id=766938
      scrollable: document.body,
      threshold: 150,
      onStateChange(/* state, opts */) {
        /* noop */
      }
    },
    opts
  )

  var { container, scrollable, threshold, refresh, onStateChange, animates } = opts

  if (!animates) {
    animates = document.createElement('ox-pulltorefresh-control')
    container.appendChild(animates)
  }

  let distance: number = -1,
    offset: number = -1,
    state: string // state: pulling, aborting, reached, refreshing, restoring

  function addClass(cls: string) {
    animates.classList.add('pull-to-refresh--' + cls)
  }

  function removeClass(cls: string) {
    animates.classList.remove('pull-to-refresh--' + cls)
  }

  function scrollTop() {
    if (!scrollable || [window, document, document.body, document.documentElement].includes(scrollable)) {
      return document.documentElement.scrollTop || document.body.scrollTop
    } else {
      return scrollable.scrollTop
    }
  }

  return ontouchpan({
    element: container,
    animates,

    onpanmove(e: any) {
      let d = e.deltaY

      if (scrollTop() > 0 || (d < 0 && !state) || state in { aborting: 1, refreshing: 1, restoring: 1 }) return

      if (e.cancelable) {
        e.preventDefault()
      }

      if (distance == -1) {
        offset = d
        state = 'pulling'
        addClass(state)
        onStateChange(state, opts)
      }

      d = d - offset
      if (d < 0) d = 0
      distance = d

      if ((d >= threshold && state !== 'reached') || (d < threshold && state !== 'pulling')) {
        removeClass(state)
        state = state === 'reached' ? 'pulling' : 'reached'
        addClass(state)
        onStateChange(state, opts)
      }

      animates.pulling(d, opts)
    },

    async onpanend() {
      if (state == '') return

      if (state === 'pulling') {
        removeClass(state)
        state = 'aborting'
        onStateChange(state)
        addClass(state)

        await animates.aborting(opts)

        removeClass(state)
        distance = -1
        state = ''
        offset = -1
        onStateChange(state)
      } else if (state === 'reached') {
        removeClass(state)
        state = 'refreshing'
        addClass(state)
        onStateChange(state, opts)
        animates.refreshing(opts)

        await refresh()

        removeClass(state)
        state = 'restoring'
        addClass(state)
        onStateChange(state)

        await animates.restoring(opts)

        removeClass(state)
        distance = -1
        state = ''
        offset = -1
        onStateChange(state)
      }
    }
  })
}
