import '@operato/i18n/ox-i18n.js'

import { css, html, LitElement } from 'lit'
import { customElement } from 'lit/decorators.js'

import { i18next, localize } from '@operato/i18n'
import { notify } from '@operato/layout'
import { auth } from '@things-factory/auth-base/dist-client/auth.js'

@customElement('delete-user-popup')
export class DeleteUserPopup extends localize(i18next)(LitElement) {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: column;
        color: var(--popup-content-color);
        background-color: var(--popup-content-background-color);
        padding: var(--popup-content-padding);
      }

      * {
        box-sizing: border-box;
      }
      *:focus {
        outline: none;
      }

      label {
        font: bold 14px var(--theme-font);
        color: var(--md-sys-color-primary);
      }

      input {
        border: var(--change-password-field-border);
        border-radius: var(--change-password-field-border-radius);
        padding: var(--change-password-field-padding);

        font: var(--change-password-field-font);
        width: var(--change-password-field-width);
      }
      input:focus {
        border: 1px solid var(--focus-background-color);
      }

      div.field {
        padding-bottom: 10px;
      }

      ::placeholder {
        font-size: 0.8rem;
        text-transform: capitalize;
      }

      button {
        background-color: var(--status-danger-color, #d14946);
        margin: 2px 2px 10px 2px;
        height: var(--button-height, 28px);
        color: var(--button-color, #fff);
        font: var(--button-font);
        border-radius: var(--button-radius, 5px);
        border: var(--button-border, 1px solid transparent);
        line-height: 1.5;
      }
      button:hover,
      button:active {
        background-color: var(--button-active-background-color, #22a6a7);
        border: var(--button-active-border);
      }
    `
  ]

  render() {
    return html`
      <h1><ox-i18n msgid="label.delete account"></ox-i18n></h1>
      <span><ox-i18n msgid="text.delete account warning message"></ox-i18n></span>
      <form @submit=${e => this.submit(e)}>
        <div class="field">
          <label for="email"><ox-i18n msgid="label.email"></ox-i18n></label>
          <input id="email" type="email" name="email" autocapitalize="off" required />
          <label for="password"><ox-i18n msgid="label.password"></ox-i18n></label>
          <input id="password" type="password" name="password" required />
        </div>

        <button class="ui button" type="submit"><ox-i18n msgid="label.delete account"></ox-i18n></button>
      </form>
    `
  }

  async submit(e: Event) {
    e.preventDefault()

    const form = e.target as HTMLFormElement

    var params = {}
    new FormData(form).forEach((value, key) => {
      params[key] = value
    })

    try {
      const message = await auth.deleteUser(params)
      notify({
        level: 'info',
        message
      })

      auth.signout()
    } catch (e: any) {
      form.reset()

      notify({
        level: 'error',
        message: 'message' in e ? e.message : e
      })
    }
  }
}
