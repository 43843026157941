import './data-report/data-report-component'

import { GristConfig, GristData, GristRecord, GroupConfig } from './types'
import { LitElement, PropertyValues, css, html } from 'lit'
import { ScrollbarStyles, SpinnerStyles } from '@operato/styles'
import { ZERO_CONFIG, ZERO_DATA, ZERO_PAGINATION } from './configure/zero-config'
import { customElement, property, query, queryAsync, state } from 'lit/decorators.js'

import { DataConsumer } from './data-consumer'
import { DataProvider } from './data-provider'
import { DataReportComponent } from './data-report/data-report-component'
import { buildColumn } from './configure/column-builder'
import { buildConfig } from './configure/config-builder'
import i18next from 'i18next'
import { pulltorefresh } from '@operato/pull-to-refresh'

@customElement('ox-report')
export class DataReport extends LitElement implements DataConsumer {
  static styles = [
    ScrollbarStyles,
    SpinnerStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background-color: var(--report-background-color);
        padding: var(--report-padding);
        min-height: 120px;

        overflow: hidden;

        /* for pulltorefresh controller */
        position: relative;
      }

      #wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }

      ox-report-component {
        flex: 1;
      }

      ox-empty-note {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `
  ]

  @property() config: any
  @property() data: GristData = ZERO_DATA
  @property() fetchHandler: any
  @property() fetchOptions: any
  @property({ type: Boolean, attribute: 'auto-fetch' }) autoFetch: boolean = false

  @state() _data: GristData = ZERO_DATA
  @state() _config: GristConfig = ZERO_CONFIG
  @state() private _showSpinner: boolean = false

  private dataProvider?: DataProvider
  private pulltorefreshHandle?: any

  @query('#report') report!: DataReportComponent
  @queryAsync('#wrap') private wrap!: Promise<HTMLElement>

  get mode(): 'REPORT' {
    return 'REPORT'
  }

  connectedCallback() {
    super.connectedCallback()

    this.dataProvider = new DataProvider(this)
  }

  disconnectedCallback() {
    super.disconnectedCallback()

    this.resetPullToRefresh()
    this.dataProvider?.dispose()
  }

  private resetPullToRefresh() {
    if (this.pulltorefreshHandle) {
      this.pulltorefreshHandle()
      delete this.pulltorefreshHandle
    }
  }

  private async setPullToRefresh() {
    this.resetPullToRefresh()
    if (this.fetchHandler) {
      this.pulltorefreshHandle = pulltorefresh({
        container: await this.wrap,
        scrollable: this.report.pullToRefreshTarget,
        refresh: () => {
          return this.fetch(true)
        }
      })
    }
  }

  async firstUpdated() {
    if (this.fetchHandler && this.autoFetch) {
      await this.requestUpdate()
      this.fetch(true)
    }
  }

  render() {
    var oops = !this._showSpinner && (!this._data || !this._data.records || this._data.records.length == 0)

    return html`
      ${oops ? html` <ox-empty-note title="NO RECORDS"></ox-empty-note> ` : html``}

      <div id="wrap">
        <ox-report-component id="report" .config=${this._config} .data=${this._data}> </ox-report-component>
      </div>

      <div id="spinner" ?show=${this._showSpinner}></div>
    `
  }

  async fetch(reset = true) {
    if (!this._config) {
      /* avoid to be here */
      console.warn('report is not configured yet.')
      return
    }

    if (reset) {
      /*
       * scroll 의 현재위치에 의해서 scroll 이벤트가 발생할 수 있으므로, 이를 방지하기 위해서 스크롤의 위치를 TOP으로 옮긴다.
       * (scroll 이 첫페이지 크기 이상으로 내려가 있는 경우, 첫페이지부터 다시 표시하는 경우에, scroll 이벤트가 발생한다.)
       */
      this.report.scrollTop = 0
    }

    if (this.dataProvider) {
      await this.dataProvider.attach(reset)
    }
  }

  async updated(changes: PropertyValues<this>) {
    if (changes.has('config')) {
      this._config = buildConfig({
        ...this.config
      })

      this.dataProvider && (this.dataProvider.sorters = this._config.sorters)
      this.fetch()
    }

    if (changes.has('fetchHandler')) {
      this.dataProvider && (this.dataProvider.fetchHandler = this.fetchHandler)
      await this.setPullToRefresh()
    }

    if (changes.has('fetchOptions')) {
      this.dataProvider && (this.dataProvider.fetchOptions = this.fetchOptions)
    }

    if (changes.has('data')) {
      this.reset()
    }
  }

  get dirtyData() {
    return (this.report as any)?.data || {}
  }

  showSpinner() {
    this._showSpinner = true
  }

  hideSpinner() {
    this._showSpinner = false
  }

  focus() {
    super.focus()

    this.report?.focus()
  }

  /**
   * Forced internal data to be reflected on the screen
   * Data changing through a normal method is automatically reflected on the screen, so it is a method that does not need to be used in general.
Therefore, it will be deprecated.
   * @deprecated
   * @method
   */
  refresh() {
    this.requestUpdate()
  }

  reset() {
    var {
      limit = ZERO_PAGINATION.limit,
      page = ZERO_PAGINATION.page,
      total = ZERO_PAGINATION.total,
      records = []
    } = this.data

    this._data = {
      limit,
      page,
      total,
      records: this.sortByGroups(records).map((record, idx) => {
        return {
          ...record,
          __seq__: (page - 1) * limit + idx + 1
        }
      })
    }
  }

  sortByGroups(sortedRecords: GristRecord[]) {
    var { groups, totals } = this._config.rows
    var { columns } = this._config

    var getColumnIndex = (name: string | number) =>
      columns.filter(column => !column.hidden).findIndex(column => column.name == name)

    var groupFieldsForTotalRecord: GroupConfig[] = [
      /* add a group total record to the front. */
      {
        column: '*',
        title: i18next.exists('text.ox-data-report-grand-total')
          ? i18next.t('text.ox-data-report-grand-total')
          : 'grand total',
        align: 'right',
        rowspan: 1
      },
      ...groups
    ].map(group => {
      return {
        ...group,
        titleColumn: buildColumn({
          record: {
            align: group.align || 'right'
          }
        })
      }
    })
    let lastGroupValues
    let reportRecords = []
    let totalicRecords: { [idx: string]: GroupConfig }[] = sortedRecords[0]
      ? (() => {
          /* 처음 만드는 total records */
          let record = sortedRecords[0]
          let totalBase = totals.reduce((base, field) => {
            base[field] =
              columns.find(col => col.name == field)?.rowCount && (record[field] === 0 || record[field])
                ? 1
                : record[field]
            return base
          }, {} as { [totalField: string]: number })
          let groupBase = {} as { [idx: string]: GroupConfig }

          return groupFieldsForTotalRecord.map((group, idx) => {
            groupBase[group.column] = record[group.column]

            return {
              ...totalBase,
              ...groupBase,
              [group.column]: record[group.column],
              '*': {
                titleColumn: group.titleColumn,
                value: group.title,
                groupName: group.column,
                /* 이 레코드 그룹에 해당하는 첫번째 레코드의 행 번호(1 부터 시작하는 번호임.) - grid layout의 row 지정에 사용됨. */
                row: 1,
                rowspan: 1,
                /* 이 레코드 그룹의 컬럼에 해당하는 열 번호(1 부터 시작하는 번호임.) - grid layout의 column 지정에 사용됨. */
                column:
                  group.column !== '*'
                    ? getColumnIndex(group.column) + 1
                    : getColumnIndex(groups[0].column) + 1 /* grand total 은 첫번째 그룹 컬럼을 사용한다. */,
                colspan:
                  group.column !== '*' ? groupFieldsForTotalRecord.length - idx : groupFieldsForTotalRecord.length - 1
              }
            }
          })
        })()
      : [
          {
            '*': {
              titleColumn: groupFieldsForTotalRecord[0].titleColumn,
              value: i18next.exists('text.ox-data-report-grand-total')
                ? i18next.t('text.ox-data-report-grand-total')
                : 'grand total',
              groupName: '*',
              row: 1,
              rowspan: 1,
              column: 1,
              colspan: 0
            } as GroupConfig
          }
        ]

    var row = 0

    for (let record of sortedRecords) {
      let groupValues = groups.reduce((base, group) => {
        base[group.column] = record[group.column]
        return base
      }, {} as { [groupColumn: string]: string | number })
      let isSameGroupRecord = true
      let totalsStack = [] as { idx: string; record: any }[]
      let groupBase = {} as { [idx: string]: any }

      row++

      if (lastGroupValues) {
        for (let idx in groupFieldsForTotalRecord) {
          let group = groupFieldsForTotalRecord[idx]
          let totalRecord: {
            [idx: string]: GroupConfig | number
          } = totalicRecords[idx]

          groupBase[group.column] = record[group.column]

          if (
            group.column == '*' ||
            (isSameGroupRecord && groupValues[group.column] === lastGroupValues[group.column])
          ) {
            for (let field of totals) {
              totalRecord[field] +=
                columns.find(col => col.name == field)?.rowCount && (record[field] === 0 || record[field])
                  ? 1
                  : record[field]
            }

            ;(totalRecord['*'] as GroupConfig).rowspan++

            continue
          }

          /* to avoid from floating point calculation problem */
          totals.forEach(field => {
            totalRecord[field] = Math.round((totalRecord[field] as number) * 100) / 100
          })

          isSameGroupRecord = false

          totalsStack.push({
            idx,
            record: totals.reduce(
              (sum, field) => {
                sum[field] =
                  columns.find(col => col.name == field)?.rowCount && (record[field] === 0 || record[field])
                    ? 1
                    : record[field]
                return sum
              },
              {
                ...groupBase,
                [group.column]: record[group.column],
                '*': {
                  titleColumn: group.titleColumn,
                  groupName: group.column,
                  value: group.title,
                  row,
                  rowspan: 1,
                  column: getColumnIndex(group.column) + 1,
                  colspan: totalicRecords[idx]['*'].colspan
                }
              }
            )
          })
        }

        totalsStack
          .reverse()
          .map(({ record, idx }) => {
            reportRecords.push(totalicRecords[Number(idx)])
            totalicRecords[Number(idx)] = {}

            if (record['*'].value) {
              totalicRecords.forEach(record => record['*'] && record['*'].rowspan++)
              row++
            }

            return { record, idx }
          })
          .forEach(({ record, idx }) => {
            record['*'].row = row
            totalicRecords[Number(idx)] = record
          })
      }

      reportRecords.push(record)

      lastGroupValues = groupValues
    }

    /* 마지막 남은 토탈 레코드들을 추가한다. */
    var poped: any
    while ((poped = totalicRecords.pop())) {
      /* to avoid from floating point calculation problem */
      totals.forEach(field => {
        poped[field] = Math.round(poped[field] * 100) / 100
      })

      reportRecords.push(poped)
      if (poped['*'].value) {
        totalicRecords.forEach(record => record['*'].rowspan++)
      }
    }

    return reportRecords
  }

  checkDirties() {}
}
