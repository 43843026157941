import '@material/web/icon/icon.js'

import { LitElement, css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

@customElement('ox-empty-note')
export class EmptyNote extends LitElement {
  static styles = css`
    :host {
      display: block;
      text-align: center;
    }

    md-icon {
      font: var(--oops-note-icon-font);
      color: var(--oops-note-icon-color);
      border: var(--oops-note-icon-border);
      border-radius: var(--oops-note-icon-border-radius);
      padding: var(--oops-note-icon-padding);
    }

    [titler] {
      margin: var(--oops-note-title-margin);
      font: var(--oops-note-title-font);
      color: var(--oops-note-title-color);
      text-transform: capitalize;
    }

    [description] {
      font: var(--oops-note-description-font);
      color: var(--oops-note-description-color);
    }
  `

  @property() icon?: string
  @property() title!: string
  @property() description?: string

  render() {
    return html`
      ${this.icon ? html`<md-icon>${this.icon}</md-icon>` : html``}
      <div titler>${this.title}</div>
      ${this.description ? html`<div description>${this.description}</div>` : html``}
    `
  }
}
