import { DataReportBody } from '../data-report-body'

/**
 * ox-report-body 의 keydown handler
 */
export function dataReportBodyKeydownHandler(this: DataReportBody, e: KeyboardEvent): void {
  // arrow-key
  const key = e.key
  var { row = 0, column = 0 } = this.focused || {}
  var { records = [] } = this.data || {}
  var maxrow = this.config.rows?.appendable ? records.length : records.length - 1
  var maxcolumn = (this.columns || []).filter(column => !column.hidden).length - 1

  switch (key) {
    case 'Up':
    case 'ArrowUp':
      row = Math.max(0, row - 1)
      break

    case 'Down':
    case 'ArrowDown':
      row = Math.min(maxrow, row + 1)
      break

    case 'Enter':
      if (maxcolumn > column) {
        column++
      } else if (maxrow > row) {
        row++
      }
      break

    case 'Left':
    case 'ArrowLeft':
    case 'Backspace':
      column = Math.max(0, column - 1)
      break

    case 'Right':
    case 'ArrowRight':
    case 'Tab':
      column = Math.min(maxcolumn, column + 1)
      break

    case 'PageUp':
      /* TODO 페이지당 레코드의 수를 계산해서 증감시켜야 한다. */
      row = Math.max(0, row - 10)
      break

    case 'PageDown':
      row = Math.min(maxrow, row + 10)
      break

    case 'Esc':
    case 'Escape':
      return

    default:
      return
  }

  if (!this.focused || this.focused.row !== row || this.focused.column !== column) {
    this.focused = { row, column }
  }

  /* arrow key에 의한 scrollbar의 자동 움직임을 하지 못하도록 한다. */
  e.preventDefault()
}
