/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-scene-component-id')
export class OxInputId extends OxFormField {
  static styles = css`
    :host {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
    }

    input {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  `

  @property({ type: Object }) property: { component?: any } | null | undefined

  @state() _ids: Array<string> = []

  render() {
    const ids = this._ids || []

    return html`
      <input
        id="text"
        type="text"
        .value=${this.value || ''}
        @focusin=${(e: FocusEvent) => this._onInputFocused(e)}
        @change=${(e: InputEvent) => this._onInputChanged(e)}
        .placeholder=${this.getAttribute('placeholder') || ''}
        list="ids"
        ?disabled=${this.disabled}
      />

      <datalist id="ids">${ids.map(id => html` <option value=${id}></option> `)}</datalist>
    `
  }

  _onInputFocused(e: FocusEvent) {
    var { component } = this.property || {}

    document.dispatchEvent(
      new CustomEvent('get-all-scene-component-ids', {
        detail: {
          component,
          callback: (ids: string[]) => {
            this._ids = ids
          }
        }
      })
    )
  }

  _onInputChanged(e: InputEvent) {
    e.stopPropagation()

    this.value = (e.target as HTMLInputElement).value

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true }))
  }
}
