import { html } from 'lit'

import { DataCard } from '../data-card/data-card'
import { DataGrid } from '../data-grid/data-grid'
import { DataList } from '../data-list/data-list'
import { ColumnConfig, FieldRenderer, HeaderRenderer } from '../types'

export class GutterRowSelector {
  static instance(config: Partial<ColumnConfig> = { type: 'gutter', name: '__selected__' }) {
    return Object.assign(
      {},
      {
        type: 'gutter',
        name: '__selected__',
        gutterName: 'row-selector',
        width: 30,
        resizable: false,
        sortable: false,
        header: {
          renderer: function (this: DataGrid | DataList | DataCard, column) {
            var checked = ((this.data || {}).records || []).find(record => record['__selected__'])

            return html`
              <input
                type="checkbox"
                .checked=${!!checked}
                @change=${(e: Event) => {
                  let selected = column.multiple ? (e.target as HTMLInputElement).checked : false

                  this.dispatchEvent(
                    new CustomEvent('select-all-change', {
                      bubbles: true,
                      composed: true,
                      detail: {
                        selected
                      }
                    })
                  )

                  e.stopPropagation()
                }}
              />
            `
          } as HeaderRenderer
        },
        record: {
          align: 'center',
          renderer: function (value, column, record, rowIndex, field) {
            return html`
              <input
                type=${column.multiple ? 'checkbox' : 'radio'}
                .checked=${!!value}
                @click=${(e: Event) => {
                  e.stopPropagation()
                }}
                @change=${(e: Event) => {
                  let selected = (e.target as HTMLInputElement).checked

                  let detail = column.multiple
                    ? {
                        added: selected ? [record] : [],
                        removed: selected ? [] : [record]
                      }
                    : {
                        records: selected ? [record] : []
                      }

                  field.dispatchEvent(
                    new CustomEvent('select-record-change', {
                      bubbles: true,
                      composed: true,
                      detail
                    })
                  )

                  e.stopPropagation()
                }}
              />
            `
          } as FieldRenderer
        },
        forGrid: true,
        forList: true,
        forCard: true
      },
      config
    )
  }
}
