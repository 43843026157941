import { TreeConfig } from '../types'

const CollapsedAll = () => false
const ExpandedAll = () => true

export const buildTreeOptions = (tree: TreeConfig | undefined): TreeConfig => {
  var { expanded = CollapsedAll } = tree || {}

  switch (expanded) {
    case true:
      expanded = ExpandedAll
      break
    case false:
      expanded = CollapsedAll
      break
  }

  return {
    ...tree,
    expanded
  }
}
