import { ColumnConfig, GristRecord } from '../types'
import { LitElement, TemplateResult, css, html } from 'lit'
import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { customElement, property } from 'lit/decorators.js'

@customElement('ox-list-field')
export class DataListField extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        white-space: normal;
        padding: var(--spacing-tiny) var(--spacing-small);

        font: inherit;
      }

      :host > * {
        flex: 1;
        margin: var(--spacing-none);
        text-align: left;
      }

      :host > *[center] {
        flex: none;
        margin: var(--spacing-none) auto;
      }

      :host([thumbnail]) {
        flex-direction: column;
        justify-content: center;
      }

      :host([thumbnail]) > * {
        flex: unset;
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding: var(--spacing-none);
        margin: var(--spacing-none);
      }

      label {
        flex: none;
        width: 33%;
        font: var(--data-list-item-etc-label-font);
      }

      @media only screen and (max-width: 460px) {
        *[center] {
          margin: initial;
        }
      }
    `
  ]

  @property({ attribute: true }) align?: string
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex: number = -1
  @property({ type: Object }) value?: object

  render(): TemplateResult {
    var { value, column, record, rowIndex } = this

    var {
      label,
      record: { renderer: fieldRenderer }
    } = column

    if (typeof label == 'object') {
      let { renderer: labelRenderer } = label
      return html`<label>${labelRenderer(column)}</label>${fieldRenderer(value, column, record, rowIndex, this)}`
    } else {
      return html`${fieldRenderer(value, column, record, rowIndex, this)}`
    }
  }
}
