// 숫자라면 숫자값을, 아니면 null을 반환
// 0         = true
// '0'       = true
// 123       = true
// "123"     = true
// -123      = true
// "-123"    = true
// true      = false
// false     = false
// ""        = false
// null      = false
// undefined = false
// {}        = false
// []        = false
export function parseToNumberOrNull(value: any): number | null {
  let result: number | null = null

  // 숫자나 스트링이면서 NaN이 아니면 숫자로 변환
  if ((typeof value === 'string' || typeof value === 'number') && value !== '' && !isNaN(value as number)) {
    result = Number(value)
  }

  return result
}
