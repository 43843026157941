/**
 * judge callback function for FileDropHelper.set
 */
type JudgeCallback = () => boolean

/**
 * file drop assist class
 */
export class FileDropHelper {
  /**
   * 파일 드롭 영역에서의 file drag&drop과 관련된 이벤트에 대한 처리를 설정한다.
   * @param dropArea file drag&drop target area
   * @param [judge] file drag&drop과 관련된 이벤트에 대한 judge callback function
   */
  static set(dropArea: HTMLElement, judge?: JudgeCallback) {
    var preventDefaults = (e: Event) => {
      if (!judge || judge()) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    var highlight = (e: Event) => {
      if (!judge || judge()) {
        dropArea.classList.add('candrop')
      }
    }

    var unhighlight = (e: Event) => {
      if (!judge || judge()) {
        dropArea.classList.remove('candrop')
      }
    }

    ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(event => {
      dropArea.addEventListener(event, preventDefaults, false)
    })
    ;['dragenter', 'dragover'].forEach(event => {
      dropArea.addEventListener(event, highlight, false)
    })
    ;['dragleave', 'drop'].forEach(event => {
      dropArea.addEventListener(event, unhighlight, false)
    })

    dropArea.addEventListener(
      'drop',
      e => {
        if (!judge || judge()) {
          let dt = e.dataTransfer!
          let files = dt.files

          dropArea.dispatchEvent(
            new CustomEvent('file-drop', {
              detail: [...Array.from(files)]
            })
          )
        }
      },
      false
    )
  }
}
