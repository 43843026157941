/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { i18next } from '@operato/i18n'

import { OxFormField } from './ox-form-field'

import '@material/web/fab/fab.js'
import '@material/web/icon/icon.js'

function createCronRegex(type: 'sec' | 'min' | 'hour' | 'day' | 'month' | 'dayOfWeek') {
  // https://gist.github.com/dkandalov/a2aed17cfdeb65243022
  var regexByField = {} as any
  regexByField['sec'] = '[0-5]?\\d'
  regexByField['min'] = '[0-5]?\\d'
  regexByField['hour'] = '[01]?\\d|2[0-3]'
  regexByField['day'] = '0?[1-9]|[12]\\d|3[01]'
  regexByField['month'] = '[1-9]|1[012]'
  regexByField['dayOfWeek'] = '[0-7]'

  var crontabFields = [type]
  if (!type) crontabFields = ['sec', 'min', 'hour', 'day', 'month', 'dayOfWeek']

  crontabFields.forEach(field => {
    var number = regexByField[field]
    var range =
      '(?:' +
      number +
      ')' +
      '(?:' +
      '(?:-|/|,' +
      ('dayOfWeek' === field ? '|#' : '') +
      ')' +
      '(?:' +
      number +
      ')' +
      ')?'
    if (field === 'dayOfWeek') range += '(?:L)?'
    if (field === 'month') range += '(?:L|W)?'
    regexByField[field] = '\\?|\\*|' + range + '(?:,' + range + ')*'
  })

  var monthValues = 'JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC'
  var monthRange = '(?:' + monthValues + ')(?:(?:-)(?:' + monthValues + '))?'
  regexByField['month'] += '|\\?|\\*|' + monthRange + '(?:,' + monthRange + ')*'

  var dayOfWeekValues = 'MON|TUE|WED|THU|FRI|SAT|SUN'
  var dayOfWeekRange = '(?:' + dayOfWeekValues + ')(?:(?:-)(?:' + dayOfWeekValues + '))?'
  regexByField['dayOfWeek'] += '|\\?|\\*|' + dayOfWeekRange + '(?:,' + dayOfWeekRange + ')*'

  if (!type)
    return (
      '^\\s*($' +
      '|#' +
      '|\\w+\\s*=' +
      '|' +
      '(' +
      regexByField['sec'] +
      ')\\s+' +
      '(' +
      regexByField['min'] +
      ')\\s+' +
      '(' +
      regexByField['hour'] +
      ')\\s+' +
      '(' +
      regexByField['day'] +
      ')\\s+' +
      '(' +
      regexByField['month'] +
      ')\\s+' +
      '(' +
      regexByField['dayOfWeek'] +
      ')(|\\s)+' +
      ')$'
    )
  else return `^${regexByField[type]}$`
}

@customElement('ox-input-crontab')
export class OxInputCrontab extends OxFormField {
  static styles = css`
    :host {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
    }

    :host * {
      box-sizing: border-box;
    }
    :host *:focus {
      outline: none;
    }

    form {
      display: grid;
      width: 100%;
      height: 100%;
      padding: 1rem;
      grid-template: auto auto 1fr auto / repeat(6, 1fr);
      grid-gap: 0.5rem;
      justify-content: center;
      align-items: center;
      overflow: auto;
    }

    label[for='example'] {
      text-align: right;
      grid-column: 3;
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
      text-transform: capitalize;
    }

    #example {
      grid-column: 4 / span 3;
      width: 100%;
      box-sizing: border-box;
      padding: 0 var(--spacing-small);
      height: var(--form-element-height-medium);
      border: 1px solid var(--md-sys-color-outline);
      border-radius: var(--md-sys-shape-corner-small);
      background-color: var(--md-sys-color-on-primary);
      text-transform: capitalize;
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
    }

    input {
      width: 100%;
      box-sizing: border-box;
      padding: 0 var(--spacing-small);
      height: var(--form-element-height-medium);
      border: 1px solid var(--md-sys-color-outline);
      border-radius: var(--md-sys-shape-corner-small);
      background-color: var(--md-sys-color-on-primary);
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
    }

    input:focus {
      outline: none;
      border-color: var(--md-sys-color-secondary-fixed-dim);
    }

    input:invalid {
      border-color: var(--md-sys-color-error);
      color: var(--md-sys-color-error);
    }

    label {
      width: 100%;
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
    }

    label:not([for='example']) {
      text-align: center;
    }

    #input-wrapper {
      grid-column: span 6;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.25rem;
    }

    #input-wrapper > div {
      flex: 1;
      display: grid;
      grid-template-rows: 1fr auto;
      grid-gap: var(--spacing-small);
      min-width: 60px;
      max-width: 33%;
      margin: 0.25rem;
    }

    #tooltip {
      grid-column: span 6;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 0;
      margin: auto;
      grid-auto-rows: min-content;
      align-self: center;
      align-items: center;
    }

    #tooltip > div {
      padding: 0.25rem 0.5rem;
      border-bottom: var(--md-sys-color-on-surface-variant) 1px dashed;
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
    }

    #tooltip > .crontab-value {
      text-align: right;
      color: var(--md-sys-color-surface-tint);
    }

    #tooltip > .crontab-description {
      text-align: left;
      color: var (--md-sys-color-on-primary-container);
    }

    #button-wrapper {
      grid-column: 1 / span 6;
      display: flex;
      flex-wrap: wrap-reverse;
      flex-direction: row-reverse;
      margin: -0.25rem;
    }

    md-fab {
      margin: 0.25rem;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      color: var(--md-sys-color-on-primary);
    }

    md-icon {
      font-size: 24px;
    }
  `

  @property({ type: String }) value?: string
  @property({ type: String }) second?: string
  @property({ type: String }) minute?: string
  @property({ type: String }) hour?: string
  @property({ type: String }) dayOfMonth?: string
  @property({ type: String }) month?: string
  @property({ type: String }) dayOfWeek?: string

  @state() tooltip: { value: string; description: string }[] = []

  render() {
    return html`
      <form>
        <label for="example">${i18next.t('label.examples')}</label>
        <select
          id="example"
          @change=${(e: Event) => (this.value = (e.currentTarget as HTMLInputElement).value)}
          .value=${this.value || ''}
        >
          <optgroup label="String(${i18next.t('label.second by second')})">
            <option value="* * * * * *">${i18next.t('text.every second')}</option>
            <option value="0/2 * * * * *">${i18next.t('text.every 2 seconds')}</option>
            <option value="0/15 * * * * *">${i18next.t('text.every 15 seconds')}</option>
            <option value="0/30 * * * * *">${i18next.t('text.every 30 seconds')}</option>
          </optgroup>
          <optgroup label="String(${i18next.t('label.minute by minute')})">
            <option value="0 * * * * *">${i18next.t('text.every minute')}</option>
            <option value="0 0/2 * * * *">${i18next.t('text.every 2 minutes')}</option>
            <option value="0 0/15 * * * *">${i18next.t('text.every 15 minutes')}</option>
            <option value="0 0/30 * * * *">${i18next.t('text.every half hour')}</option>
          </optgroup>
          <optgroup label="String(${i18next.t('label.hourly')})">
            <option value="0 0 * * * *">${i18next.t('text.every hour')}</option>
            <option value="0 0 0/2 * * *">${i18next.t('text.every 2 hours')}</option>
            <option value="0 0 0/12 * * *">${i18next.t('text.every 12 hours')}</option>
            <option value="0 0 10-19 * * *">${i18next.t('text.every hour during working time')}</option>
          </optgroup>
          <optgroup label="String(${i18next.t('label.daily')})">
            <option value="0 0 0 * * *">${i18next.t('text.every day')}</option>
          </optgroup>
          <optgroup label="String(${i18next.t('label.weekly')})">
            <option value="0 0 0 * * SUN">${i18next.t('text.every sunday')}</option>
            <option value="0 0 0 * * 0">${i18next.t('text.every sunday')}(2)</option>
            <option value="0 0 0 * * 1-5">${i18next.t('text.every weekday')}</option>
          </optgroup>
          <optgroup label="String(${i18next.t('label.monthly')})">
            <option value="0 0 0 1 * *">${i18next.t('text.the first day of every month')}</option>
            <option value="0 0 10 21 * *">${i18next.t('text.10 am on every payday')}</option>
          </optgroup>
          <optgroup label="String(${i18next.t('label.yearly')})">
            <option value="0 0 0 1 1 *">${i18next.t('text.the first day of every year')}</option>
            <option value="0 0 0 25 12 *">${i18next.t('text.every christmas')}</option>
          </optgroup>
        </select>
        <div id="input-wrapper">
          <div>
            <input
              id="second-input"
              class="second"
              type="text"
              .value=${this.second || ''}
              @input=${(e: Event) => (this.second = (e.currentTarget as HTMLInputElement).value)}
              @focus=${(e: Event) => {
                this.showTooltip('second')
              }}
              pattern=${createCronRegex('sec')}
              required
            />
            <label for="second-input" class="second">${i18next.t('label.second')}</label>
          </div>
          <div>
            <input
              id="minute-input"
              class="minute"
              type="text"
              .value=${this.minute || ''}
              @input=${(e: Event) => (this.minute = (e.currentTarget as HTMLInputElement).value)}
              @focus=${(e: Event) => {
                this.showTooltip('minute')
              }}
              pattern=${createCronRegex('min')}
              required
            />
            <label for="minute-input" class="minute">${i18next.t('label.minute')}</label>
          </div>
          <div>
            <input
              id="hour-input"
              class="hour"
              type="text"
              .value=${this.hour || ''}
              @input=${(e: Event) => (this.hour = (e.currentTarget as HTMLInputElement).value)}
              @focus=${(e: Event) => {
                this.showTooltip('hour')
              }}
              pattern=${createCronRegex('hour')}
              required
            />
            <label for="hour-input" class="hour">${i18next.t('label.hour')}</label>
          </div>
          <div>
            <input
              id="day-of-month-input"
              class="day-of-month"
              type="text"
              .value=${this.dayOfMonth || ''}
              @input=${(e: Event) => (this.dayOfMonth = (e.currentTarget as HTMLInputElement).value)}
              @focus=${(e: Event) => {
                this.showTooltip('dayOfMonth')
              }}
              pattern=${createCronRegex('day')}
              required
            />
            <label for="day-of-month-input" class="day-of-month">${i18next.t('label.day-of-month')}</label>
          </div>
          <div>
            <input
              id="month-input"
              class="month"
              type="text"
              .value=${this.month || ''}
              @input=${(e: Event) => (this.month = (e.currentTarget as HTMLInputElement).value)}
              @focus=${(e: Event) => {
                this.showTooltip('month')
              }}
              pattern=${createCronRegex('month')}
              required
            />
            <label for="month-input" class="month">${i18next.t('label.month')}</label>
          </div>
          <div>
            <input
              id="day-of-week-input"
              class="day-of-week"
              type="text"
              .value=${this.dayOfWeek || ''}
              @input=${(e: Event) => (this.dayOfWeek = (e.currentTarget as HTMLInputElement).value)}
              @focus=${(e: Event) => {
                this.showTooltip('dayOfWeek')
              }}
              pattern=${createCronRegex('dayOfWeek')}
              required
            />
            <label for="day-of-week-input" class="day-of-week">${i18next.t('label.day-of-week')}</label>
          </div>
        </div>
        <div id="tooltip">
          ${this.tooltip.map(
            tip => html`
              <div class="crontab-value">${tip.value}</div>
              <div class="crontab-description">${i18next.t(`text.${tip.description}`)}</div>
            `
          )}
        </div>
        <div id="button-wrapper">
          <md-fab
            title="clear"
            @click=${(e: Event) => {
              e.preventDefault()
              e.stopPropagation()
              this.value = ''
              this.dispatchEvent(
                new CustomEvent('change', {
                  bubbles: true,
                  composed: true,
                  detail: this.value
                })
              )
            }}
          >
            <md-icon slot="icon">delete</md-icon>
          </md-fab>
        </div>
      </form>
    `
  }

  get focusableElements(): HTMLElement[] {
    return Array.from(this.renderRoot.querySelectorAll('select, input, button'))
  }

  firstUpdated() {
    ;(this.renderRoot.querySelector('input') as HTMLInputElement).focus()
    this.renderRoot.addEventListener('change', this.onChange.bind(this))
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('value')) {
      var values = (this.value || '').split(' ')

      if (values.length == 1) values = ['*', '*', '*', '*', '*', '*']
      else if (values.length == 5) values = ['0'].concat(values)

      this.second = values[0]
      this.minute = values[1]
      this.hour = values[2]
      this.dayOfMonth = values[3]
      this.month = values[4]
      this.dayOfWeek = values[5]
    }
  }

  showTooltip(type: 'second' | 'minute' | 'hour' | 'dayOfMonth' | 'month' | 'dayOfWeek') {
    switch (type) {
      case 'second':
      case 'minute':
        this.tooltip = [
          {
            value: '*',
            description: 'any value'
          },
          {
            value: ',',
            description: 'value list separator'
          },
          {
            value: '-',
            description: 'range of values'
          },
          {
            value: '/',
            description: 'step values'
          },
          {
            value: '0-59',
            description: 'allowed values'
          }
        ]
        break
      case 'hour':
        this.tooltip = [
          {
            value: '*',
            description: 'any value'
          },
          {
            value: ',',
            description: 'value list separator'
          },
          {
            value: '-',
            description: 'range of values'
          },
          {
            value: '/',
            description: 'step values'
          },
          {
            value: '0-23',
            description: 'allowed values'
          }
        ]
        break

      case 'dayOfMonth':
        this.tooltip = [
          {
            value: '*',
            description: 'any value'
          },
          {
            value: ',',
            description: 'value list separator'
          },
          {
            value: '-',
            description: 'range of values'
          },
          {
            value: '/',
            description: 'step values'
          },
          {
            value: '1-31',
            description: 'allowed values'
          }
        ]
        break

      case 'month':
        this.tooltip = [
          {
            value: '*',
            description: 'any value'
          },
          {
            value: ',',
            description: 'value list separator'
          },
          {
            value: '-',
            description: 'range of values'
          },
          {
            value: '/',
            description: 'step values'
          },
          {
            value: '1-12',
            description: 'allowed values'
          },
          {
            value: 'JAN-DEC',
            description: 'alternative single values'
          }
        ]
        break

      case 'dayOfWeek':
        this.tooltip = [
          {
            value: '*',
            description: 'any value'
          },
          {
            value: ',',
            description: 'value list separator'
          },
          {
            value: '-',
            description: 'range of values'
          },
          {
            value: '/',
            description: 'step values'
          },
          {
            value: '0-6',
            description: 'allowed values'
          },
          {
            value: 'SUN-SAT',
            description: 'alternative single values'
          }
        ]
        break

      default:
        this.tooltip = []
        break
    }
  }

  onChange() {
    var form = this.renderRoot.querySelector('form') as HTMLFormElement
    var valid = form.checkValidity()
    if (!valid) {
      form.reportValidity()
      return
    }

    this.value = `${this.second} ${this.minute} ${this.hour} ${this.dayOfMonth} ${this.month} ${this.dayOfWeek}`

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }
}
