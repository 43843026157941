import { html } from 'lit'

import { FieldRenderer } from '../types'

export const OxGristRendererColor: FieldRenderer = (value, column, record, rowIndex, field) => {
  value = value == null ? '#000' : value

  return html`
    <div
      style="display:flex;align-items:center;justify-content:center;width:100%;height:100%;background-color:${value};"
    >
      ${value ? html`<p>${value}</p>` : html`<p>&nbsp</p>`}
    </div>
  `
}
