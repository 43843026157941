import { DataListField } from '../data-list-field'
import { DataListGutter } from '../data-list-gutter'
import { RecordPartial } from '../record-partial'

/**
 * record-partial 의 click handler
 *
 * - handler의 this 는 record-partial임.
 */
export function recordPartialClickHandler(this: RecordPartial, e: MouseEvent): void {
  e.stopPropagation()

  /* target should be [content] or 'ox-card-field' or 'ox-card-gutter' */
  var target = (e.target as Element).closest('ox-list-field, ox-list-gutter') as DataListField | DataListGutter
  var { column, rowIndex } = target || {}

  /* do column click handler */
  if (column) {
    var { click } = column.handlers
    if (click) {
      click(this.config.columns, this.data, column, this.record, rowIndex, target)
      return
    }
  }

  /* do rows click handler */
  var { click: rowsClick } = this.config.rows.handlers
  if (rowsClick) {
    rowsClick(this.config.columns, this.data, column, this.record, rowIndex, target)
  } else {
    /* content 가 클릭된 경우 - 레코드뷰 팝업을 실행한다. */
    this.config.rows.editable && this.popupRecordView()
  }
}
