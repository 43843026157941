/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'

import { css, html } from 'lit'
import { customElement, query } from 'lit/decorators.js'

import { ScrollbarStyles } from '@operato/styles'
import { FileDropHelper } from '@operato/utils'

import { OxFormField } from './ox-form-field.js'

const IMAGE_FALLBACK = new URL('../../assets/images/no-image.png', import.meta.url).href

@customElement('ox-input-image')
export class OxInputImage extends OxFormField {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        position: relative;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        place-content: center;
        border-radius: var(--border-radius);
        padding: var(--padding-default, 9px);
        min-height: 100px;
        text-transform: capitalize;

        border: var(--file-uploader-border);
        background-color: var(--md-sys-color-background);
        font: var(--file-uploader-font) !important;
        color: var(--file-uploader-color);

        overflow: hidden;
      }

      :host(.candrop) {
        background-color: var(--file-uploader-candrop-background-color);
      }

      img {
        min-height: 100%;
        margin: auto;
      }

      [overlay] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0%;
        background-color: var(--md-sys-color-surface);

        display: flex;
        place-content: center;
      }

      [overlay]:hover {
        opacity: 50%;
      }

      #input-file {
        display: none;
      }

      label {
        border: none;
        flex: 1;
        display: flex;
        place-content: center;
      }

      md-icon {
        align-self: center;

        color: var(--file-uploader-icon-color, black);
        --md-icon-size: var(--file-uploader-icon-size, 36px);
      }
    `
  ]

  @query('#input-file') fileInput!: HTMLInputElement

  render() {
    var value = this.value
    var src: string

    if (!value) {
      src = IMAGE_FALLBACK
    } else if (typeof value === 'string') {
      src = value
    } else {
      src = URL.createObjectURL(value)
      requestAnimationFrame(() => URL.revokeObjectURL(src))
    }

    return html`
      <img
        src=${src}
        style="max-width: 100%;"
        onerror="this.src !== '${IMAGE_FALLBACK}' && (this.src = '${IMAGE_FALLBACK}')"
      />

      <div overlay>
        <label>
          <input
            id="input-file"
            type="file"
            accept="image/*"
            hidden
            capture="environment"
            @change=${(e: Event) => this._onChangeValue(e)}
            ?disabled=${this.disabled}
          />
          <md-icon>upload</md-icon>
        </label>
      </div>
    `
  }

  firstUpdated() {
    FileDropHelper.set(this)

    this.addEventListener('file-drop', (e: Event) => {
      const detail = (e as CustomEvent).detail
      this.value = detail[0]

      this._notifyChange()
    })
  }

  get file() {
    return this.value
  }

  reset() {
    this.fileInput.value = ''
    this.value = []

    this._notifyChange()
  }

  _onChangeValue(e: Event) {
    const fileInput = e.currentTarget as HTMLInputElement

    this.value = [...Array.from(fileInput.files as FileList)][0]

    fileInput.files = null

    this._notifyChange()
  }

  _notifyChange() {
    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }
}
