import { DataListField } from '../data-list-field'
import { DataListGutter } from '../data-list-gutter'
import { RecordPartial } from '../record-partial'

/**
 * record-partial 의 dblclick handler
 *
 * - handler의 this 는 record-partial임.
 */
export function recordPartialDblClickHandler(this: RecordPartial, e: MouseEvent): void {
  e.stopPropagation()

  /* target should be [content] or 'ox-card-field' or 'ox-card-gutter' */
  var target = (e.target as Element).closest('ox-list-field, ox-list-gutter') as DataListField | DataListGutter
  var { column, record, rowIndex } = target || {}

  if (column && column.type == 'gutter') {
    /*
     * Gutter가 클릭된 경우
     * TODO record-partial의 dblclick handling의 성능을 확인하고,
     * gutter에 dblclick를 적용할 수 있는 지 확인한 후에 활성화하자.
     */
    /* do column click handler */
    // var { dblclick } = column.handlers
    // dblclick && dblclick(this.columns, this.data, column, record, rowIndex, target)
    // return
  } else {
    var { dblclick: rowsDblClick } = this.config.rows.handlers
    if (rowsDblClick) {
      rowsDblClick(this.config.columns, this.data, column, record, rowIndex, target)
    }
  }
}
