/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, PropertyValues } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { minimalSetup } from 'codemirror'
import { history, historyKeymap, indentWithTab } from '@codemirror/commands'
import { EditorView, highlightActiveLine, keymap, lineNumbers } from '@codemirror/view'
import { autocompletion, closeBrackets } from '@codemirror/autocomplete'
import { bracketMatching, LanguageSupport, syntaxHighlighting } from '@codemirror/language'
import { oneDarkHighlightStyle, oneDark } from '@codemirror/theme-one-dark'

import { javascript } from '@codemirror/lang-javascript'
import { sql } from '@codemirror/lang-sql'
import { json } from '@codemirror/lang-json'

import { ScrollbarStyles } from '@operato/styles'
import { togglefullscreen } from '@operato/utils'

import { OxFormField } from './ox-form-field'

/**
WEB Component for code-mirror code editor.

Example:

  <ox-input-code .value=${text} language="javascript" show-line-numbers>
  </ox-input-code>
*/
@customElement('ox-input-code')
export class OxInputCode extends OxFormField {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        position: relative;
        background: white;
        overflow: auto;
        border:1px solid var(--md-sys-color-outline);
        border-radius:var(--md-sys-shape-corner-small);
      }

      .cm-editor {
        flex: 1;
        
      }
    `
  ]

  /**
   * `value`는 에디터에서 작성중인 contents이다.
   */
  @property({ type: String }) value: string = ''
  @property({ type: Boolean, attribute: 'show-line-numbers' }) showLineNumbers: boolean = false
  @property({ type: String }) language?: string = 'javascript'

  private _self_changing: boolean = false
  private _editor?: EditorView
  private _changed: boolean = false

  updated(changes: PropertyValues<this>) {
    if (changes.has('value') && this.editor && !this._self_changing) {
      const to = this.editor.state.doc.toString().length
      this.editor.dispatch({
        changes: { from: 0, to, insert: this.value === undefined ? '' : String(this.value) }
      })
    }
  }

  get editor() {
    if (!this._editor) {
      let language: LanguageSupport[] = []
      switch (this.language) {
        case 'sql':
          language = [sql()]
          break
        case 'json':
          language = [json()]
          break
        case 'javascript':
          language = [javascript()]
          break
        default:
          break
      }

      this._editor = new EditorView({
        doc: this.value,
        extensions: [
          minimalSetup,
          ...language,
          ...(this.showLineNumbers ? [lineNumbers()] : []),
          bracketMatching(),
          closeBrackets(),
          history(),
          autocompletion(),
          oneDark,
          syntaxHighlighting(oneDarkHighlightStyle),
          highlightActiveLine(),
          keymap.of([...historyKeymap, indentWithTab]),
          EditorView.updateListener.of(async v => {
            if (v.docChanged) {
              this._self_changing = true
              this._changed = true

              await this.updateComplete

              this._self_changing = false
            }
          })
        ],
        parent: this.renderRoot
      })
    }

    this._editor.contentDOM.addEventListener('keydown', event => {
      event.stopPropagation()

      if (event.key === 'Escape') {
        togglefullscreen(this._editor!.contentDOM)
      }
    })

    this._editor.contentDOM.addEventListener('blur', e => {
      if (!this._changed) {
        return
      }

      this.value = this._editor!.state.doc.toString()
      this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true, detail: this.value }))
    })

    return this._editor
  }
}
