/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import './ox-input-angle'

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { localized, msg } from '@lit/localize'

import { OxFormField } from './ox-form-field'
import { OxInputAngle } from './ox-input-angle'

@localized()
@customElement('ox-input-3dish')
export class OxInput3Dish extends OxFormField {
  static styles = [
    css`
      :host {
        display: grid;
        grid-template-columns: repeat(7, minmax(50px, 1fr));
        grid-gap: var(--spacing-medium);
      }

      label {
        text-align: right;
        align-self: center;
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-on-primary-container);
        text-transform: var(--label-text-transform);
        grid-column: span 1;
      }

      span {
        grid-column: span 2;
        text-align: center;
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-surface-tint);
        font-weight: var(--md-sys-typescale-label-medium-weight, var(--md-ref-typeface-weight-medium, 500));
      }

      input,
      ox-input-angle {
        grid-column: span 2;
        box-sizing: border-box;
        padding: 0 var(--spacing-small);
        height: var(--form-element-height-medium);
        border: 1px solid var(--md-sys-color-outline);
        border-radius: var(--md-sys-shape-corner-small);
        overflow: hidden;
        background-color: var(--md-sys-color-on-primary);
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-on-primary-container);
      }

      ox-input-angle {
        --input-border: 0;
      }

      input:focus {
        outline: none;
        border-color: var(--md-sys-color-secondary-fixed-dim);
      }
    `
  ]

  @property({ type: Object }) dimension?: { width?: number; height?: number; depth?: number }
  @property({ type: Object }) position?: { x?: number; y?: number; z?: number }
  @property({ type: Object }) rotate?: { x?: number; y?: number; z?: number }
  @property({ type: Object }) scale?: { x?: number; y?: number; z?: number }

  firstUpdated() {
    this.renderRoot.addEventListener('change', this._onChange.bind(this))
  }

  _onChange(e: Event) {
    e.stopPropagation()
    e.preventDefault()

    var element = e.target as HTMLElement
    var id = element.id
    var prop = id.substr(1)
    var value = Number((element as HTMLInputElement).value)

    switch (element.tagName) {
      case 'PROPERTY-ANGLE':
        value = Number((element as OxInputAngle).value || 0)
        break
    }

    switch (id) {
      case 'tx':
      case 'ty':
      case 'tz':
        this.position = {
          ...this.position,
          [prop]: value
        }

        this.dispatchEvent(
          new CustomEvent('position-changed', {
            bubbles: true,
            composed: true,
            detail: {
              value: this.position
            }
          })
        )
        break

      case 'rx':
      case 'ry':
      case 'rz':
        this.rotate = {
          ...this.rotate,
          [prop]: value
        }

        this.dispatchEvent(
          new CustomEvent('rotate-changed', {
            bubbles: true,
            composed: true,
            detail: {
              value: this.rotate
            }
          })
        )
        break

      case 'sx':
      case 'sy':
      case 'sz':
        this.scale = {
          ...this.scale,
          [prop]: value
        }

        this.dispatchEvent(
          new CustomEvent('scale-changed', {
            bubbles: true,
            composed: true,
            detail: {
              value: this.scale
            }
          })
        )
        break

      default:
        // dimension
        this.dimension = {
          ...this.dimension,
          [prop]: value
        }

        this.dispatchEvent(
          new CustomEvent('dimension-changed', {
            bubbles: true,
            composed: true,
            detail: {
              value: this.dimension
            }
          })
        )
    }

    this.value = {
      position: this.position,
      rotate: this.rotate,
      scale: this.scale,
      dimension: this.dimension
    }

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }

  render() {
    return html`
      <label></label> <span>${msg('x-axes')}</span>
      <span>${msg('y-axes')}</span>
      <span>${msg('z-axes')}</span>

      <label>${msg('dimension')}</label>
      <input type="number" id="dwidth" .value=${String(this.dimension?.width)} ?disabled=${this.disabled} />
      <input type="number" id="dheight" .value=${String(this.dimension?.height)} ?disabled=${this.disabled} />
      <input type="number" id="ddepth" .value=${String(this.dimension?.depth)} ?disabled=${this.disabled} />

      <label>${msg('position')}</label>
      <input type="number" id="tx" .value=${String(this.position?.x)} ?disabled=${this.disabled} />
      <input type="number" id="ty" .value=${String(this.position?.y)} ?disabled=${this.disabled} />
      <input type="number" id="tz" .value=${String(this.position?.z)} ?disabled=${this.disabled} />

      <label>${msg('scale')}</label>
      <input type="number" id="sx" .value=${String(this.scale?.x)} ?disabled=${this.disabled} />
      <input type="number" id="sy" .value=${String(this.scale?.y)} ?disabled=${this.disabled} />
      <input type="number" id="sz" .value=${String(this.scale?.z)} ?disabled=${this.disabled} />

      <label>${msg('rotate')}</label>
      <ox-input-angle id="rx" .value=${String(this.rotate?.x)} ?disabled=${this.disabled}></ox-input-angle>
      <ox-input-angle id="ry" .value=${String(this.rotate?.y)} ?disabled=${this.disabled}></ox-input-angle>
      <ox-input-angle id="rz" .value=${String(this.rotate?.z)} ?disabled=${this.disabled}></ox-input-angle>
    `
  }
}
