import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit'
import { customElement, state } from 'lit/decorators.js'

import { DataGrist } from '../data-grist'
import { ColumnConfig, GristConfig, SorterConfig } from '../types'

@customElement('ox-sorters-control')
export class SortersControl extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
      }

      [option] {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 3px var(--spacing-small);
      }

      [option] > span {
        margin-right: auto;
        text-transform: capitalize;
        user-select: none;
      }
      [option] md-icon {
        margin-left: var(--spacing-medium);
        --md-icon-size: var(--fontsize-large);
        color: var(--md-sys-color-on-primary-container);
      }
      [option] sub {
        color: var(--md-sys-color-on-primary-container);
      }
    `
  ]

  @state() config!: GristConfig
  @state() columns: ColumnConfig[] = []
  @state() sorters: SorterConfig[] = []

  connectedCallback(): void {
    super.connectedCallback()

    const grist = this.closest('ox-grist') as DataGrist

    if (grist) {
      this.config = grist.compiledConfig
      grist.addEventListener('config-change', (e: Event) => {
        this.config = (e as CustomEvent).detail
      })

      grist.addEventListener('fetch-params-change', e => {
        const { sorters, from } = (e as CustomEvent).detail || {}
        if (from === 'sorters-control') {
          return
        }

        sorters && (this.sorters = sorters)
      })
    }
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('config')) {
      this.columns = this.config.columns.filter(column => column.sortable)
      this.sorters = this.sorters || this.config.sorters || []
    }
  }

  render(): TemplateResult {
    const columns = this.columns

    const current = this.sorters || []

    return html`
      ${columns.map(column => {
        const { name } = column
        var rank = current.findIndex(sorter => sorter.name === name) + 1
        var desc = rank !== 0 ? current[rank - 1].desc : null

        if (current.length <= 1) {
          rank = 0
        }

        return html`
          <div
            option
            @click=${(e: MouseEvent) => {
              this.onChangeSort(name)
            }}
          >
            <span>${column.header.renderer.call(this, column)}</span>
            ${desc === null
              ? html``
              : html`
                  <md-icon>${desc ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</md-icon>
                  ${rank === 0 ? html`` : html`<sub>${rank}</sub>`}
                `}
          </div>
        `
      })}
    `
  }

  onChangeSort(name: string) {
    var sorters = [...this.sorters]

    var idx = sorters.findIndex(sorter => sorter.name == name)
    if (idx !== -1) {
      let sorter = sorters[idx]
      if (sorter.desc) {
        sorters.splice(idx, 1)
      } else {
        sorter.desc = true
      }
    } else {
      var sorter = {
        name
      }

      sorters.push(sorter)
    }

    this.sorters = sorters

    this.dispatchEvent(
      new CustomEvent('fetch-params-change', {
        bubbles: true,
        composed: true,
        detail: {
          sorters: this.sorters,
          from: 'sorters-control'
        }
      })
    )
  }
}
