/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

/**
여러 버튼 중에서 하나만 눌리거나, 모두 눌리지 않은 상태만을 갖는 라디오 형태의 버튼이다.

Example:

  <ox-buttons-radio
    class="light"
    @change=${(e: CustomEvent) => console.log(e.detail)}
    value="1"
  >
    <md-outlined-button data-value="1" ?active=${value == '1'}>Option 1</md-outlined-button>
    <md-outlined-button data-value="2" ?active=${value == '2'}>Option 2</md-outlined-button>
    <md-outlined-button data-value="3" ?active=${value == '3'}>Option 3</md-outlined-button>
  </ox-buttons-radio>
*/
@customElement('ox-buttons-radio')
export class OxButtonsRadio extends OxFormField {
  static styles = [
    css`
      :host {
        display: inline-block;
        background-color: var(--ox-buttons-radio-background-color, var(--md-sys-color-surface));

        --md-outlined-button-label-text-color: var(--md-sys-color-on-surface-variant);
        --md-outlined-button-label-text-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        --md-outlined-button-container-height: var(--form-element-height-medium);
        --md-outlined-button-container-shape: var(--md-sys-shape-corner-small);
        --md-outlined-button-leading-space: var(--spacing-large);
        --md-outlined-button-trailing-space: var(--spacing-large);
        --md-outlined-button-hover-label-text-color: var(--md-sys-color-primary);
        --md-outlined-button-pressed-outline-color: var(--md-sys-color-primary);
        --md-outlined-button-pressed-label-text-color: var(--md-sys-color-primary);

        --md-filled-button-container-color: var(--md-sys-color-primary);
        --md-filled-button-label-text-color: var(--md-sys-color-primary-container);
        --md-filled-button-label-text-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        --md-filled-button-container-height: var(--form-element-height-medium);
        --md-filled-button-container-shape: var(--md-sys-shape-corner-small);
        --md-filled-button-leading-space: var(--spacing-large);
        --md-filled-button-trailing-space: var(--spacing-large);
        --md-filled-button-hover-label-text-color: var(--md-sys-color-secondary-container);
        --md-filled-button-pressed-label-text-color: var(--md-sys-color-secondary-container);
        --md-filled-button-focus-label-text-color: var(--md-sys-color-secondary-container);

        --md-text-button-container-height: var(--form-element-height-medium);
        --md-text-button-container-shape: var(--md-sys-shape-corner-small);

        --md-elevated-button-container-height: var(--form-element-height-medium);
        --md-elevated-button-container-shape: var(--md-sys-shape-corner-small);
      }
    `
  ]

  /**
   * `value`는 버튼의 눌린 상태를 값으로 갖는 속성이다.
   */
  @property({ type: Object }) value: Object | null = null
  @property({ type: Boolean }) mandatory!: boolean

  @state() _slotObserver: MutationObserver = new MutationObserver(mutations => {
    this._onChanged()
  })

  render() {
    return html` <slot @click=${(e: Event) => this._onTapButton(e)}></slot> `
  }

  connectedCallback() {
    super.connectedCallback()

    this._slotObserver?.observe(this, { childList: true })
  }

  disconnectedCallback() {
    super.disconnectedCallback()

    this._slotObserver?.disconnect()
  }

  updated(changes: PropertyValues<this>) {
    changes.has('value') && this._onChanged()
  }

  get buttons() {
    return Array.from(this.querySelectorAll('*'))
  }

  _onChanged() {
    this.buttons.forEach(button => {
      if (this.value === button.getAttribute('data-value')) {
        button.setAttribute('active', '')
      } else {
        button.removeAttribute('active')
      }
    })
  }

  _onTapButton(e: Event) {
    var target = e.target as HTMLElement
    target = target.closest('[data-value]') as HTMLElement

    if (!target || target === this) {
      return
    }

    var old = this.value

    if (!this.mandatory) {
      if (!target.getAttribute('active')) {
        this.value = target.getAttribute('data-value')
        target.setAttribute('active', '')
      } else {
        this.value = null
        target.removeAttribute('active')
      }
    } else {
      this.value = target.getAttribute('data-value')
      target.setAttribute('active', '')
    }

    if (old !== this.value) {
      this.dispatchEvent(
        new CustomEvent('change', {
          bubbles: true,
          composed: true,
          detail: this.value
        })
      )
    }
  }
}
