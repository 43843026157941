import { DataGridBody } from '../data-grid-body'
import { DataGridField } from '../data-grid-field'

/**
 * ox-grid-body 의 dblclick handler
 *
 * - handler의 this 는 ox-grid-body임.
 */
export async function dataGridBodyDblclickHandler(this: DataGridBody, e: Event): Promise<void> {
  e.stopPropagation()

  if (this.editTarget) {
    /* editTarget이 새로 설정되지 않았다면, 이후 기능이 실행된다. */
    return
  }

  /* target should be 'ox-grid-field' */
  var target = (e.target as Element).closest('ox-grid-field') as DataGridField
  var { column, record, rowIndex, columnIndex } = target || {}

  // if (!column) {
  //   /* 여백 컬럼이 클릭된 경우 */
  //   return
  // }

  // if (!isNaN(rowIndex) && !isNaN(columnIndex)) {
  //   this.startEditTarget(rowIndex, columnIndex)
  // } else {
  //   console.error('should not be here.')
  //   return
  // }

  /* do column dblclick handler */
  if (column) {
    var { dblclick } = column.handlers
    dblclick && dblclick(this.columns, this.data, column, record, rowIndex, target, e)
  }

  /* do rows dblclick handler */
  var { dblclick: rowsDblclick } = this.config.rows.handlers
  rowsDblclick && rowsDblclick(this.columns, this.data, column, record, rowIndex, target, e)
}
