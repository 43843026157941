import { FilterConfigObject, FilterSelectRenderer } from '../types'

import { html } from 'lit-html'

export const FilterRangeNumber: FilterSelectRenderer = (column, value, owner) => {
  const filter = column.filter as FilterConfigObject
  const type = filter?.type || column.type

  const [from, to] = value instanceof Array ? value : []

  return html`
    <input
      name=${column.name}
      type="number"
      placeholder="minimum"
      .value=${from}
      from
      @keyup=${(e: KeyboardEvent) => {
        if (e.key !== 'Enter') {
          return
        }
        ;(e.target as HTMLElement).dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: Array.from(
                (e.target as HTMLElement).parentElement!.querySelectorAll('input[from], input[to]')
              ).map(input => (input as HTMLInputElement).value)
            }
          })
        )
      }}
    />
    ~
    <input
      name=${column.name}
      type="number"
      placeholder="maximum"
      .value=${to}
      to
      @keyup=${(e: KeyboardEvent) => {
        if (e.key !== 'Enter') {
          return
        }
        ;(e.target as HTMLElement).dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: Array.from(
                (e.target as HTMLElement).parentElement!.querySelectorAll('input[from], input[to]')
              ).map(input => (input as HTMLInputElement).value)
            }
          })
        )
      }}
    />
  `
}
