import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { OxFormField } from './ox-form-field'
import '@material/web/icon/icon.js'

export interface ColumnConfig {
  name: string
  label: string
  visible: boolean
  width: number
  order: number
}

@customElement('ox-input-table-column-config')
export class OxInputTableColumnConfig extends OxFormField {
  static styles = [
    css`
      :host {
        display: block;
        padding: 4px;
        border: 1px solid var(--ox-input-table-column-config-border-color, var(--md-sys-color-on-surface));
        background-color: var(--ox-input-table-column-config-background-color, var(--md-sys-color-surface));
        color: var(--ox-input-table-column-config-color, var(--md-sys-color-on-surface));
      }

      table {
        width: auto;
        border-collapse: collapse;
        font-size: 12px;
        border: 0;
      }

      tr {
        border-bottom: 1px solid var(--ox-input-table-column-config-border-color, var(--md-sys-color-on-surface));
      }

      th,
      td {
        padding: 0 var(--spacing-small, 2px);
        text-align: left;
      }

      th {
        text-transform: capitalize;
        text-align: center;
        background-color: var(
          --ox-input-table-column-config-header-background-color,
          var(--md-sys-color-secondary-container)
        );
        color: var(--ox-input-table-column-config-header-background-color, var(--md-sys-color-on-secondary-container));
        white-space: nowrap;
      }

      th *,
      td * {
        vertical-align: middle;
      }

      td[width] {
        width: 60px;
        text-align: right;
      }

      input {
        font-size: 12px;
        padding: 0;
        margin: 0;
        border: none;
        width: 100%;
        box-sizing: border-box;
        outline: none;
        background-color: var(--ox-input-table-column-config-input-background-color, var(--md-sys-color-surface));
        color: var(--ox-input-table-column-config-input-color, var(--md-sys-color-on-surface));
      }

      input[type='number'] {
        text-align: right;
      }

      md-icon {
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: none;
        background: none;

        --md-icon-size: 16px;
      }

      md-icon[disabled] {
        cursor: not-allowed;
        color: var(--ox-input-table-column-config-disabled-color, var(--md-sys-color-surface-variant, #ccc));
      }
    `
  ]

  @property({ type: Array }) value: ColumnConfig[] = []

  render() {
    console.log('this.value', this.value)
    return html`
      <table>
        <thead>
          <tr>
            <th></th>
            <th>name</th>
            <th>label</th>
            <th>width</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          ${(this.value || []).map(
            (col, index) => html`
              <tr name=${col.name}>
                <td>
                  <input
                    type="checkbox"
                    .checked=${col.visible}
                    @change=${(e: Event) => this._updateVisibility(e, index)}
                  />
                </td>
                <td name>${col.name}</td>
                <td>
                  <input type="text" .value=${col.label} @input=${(e: Event) => this._updateLabel(e, index)} />
                </td>
                <td width>
                  <input
                    type="number"
                    .value=${String(col.width)}
                    @input=${(e: Event) => this._updateWidth(e, index)}
                  />
                </td>
                <td buttons>
                  <md-icon class="icon-button" @click=${() => this._moveUp(index)} ?disabled=${index === 0}>
                    arrow_upward
                  </md-icon>
                  <md-icon
                    class="icon-button"
                    @click=${() => this._moveDown(index)}
                    ?disabled=${index === this.value.length - 1}
                  >
                    arrow_downward
                  </md-icon>
                </td>
              </tr>
            `
          )}
        </tbody>
      </table>
    `
  }

  private _updateVisibility(event: Event, index: number) {
    const target = event.target as HTMLInputElement
    this.value[index].visible = target.checked
    this.requestUpdate()
    this._notifyChange()
  }

  private _updateLabel(event: Event, index: number) {
    const target = event.target as HTMLInputElement
    this.value[index].label = target.value
    this.requestUpdate()
    this._notifyChange()
  }

  private _updateWidth(event: Event, index: number) {
    const target = event.target as HTMLInputElement
    this.value[index].width = target.valueAsNumber
    this.requestUpdate()
    this._notifyChange()
  }

  private _moveUp(index: number) {
    if (index === 0) return
    const temp = this.value[index]
    this.value[index] = this.value[index - 1]
    this.value[index - 1] = temp
    this._updateOrder()
    this.requestUpdate()
    this._notifyChange()
  }

  private _moveDown(index: number) {
    if (index === this.value.length - 1) return
    const temp = this.value[index]
    this.value[index] = this.value[index + 1]
    this.value[index + 1] = temp
    this._updateOrder()
    this.requestUpdate()
    this._notifyChange()
  }

  private _updateOrder() {
    this.value = this.value.map((col, index) => ({
      ...col,
      order: index + 1
    }))
  }

  private _notifyChange() {
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: this.value,
        bubbles: true,
        composed: true
      })
    )
  }
}
