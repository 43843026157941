/**
 * Converts a large number into a human-readable string representation with SI unit prefixes (K, M, B, T, P, E).
 *
 * This function takes a large number as input and converts it into a human-readable string representation using SI unit prefixes
 * (K for thousand, M for million, B for billion, T for trillion, P for quadrillion, E for quintillion, etc.).
 * It checks the magnitude of the input number and selects the appropriate SI unit,
 * then formats the number with two decimal places and appends the corresponding SI unit symbol to create the readable string.
 *
 * @param {number} n - The number to be converted.
 * @returns {string} - A human-readable string representation of the number with SI unit prefixes.
 */

export function stringifyBigNumber(n: number) {
  if (n < 1000) {
    return n
  }

  var si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ]

  var index
  for (index = si.length - 1; index > 0; index--) {
    if (n >= si[index].v) {
      break
    }
  }

  return (n / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s
}
