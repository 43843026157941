import { LitElement, PropertyValues } from 'lit'
import { property } from 'lit/decorators.js'

import { ZERO_CONFIG, ZERO_DATA } from './configure/zero-config'
import {
  ColumnConfig,
  FilterValue,
  GristConfig,
  GristData,
  GristRecord,
  PaginationConfig,
  SortersConfig
} from './types'

export class DataManipulator extends LitElement {
  @property({ type: Object }) config: GristConfig = ZERO_CONFIG
  @property({ type: Object }) data: GristData = ZERO_DATA
  @property({ type: Array }) sorters: SortersConfig = []
  @property({ type: Array }) filters: FilterValue[] = []
  @property({ type: Object }) pagination: PaginationConfig = {}

  constructor() {
    super()

    this.addEventListener('select-record-change', async e => {
      var {
        records: selectedRecords,
        added = [],
        removed = []
      } = (e as CustomEvent).detail as {
        records: GristRecord[]
        added: GristRecord[]
        removed: GristRecord[]
      }

      this.onSelectRecordChanged({
        selectedRecords,
        added,
        removed
      })
    })

    /* field change processing */
    this.addEventListener('field-change', async e => {
      var { after, before, column, record, row } = (e as CustomEvent).detail as {
        after: any
        before: any
        column: ColumnConfig
        record: GristRecord
        row: number
      }

      await this.onFieldChange({ after, before, column, record, row })
    })

    /* record reset processing */
    this.addEventListener('record-reset', e => {
      var { record, row } = (e as CustomEvent).detail as {
        record: GristRecord
        row: number
      }

      this.onRecordChanged(record['__origin__'], row, null)
    })

    /* tree processing */
    this.addEventListener('collapse-all', (e: Event) => this.collapseAll())
    this.addEventListener('expand-all', (e: Event) => this.expandAll())
    this.addEventListener('collapse-node', (e: Event) => this.collapseNode((e as CustomEvent).detail as GristRecord))
    this.addEventListener('expand-node', (e: Event) => this.expandNode((e as CustomEvent).detail as GristRecord))
    this.addEventListener('check-in-tree', (e: Event) => this.onCheckInTree(e as CustomEvent))

    this.addEventListener('add-sibling-node', (e: Event) =>
      this.addSiblingNode((e as CustomEvent).detail as GristRecord)
    )
    this.addEventListener('add-child-node', (e: Event) => this.addChildNode((e as CustomEvent).detail as GristRecord))
  }

  async onFieldChange({
    after,
    before,
    column,
    record,
    row
  }: {
    after: any
    before: any
    column: ColumnConfig
    record: GristRecord
    row: number
  }) {
    /* compare changes */
    if (after === before) {
      return
    }

    var validation = column.validation
    if (validation && typeof validation == 'function') {
      if (!(await validation.call(this, after, before, record, column))) {
        return
      }
    }

    this.onRecordChanged({ [column.name]: after }, row, column)
  }

  onSelectRecordChanged({
    selectedRecords,
    added = [],
    removed = []
  }: {
    selectedRecords: GristRecord[]
    added: GristRecord[]
    removed: GristRecord[]
  }) {
    var { records } = this.data || {}
    var { selectable = false } = this.config.rows || {}

    if (!records || !selectable) {
      return
    }

    if (selectable && !selectable.multiple) {
      records.forEach(record => (record['__selected__'] = false))
    }

    if (selectedRecords) {
      records.forEach(record => (record['__selected__'] = false))
      selectedRecords.forEach(record => (record['__selected__'] = true))
    } else {
      removed.forEach(record => (record['__selected__'] = false))
      added.forEach(record => (record['__selected__'] = true))
    }

    this.requestUpdate()
  }

  onRecordChanged(
    recordData: GristRecord,
    row: number,
    column: ColumnConfig | null /* TODO column should be removed */
  ) {
    // TODO 오브젝트나 배열 타입인 경우 deepCompare 후에 변경 적용 여부를 결정한다.

    /* 빈 그리드로 시작한 경우, data 설정이 되어있지 않을 수 있다. */
    var records = this.data.records

    var beforeRecord = records[row]
    var afterRecord: GristRecord
    var wantToDelete = false
    var wantToAppend = false

    if (!recordData) {
      if (!beforeRecord) {
        /* recordData가 없고, beforeRecord도 없다면, 레코드 생성 중에 리셋된 경우이므로 아무것도 하지 않는다. */
        this.requestUpdate()
        return
      } else {
        /*
         * beforeRecord가 있는데, 빈데이타로 업데이트하고자 한다면,
         * 삭제하고자 하는 의도로 이해된다. (주의 필요)
         */
        if (beforeRecord['__dirty__'] == '+') {
          wantToDelete = true
        } else {
          afterRecord = {
            ...beforeRecord,
            __dirty__: '-'
          }
        }
      }
    } else {
      if (!beforeRecord) {
        /* 기존 레코드가 없는 경우에는 새로운 레코드가 생성된다 */
        afterRecord = {
          ...recordData,
          __dirty__: '+'
        }

        wantToAppend = true
      } else {
        let beforeDirty = beforeRecord['__dirty__']
        if (beforeDirty == '+') {
          /* 기존에 새로 생성된 레코드가 있었으며 계속 수정중이다.(레코드 레퍼런스를 유지해야한다) */
          afterRecord = Object.assign(beforeRecord, recordData, { __dirty__: '+' })
        } else {
          /* 기존에 레코드가 있었으며 계속 수정중이다.(레코드 레퍼런스를 유지해야한다) */
          afterRecord = Object.assign(beforeRecord, recordData, { __dirty__: 'M' })
        }
      }
    }

    if (wantToAppend) {
      records.push(afterRecord!)
    } else if (wantToDelete) {
      records.splice(row, 1)
    } else {
      records.splice(row, 1, afterRecord!)
    }

    this.dispatchEvent(
      new CustomEvent('record-change', {
        bubbles: true,
        composed: true,
        detail: {
          before: beforeRecord,
          after: afterRecord!,
          column,
          row
        }
      })
    )

    this.requestUpdate()
  }

  collapseAll() {
    this.refresh(false)
  }

  expandAll() {
    this.refresh(true)
  }

  collapseNode(record: GristRecord) {
    record.__expanded__ = false

    this.refresh()
  }

  expandNode(record: GristRecord) {
    record.__expanded__ = true

    this.refresh()
  }

  // onCollapse(e: CustomEvent) {
  //   const record = e.detail as GristRecord
  //   record.__expanded__ = false

  //   this.refresh()
  // }

  // onExpand(e: CustomEvent) {
  //   const record = e.detail as GristRecord
  //   record.__expanded__ = true

  //   this.refresh()
  // }

  addSiblingNode(record: GristRecord) {
    const { records } = this.data
    const toplevelRecords = records.filter(
      record => !record.__depth__
    ) /* __depth__ 가 설정되지 않았거나, 0 인 경우만 수집 */

    const { __depth__ } = record as GristRecord

    function findParent(record: GristRecord, parent?: GristRecord): GristRecord | undefined {
      var children = (parent ? parent.__children__ || [] : toplevelRecords) as GristRecord[]

      if (children.find(child => child === record)) {
        return parent
      } else {
        for (let child of children) {
          const found = findParent(record, child)
          if (found) {
            return found
          }
        }
      }
    }

    const parent = findParent(record)

    const sibling = {
      __depth__,
      __dirty__: '+'
    } as GristRecord

    if (parent) {
      const { id } = parent as GristRecord

      sibling.parent = { id }

      if (!parent.__children__) {
        parent.__children__ = [sibling]
      } else {
        let index = parent.__children__.indexOf(record)

        if (index !== -1) {
          parent.__children__ = [
            ...parent.__children__.slice(0, index + 1),
            sibling,
            ...parent.__children__.slice(index + 1)
          ]
        } else {
          parent.__children__ = [...parent.__children__, sibling]
        }
      }

      parent.__expanded__ = true
    } else {
      this.data.records = [...toplevelRecords, sibling]
    }

    this.refresh()
  }

  addChildNode(record: GristRecord) {
    const { id: parentId, __children__, __depth__ } = record as GristRecord
    const child = {
      parent: {
        id: parentId
      },
      __depth__: (__depth__ || 0) + 1,
      __dirty__: '+'
    }

    if (!record.__children__) {
      record.__children__ = [child]
    } else {
      record.__children__.unshift(child)
    }

    record.__expanded__ = true

    // this.requestUpdate()

    this.refresh()
  }

  onCheckInTree(e: CustomEvent) {
    function walkTreeCheckedUpdate(record: GristRecord, checked: 'checked' | 'unchecked') {
      const children = record.__children__

      children?.forEach(child => walkTreeCheckedUpdate(child, checked))
      record.__check_in_tree__ = checked
      record.__selected__ = checked == 'checked'
    }

    function updateCheckedAll(record: GristRecord) {
      /* 자식들의 checked 상태로 record의 checked 상태를 수정한다. */
      const children = record.__children__

      if (!children || children.length == 0) {
        return
      }

      children.forEach(child => updateCheckedAll(child))

      var checked: 'checked' | 'half-checked' | 'unchecked' | undefined =
        record.__check_in_tree__ == 'checked' ? 'checked' : undefined

      children.forEach(child => {
        const { __check_in_tree__ } = child

        if (__check_in_tree__ == 'half-checked') {
          checked = 'half-checked'
        } else if (__check_in_tree__ == 'checked') {
          checked = checked == 'checked' ? 'checked' : 'half-checked'
        } else {
          checked = checked == 'unchecked' || !checked ? 'unchecked' : 'half-checked'
        }
      })

      record.__check_in_tree__ = checked
      record.__selected__ = checked == 'checked'
    }

    e.stopPropagation()

    const record = e.detail
    var checked = record.__check_in_tree__

    walkTreeCheckedUpdate(record, !checked || checked == 'unchecked' ? 'checked' : 'unchecked')
    const toplevelRecords = this.data.records.filter(
      record => !record.__depth__
    ) /* __depth__ 가 설정되지 않았거나, 0 인 경우만 수집 */

    toplevelRecords.forEach(record => updateCheckedAll(record))

    this.refresh()
  }

  /**
   * Forced internal data to be reflected on the screen
   * Data changing through a normal method is automatically reflected on the screen, so it is a method that does not need to be used in general.
   * Therefore, it will be deprecated.
   * @method
   */
  refresh(forceExpandOrCollapse?: boolean) {
    /*
    - TODO 여기에서 TREE 형태 데이터의 접고, 펴는 것을 재구성한다.
    - 동적으로 서브항목을 fetch 하는 기능은 제공하지 않는다.

    1. 빈배열에서 시작한다.
    2. 기존 배열을 traverseRefresh하면서, collapsed 여부에 따라서, 자식의 포함여부를 결정하고 준비한 배열에 하나씩 추가한다.

    */
    const { records } = this.data
    const toplevelRecords = records.filter(
      record => !record.__depth__
    ) /* __depth__ 가 설정되지 않았거나, 0 인 경우만 수집 */
    this.data = {
      ...this.data,
      records: ([] as GristRecord[]).concat(
        ...toplevelRecords.map(record => this.traverseRefresh(record, forceExpandOrCollapse))
      )
    }
  }

  private traverseRefresh(record: GristRecord, forceExpandOrCollapse?: boolean): GristRecord[] {
    if (forceExpandOrCollapse !== undefined) {
      record.__expanded__ = forceExpandOrCollapse
    }

    const { __expanded__, __children__ = [] } = record

    if (__expanded__ && __children__.length > 0) {
      return [record].concat(...__children__.map(child => this.traverseRefresh(child, forceExpandOrCollapse)))
    } else {
      return [record]
    }
  }
}
