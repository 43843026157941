import { DataGridBody } from '../data-grid-body'
import { DataGridField } from '../data-grid-field'

/**
 * ox-grid-body 의 focus-change handler
 *
 * - handler의 this 는 ox-grid-body임.
 */
export async function dataGridBodyContextMenuHandler(this: DataGridBody, e: Event): Promise<void> {
  e.stopPropagation()

  if (this.editTarget) {
    /* editTarget이 새로 설정되지 않았다면, 이후 기능이 실행된다. */
    return
  }

  /* target should be 'ox-grid-field' */
  var target = (e.target as Element).closest('ox-grid-field') as DataGridField
  var { column, record, rowIndex, columnIndex } = target || {}

  var { column, record } = target || {}

  /* do column contextmenu handler */
  if (column) {
    var { contextmenu } = column.handlers
    contextmenu && contextmenu(this.columns, this.data, column, record, rowIndex, target, e)
  }

  /* do rows contextmenu handler */
  var { contextmenu: rowsContextMenu } = this.config.rows.handlers
  rowsContextMenu && rowsContextMenu(this.columns, this.data, column, record, rowIndex, target, e)
}
