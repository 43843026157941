import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-time')
export class OxGristEditorTime extends OxGristEditor {
  get editorTemplate() {
    return html` <input type="time" .value=${this.value} /> `
  }
}
