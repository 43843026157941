import '@material/web/button/outlined-button.js'

import { css, html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { i18next } from '@operato/i18n'
import { OxPopupList } from '@operato/popup'
import { OxCheckbox } from '@operato/input'

import { ColumnConfig, PersonalGristPreference } from '../types'
import { DataGrist } from '../data-grist'

@customElement('ox-grist-personalizer')
export class OxGristPersonalizer extends LitElement {
  static styles = [
    css`
      md-icon {
        --md-icon-size: 14px;
        width: 16px;
        height: 16px;
        color: var(--ox-grist-p13n-color, var(--md-sys-color-on-primary));
        background-color: var(--ox-grist-p13n-background-color, var(--md-sys-color-primary));
        border-radius: 0px 0px 7px 7px;
        cursor: pointer;
        padding-top: var(--spacing-small);

        &:hover {
          color: var(--ox-grist-p13n-hover-color, var(--md-sys-color-on-primary));
          background-color: var(--ox-grist-p13n-hover-background-color, var(--md-sys-color-surface-tint));
        }
      }
    `
  ]

  @property({ type: Boolean, attribute: true }) debug: boolean = false

  @state() private preference?: PersonalGristPreference

  render() {
    return html`
      <md-icon
        @click=${(e: MouseEvent) => {
          const grist = this.closest('ox-grist') as DataGrist

          const { config, compiledConfig, sorters, pagination, mode } = grist
          const { columns: compiledColumns } = compiledConfig

          const columns = compiledColumns
            .filter(ccolumn => {
              const column = config.columns.find((column: Partial<ColumnConfig>) => column.name == ccolumn.name)
              return column && column.name && column.type !== 'gutter' && !column.hidden && !column.unusable
            })
            .map(column => compiledColumns.find(compiledColumn => compiledColumn.name == column.name)!)

          this.preference = {
            columns: columns.map(column => {
              return {
                name: column.name,
                hidden: column.hidden,
                width: column.width
              }
            }),
            sorters,
            pagination: {
              ...pagination,
              limit: grist.getCurrentLimit()
            },
            mode
          }

          const template = html`
            <div class="personalizer-header" slot="header">
              <div
                style=${`
                  display: flex;
                  align-items: center;
                  margin: 0 0 0 auto;
                  min-height: 1.4em;
                  color: var(--ox-grist-p13n-button-color, var(--md-sys-color-on-primary));
                  background-color: var(--ox-grist-p13n-button-background-color, var(--md-sys-color-primary));
                  border-radius: var(--md-sys-shape-corner-small);
                  padding: 0 var(--spacing-small);
                  cursor: pointer;
                `}
                @click=${async (e: MouseEvent) => {
                  if (grist.personalConfigProvider) {
                    const { mode, columns, sorters, pagination } = this.preference || {}
                    grist.personalConfig = this.preference = await grist.personalConfigProvider.save({
                      mode,
                      columns,
                      sorters,
                      pagination
                    })
                  }
                  popup.close()
                }}
              >
                ${String(i18next.t('button.save'))}
              </div>
              <div
                style=${`
                  display: flex;
                  align-items: center;
                  margin: 0 0 0 var(--spacing-small, 4px);
                  min-height: 1.4em;
                  color: var(--ox-grist-p13n-button-color, var(--md-sys-color-on-primary));
                  background-color: var(--ox-grist-p13n-button-background-color, var(--md-sys-color-primary));
                  border-radius: var(--md-sys-shape-corner-small);
                  padding: 0 var(--spacing-small);
                  cursor: pointer;
                `}
                @click=${async (e: MouseEvent) => {
                  if (grist.personalConfigProvider) {
                    grist.personalConfig = this.preference = {}
                    await grist.personalConfigProvider.reset()
                  }
                  popup.close()
                }}
              >
                ${String(i18next.t('button.delete'))}
              </div>
              <md-icon
                style=${`
                  --md-icon-size: 1.2em;
                  margin-left: var(--spacing-tiny, 2px);
                  cursor: pointer;
                `}
                @click=${async (e: MouseEvent) => popup.close()}
                title=${String(i18next.t('button.close'))}
                >close</md-icon
              >
            </div>

            ${columns.map(
              column => html`
                <ox-checkbox label="checkbox" ?checked=${!column.hidden} value=${column.name} option
                  >${column.header.renderer(column)}<span
                    style="position: absolute; right: 10px; cursor: row-resize;opacity:.5"
                    handle
                    >☰</span
                  ></ox-checkbox
                >
              `
            )}
          `

          const popup = OxPopupList.open({
            template,
            multiple: true,
            sortable: true,
            debug: this.debug,
            attrSelected: 'checked',
            top: e.pageY,
            left: e.pageX,
            styles: css`
              :host {
                width: 240px;
                min-height: 300px;
                max-height: 80%;
                overflow: auto;
              }

              ::slotted(.personalizer-header) {
                --md-icon-size: 1.4em;

                display: flex;
                flex-direction: row;
                align-items: center;
                text-transform: capitalize;
                box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
              }

              ::slotted([option]) {
                position: relative;
                user-select: none;
              }
            `
          })

          popup.onselect = (e: Event) => {
            const selected = (e as CustomEvent).detail

            const pconfig: PersonalGristPreference = grist.personalConfig || {}
            const pcolumns = this.preference?.columns || columns

            pconfig.columns = pcolumns.map(column => {
              return {
                name: column.name,
                hidden: selected.indexOf(column.name) == -1,
                width: column.width
              }
            })

            this.preference = pconfig

            grist.personalConfig = this.preference

            grist.applyUpdatedConfiguration()
          }

          popup.addEventListener('sorted', (e: Event) => {
            const sorted = (e as CustomEvent).detail as HTMLElement[]

            const pconfig: PersonalGristPreference = grist.personalConfig || {}

            pconfig.columns = sorted.map(element => {
              const name = (element as OxCheckbox).value
              return {
                name,
                hidden: !element.hasAttribute('checked'),
                width: columns.find(column => column.name == name)?.width
              }
            })

            this.preference = pconfig

            grist.personalConfig = this.preference

            grist.applyUpdatedConfiguration()
          })
        }}
        >settings</md-icon
      >
    `
  }
}
