import { ColumnConfig, GristRecord } from '../types'
import { LitElement, css, html } from 'lit'
import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { customElement, property } from 'lit/decorators.js'

import { DataGridField } from '../data-grid/data-grid-field'

const STYLE = css``

@customElement('ox-grist-renderer')
export class OxGristRenderer extends LitElement {
  static styles = STYLE

  @property({ type: Object }) value?: any
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Number }) rowIndex?: number
  @property({ type: Object }) field?: DataGridField
  @property({ type: Number }) row?: number

  render() {
    return this.rendererTemplate
  }

  async firstUpdated() {
    const align = this.column.record.align
    if (align) {
      this.style.textAlign = align
    }
  }

  get rendererTemplate() {
    return html``
  }
}
