import '@material/web/icon/icon.js'
import './data-card-gutter.js'

import { css, html, LitElement } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { OxPopup } from '@operato/popup'

import { ColumnConfig, GristRecord } from '../types.js'

@customElement('ox-card-gutter-menu')
class DataCardGutterMenu extends LitElement {
  static styles = css`
    :host {
      position: relative;
    }

    ::slotted(ox-popup) {
      padding: var(--spacing-small);
    }

    md-icon {
      flex: 1;
      margin: var(--spacing-none);
      border: var(--data-card-item-btn-border);
      border-radius: var(--data-card-item-btn-border-radius);
      padding: var(--data-card-item-btn-padding);
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      background-color: transparent;
      color: var(--grid-record-color);
    }

    md-icon:hover {
      cursor: pointer;
      background-color: var(--md-sys-color-primary);
      color: var(--md-sys-color-on-primary);
    }
  `

  @property({ type: Object }) gutters!: ColumnConfig[]
  @property({ type: Number }) rowIndex!: number
  @property({ type: Object }) record!: GristRecord
  @property() clickHandler!: EventListener
  @property() dblclickHandler!: EventListener

  @query('md-icon') icon!: Element

  render() {
    return html`
      <md-icon @click=${(e: Event) => this.open(e)}>more_horiz</md-icon>
      <slot></slot>
    `
  }

  menuRender() {
    return html`
      ${this.gutters.map(
        gutter => html`
          <ox-card-gutter
            .rowIndex=${this.rowIndex}
            .column=${gutter}
            .record=${this.record}
            .value=${this.record[gutter.name]}
            menu
            alive-on-select
            @click=${(e: Event) => {
              this.clickHandler(e)
              // TODO close popup
            }}
            @dblclick=${(e: Event) => {
              this.dblclickHandler(e)
              // TODO close popup
            }}
          ></ox-card-gutter>
        `
      )}
    `
  }

  open(e: Event) {
    e.stopPropagation()

    const bottom = (this.icon as HTMLElement).offsetHeight
    const left = -4
    const parent = this

    OxPopup.open({
      template: this.menuRender(),
      bottom,
      left,
      parent
    })
  }
}
