import { DataCard } from '../data-card/data-card'
import { DataGrid } from '../data-grid/data-grid'
import { DataList } from '../data-list/data-list'
import { ColumnConfig, ColumnWidthCallback, FieldRenderer, GristEventHandler } from '../types'

export class GutterSequence {
  static instance(config: Partial<ColumnConfig> = { type: 'gutter', name: '__seq__' }) {
    return {
      type: 'gutter',
      name: '__seq__',
      getterType: 'sequence',
      width: function (this: DataGrid | DataList | DataCard, column): string {
        if (this.data) {
          var { limit = 0, page = 1, records = [] } = this.data || {}
          var lastIndex = (page - 1) * limit + records.length
        } else {
          var lastIndex = 100
        }

        /* 1ch의 폭은 해당 폰트 0의 폭을 의미함. Arial이 약 8px이며, 정수로 떨어지지 않을 수 있으므로, 다소 여유있게 10px을 기준으로 함. */
        return `${Math.max(String(lastIndex).length * 10, 20)}px`
      } as ColumnWidthCallback,
      resizable: false,
      sortable: false,
      header: '#',
      record: {
        renderer: function (value, column, record, rowIndex, field) {
          return value
        } as FieldRenderer,
        align: 'right'
      },
      forGrid: true,
      forList: false,
      forCard: false,
      handlers: {
        dblclick: ((columns, data, column, record, rowIndex, target) => {
          target.dispatchEvent(
            new CustomEvent('set-select-block', {
              bubbles: true,
              composed: true,
              detail: {
                startRow: rowIndex,
                startColumn: 0,
                endRow: rowIndex,
                endColumn: -1
              }
            })
          )
        }) as GristEventHandler
      },
      ...config
    }
  }
}
