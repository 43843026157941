import { html } from 'lit-html'

import { FilterConfigObject, FilterSelectRenderer } from '../types'

export const FilterInput: FilterSelectRenderer = (column, value, owner) => {
  const filter = column.filter as FilterConfigObject
  const type = filter?.type || column.type

  return html`
    <input
      type=${type as any}
      name=${column.name}
      .value=${value == null ? '' : value}
      @change=${(e: CustomEvent) => {
        const input = e.target as HTMLInputElement
        input.dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: input.value
            }
          })
        )
      }}
    />
  `
}
