import { ColumnConfig, GristRecord } from '../types'
import { LitElement, PropertyValues, TemplateResult, css, html } from 'lit'
import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { customElement, property } from 'lit/decorators.js'

const DEFAULT_TEXT_ALIGN = 'left'

@customElement('ox-list-gutter')
export class DataListGutter extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        align-items: center;
        justify-content: var(--data-list-gutter-justify-content, flex-start);
        position: relative;

        white-space: nowrap;
        overflow: hidden;
        background-color: transparent;

        font-size: var(--grid-record-wide-fontsize);
        text-overflow: ellipsis;
        color: var(--grid-record-color);

        padding-right: var(--spacing-medium);
      }

      :host-context(.expand)::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 150%;
        height: 220%;
      }

      * {
        flex: 1;
        border: 0;
        font-size: var(--md-sys-typescale-label-medium-size, 0.8rem);
        line-height: var(--md-sys-typescale-label-medium-size, 0.8rem);

        background-color: transparent;
        padding: var(--spacing-small);
      }

      *[center] {
        flex: none;
        margin: var(--spacing-none) auto;
      }

      button {
        display: flex;
        gap: var(--spacing-small);
        border: 0;
        align-items: center;
      }

      button md-icon {
        border: 0;
        padding: 0;
        background-color: unset;
      }
    `
  ]

  @property({ attribute: true }) align?: string
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex: number = -1
  @property({ type: Object }) value?: object

  render(): TemplateResult {
    if (!this.column) {
      return html``
    }

    var { value, column, record, rowIndex } = this
    var { renderer } = column.record

    /* renderer가 html template이 아니고 단순한 값인 경우가 있으므로, html 템플릿으로 감싸준다. */
    return html` ${renderer(value, column, record, rowIndex, this)} `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('column')) {
      var align = this.column.record.align || DEFAULT_TEXT_ALIGN
      if (align != DEFAULT_TEXT_ALIGN) {
        let justify = 'center'
        switch (align) {
          case 'right':
            justify = 'flex-end'
            break
        }
        this.style.setProperty('--data-list-gutter-justify-content', justify)
      }

      const { danger } = this.column
      if (danger) {
        this.setAttribute('danger', '')
      } else {
        this.removeAttribute('danger')
      }
    }
  }
}
