import { html } from 'lit'
import { OxPopupMenu } from '@operato/popup'

import { DataGridField } from '../data-grid/data-grid-field'
import { ColumnConfig, GristData, GristRecord } from '../types'

function dispatchEvent(field: DataGridField, event: string) {
  field.dispatchEvent(
    new CustomEvent(event, {
      bubbles: true,
      composed: true,
      detail: field.record
    })
  )
}

/*
 * handler들은 ox-grid-field 로부터 호출되는 것을 전제로 하며,
 * 전반적인 처리를 위해서, columns 및 data 정보를 포함해서 제공할 수 있어야 한다.
 */
export const ContextMenuTreeMutation = function (
  columns: ColumnConfig[],
  data: GristData,
  column: ColumnConfig,
  record: GristRecord,
  rowIndex: number,
  field: DataGridField,
  event: Event
): void {
  const e = event as PointerEvent
  if (!('pageX' in e && 'pageY' in e)) {
    return
  }

  e.preventDefault()

  OxPopupMenu.open({
    template: html`
      <ox-popup-menuitem
        label="add sibling node"
        @click=${() => {
          dispatchEvent(field, 'add-sibling-node')
        }}
      >
        <md-icon slot="icon">add</md-icon>
      </ox-popup-menuitem>

      <ox-popup-menuitem
        label="add child node"
        @click=${() => {
          dispatchEvent(field, 'add-child-node')
        }}
      >
        <md-icon slot="icon">playlist_add</md-icon>
      </ox-popup-menuitem>

      <div separator></div>

      <ox-popup-menuitem
        label="collapse all"
        @click=${() => {
          dispatchEvent(field, 'collapse-all')
        }}
      >
        <md-icon slot="icon">unfold_less</md-icon>
      </ox-popup-menuitem>

      <ox-popup-menuitem
        label="expand all"
        @click=${() => {
          dispatchEvent(field, 'expand-all')
        }}
      >
        <md-icon slot="icon">unfold_more</md-icon>
      </ox-popup-menuitem>
    `,
    top: e.pageY,
    left: e.pageX
  })
}
