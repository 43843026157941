import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-date')
export class OxGristEditorDate extends OxGristEditor {
  get editorTemplate() {
    return html` <input type="date" .value=${this.value} max="9999-12-31" /> `
  }
}
