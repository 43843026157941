import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'
import { parseToNumberOrNull } from '@operato/utils'

@customElement('ox-grist-editor-number')
export class OxGristEditorNumber extends OxGristEditor {
  get inlineEditable() {
    return true
  }

  formatFromEditor(value: any) {
    const parsed = parseToNumberOrNull(value) ?? null

    if (parsed !== null && this.column.type == 'integer') {
      return Math.floor(parsed)
    }

    return parsed
  }

  get editorTemplate() {
    var { min = -Infinity, max = Infinity } = this.column.record.options || {}

    return html` <input type="number" .value=${this.value} .min=${Number(min)} .max=${Number(max)} /> `
  }
}
