/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-stack')
export class OxInputStack extends OxFormField {
  static styles = [
    css`
      :host {
        display: block;
      }

      #add-floor {
        width: 100%;
        height: 20px;
        background-color: blue;
        color: white;
      }

      div {
        background-color: blue;
        width: calc(100% - 40px);
        width: -webkit-calc(100% - 40px);
        min-height: 20px;
      }

      div[active] {
        background-color: red;
      }

      div button {
        position: absolute;
        right: 10px;
        width: 30px;
        min-height: 20px;
      }
    `
  ]

  /**
   * `stack`은 stack에 의해 만들어진 층의 배열을 유지한다.
   */
  @property({ type: Array }) stack: { name: string; active?: boolean }[] = []

  /**
   * `activeIndex`은 현재 active된 층의 인덱스를 유지한다.
   */
  // @property({ type: Number }) activeIndex: number = 0

  render() {
    const stack = [...this.stack].reverse()
    const length = stack.length

    return html`
      <button id="add-floor" @click=${(e: Event) => this._onClickAddFloor(e)} ?disabled=${this.disabled}>+</button>

      ${stack.map(
        (item, index) => html`
          <div
            floor
            ?active=${item.active}
            @click=${(e: Event) => !this.disabled && this._onClickToActive(e)}
            idx=${length - index - 1}
          >
            ${item.name}
            <button @click=${(e: Event) => this._onClickRemoveFloor(e)} ?disabled=${this.disabled}>-</button>
          </div>
        `
      )}
    `
  }

  _notifyChange() {
    this.value = this.stack

    this.dispatchEvent(
      new CustomEvent('change', {
        detail: this.value,
        bubbles: true,
        composed: true
      })
    )
  }

  _onClickAddFloor(e: Event) {
    this.stack.push({ name: String(this.stack.length + 1) })
    this.requestUpdate()

    this._notifyChange()
  }

  _onClickToActive(e: Event) {
    const floor = (e.target as HTMLElement).closest('[floor]')

    if (!floor) {
      return
    }

    e.stopPropagation()

    const activeIndex = Number(floor.getAttribute('idx'))
    this.stack = this.stack.map((floor, index) => {
      return activeIndex === index
        ? {
            name: floor.name,
            active: true
          }
        : { name: floor.name }
    })

    this._notifyChange()
  }

  _onClickRemoveFloor(e: Event) {
    const floor = (e.target as HTMLElement).closest('[floor]')

    if (!floor) {
      return
    }

    e.stopPropagation()

    const idx = Number(floor.getAttribute('idx'))

    this.stack.splice(idx, 1)

    this.requestUpdate()

    this._notifyChange()
  }
}
