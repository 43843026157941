import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { until } from 'lit/directives/until.js'

import { OxGristEditor } from './ox-grist-editor.js'
import { SelectOption, SelectOptionObject } from '../types.js'

function buildOptions(options: SelectOption[], value: any) {
  const selectOptionObjects = options.map(option => {
    switch (typeof option) {
      case 'string':
        return {
          display: option,
          value: option
        }
      case 'object':
        return {
          display: option.display || option.name,
          value: option.value
        }
      default:
        return option
    }
  }) as SelectOptionObject[]

  return html`
    <select>
      ${selectOptionObjects.map(
        (option: any) => html`
          <option ?selected=${option.value == value} value=${option.value}>${option.display}</option>
        `
      )}
    </select>
  `
}

@customElement('ox-grist-editor-select')
export class OxGristEditorSelect extends OxGristEditor {
  get editorTemplate() {
    var rowOptionField = this.record[this.column.record.rowOptionField || '']
    var { options = [] } = rowOptionField ? rowOptionField : this.column.record

    if (typeof options == 'function') {
      options = options.call(null, this.value, this.column, this.record, this.rowIndex, this.field)

      if (options instanceof Promise) {
        return html`${until(options.then(options => buildOptions(options, this.value)))}`
      } else {
        return buildOptions((options || []) as SelectOption[], this.value)
      }
    } else {
      return buildOptions((options || []) as SelectOption[], this.value)
    }
  }
}
