import { css, html, LitElement, PropertyValues, TemplateResult } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { ColumnConfig, GristRecord } from '../types'

@customElement('ox-card-gutter')
export class DataCardGutter extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        background-color: transparent;
        align-content: center;
        align-items: center;
        text-overflow: ellipsis;
      }

      :host([row-selector]) {
        margin-right: auto;
      }

      * {
        border: 0;
        font-size: var(--md-sys-typescale-label-medium-size, 0.8rem);
        line-height: var(--md-sys-typescale-label-medium-size, 0.8rem);

        color: var(--md-sys-color-on-primary);
        background-color: var(--md-sys-color-primary);
        border-radius: var(--data-card-item-btn-border-radius, var(--md-sys-shape-corner-tiny));

        padding: var(--spacing-small);
      }

      *:hover {
        cursor: pointer;
        color: var(--md-sys-color-on-secondary);
        background-color: var(--md-sys-color-secondary);
      }

      :host([danger]) button {
        color: var(--md-sys-color-on-error);
        background-color: var(--md-sys-color-error);
        border-color: var(--md-sys-color-error);
      }

      :host([danger]) button:hover {
        color: var(--md-sys-color-error);
        background-color: var(--md-sys-color-on-secondary);
        border-color: var(--md-sys-color-on-secondary);
      }

      button {
        display: flex;
        gap: var(--spacing-small);
        border: 0;
        align-items: center;
      }

      button md-icon {
        border: 0;
        padding: 0;
        background-color: unset;
      }
    `
  ]

  @property({ attribute: true }) align?: string
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex: number = -1
  @property({ type: Object }) value?: object

  render(): TemplateResult {
    if (!this.column) {
      return html``
    }

    var { value, column, record, rowIndex } = this
    var { renderer } = column.record

    /* renderer가 html template이 아니고 단순한 값인 경우가 있으므로, html 템플릿으로 감싸준다. */
    return html` ${renderer(value, column, record, rowIndex, this)} `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('column')) {
      const { gutterName, danger } = this.column

      if (gutterName == 'row-selector') {
        this.setAttribute('row-selector', '')
      } else {
        this.removeAttribute('row-selector')
      }

      if (danger) {
        this.setAttribute('danger', '')
      } else {
        this.removeAttribute('danger')
      }
    }
  }
}
