import '@operato/input/ox-checkbox.js'

import { html } from 'lit-html'

import { FilterConfigObject, FilterSelectRenderer } from '../types.js'

export const FilterCheckbox: FilterSelectRenderer = (column, value, owner) => {
  const { name, header, label, filter } = column

  const { label: filterLabel, operator } = filter! as FilterConfigObject

  const labelText =
    filterLabel !== undefined
      ? filterLabel
      : typeof label === 'object' && label.renderer
        ? label.renderer(column)
        : header.renderer(column) || name

  // 텍스트 형태로 받았을시 의도가 boolean이면 boolean 타입으로 변환
  if (value === 'false') {
    value = false;
  } else if (value === 'true') {
    value = true;
  }

  return html`
    <ox-checkbox
      name=${column.name}
      ?checked=${value}
      indeterminatable
      ?indeterminate=${value === undefined}
      left-label
      @change=${(e: CustomEvent) => {
        ;(e.target as HTMLElement).dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name,
              operator,
              value: (e.target as Element & { checked: boolean | undefined }).checked
            }
          })
        )
      }}
      ><label filter-title><span>${labelText}</span></label></ox-checkbox
    >
  `
}
