import '@material/web/icon/icon.js'

import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ColumnConfig, FieldRenderer, GristEventHandler } from '../types'

@customElement('ox-gutter-dirty-element')
class GutterDirtyElement extends LitElement {
  static styles = css`
    :host {
      display: flex;
      margin: auto;
    }

    md-icon {
      width: var(--grid-record-dirty-icon-size);
      height: var(--grid-record-dirty-icon-size);
      border-radius: 50%;
      opacity: 0.8;
    }

    [add] {
      background-color: var(--md-sys-color-on-primary-container);
    }

    [remove] {
      background-color: var(--md-sys-color-error);
    }

    [done] {
      background-color: var(--status-info-color);
    }
  `

  @property({ type: String }) value?: string

  render() {
    switch (this.value) {
      case '+':
        return html` <md-icon style=${INLINESTYLE} center add>add</md-icon> `
      case '-':
        return html` <md-icon style=${INLINESTYLE} center remove>remove</md-icon> `
      case 'M':
        return html` <md-icon style=${INLINESTYLE} center done>done</md-icon> `
      default:
        return ''
    }
  }
}

const INLINESTYLE =
  'font-size: var(--grid-record-fontsize); vertical-align: middle; color: var(--grid-record-dirty-color)'

export class GutterDirty {
  static instance(config: Partial<ColumnConfig> = { type: 'gutter', name: '__dirty__' }) {
    return Object.assign(
      {},
      {
        type: 'gutter',
        name: '__dirty__',
        gutterName: 'dirty',
        width: 30,
        resizable: false,
        sortable: false,
        header: '',
        record: {
          align: 'center',
          renderer: function (value, column, record, rowIndex, field) {
            return value ? html` <ox-gutter-dirty-element value=${value}></ox-gutter-dirty-element> ` : html``
          } as FieldRenderer
        },
        forGrid: true,
        forList: false,
        forCard: false,
        handlers: {
          dblclick: ((columns, data, column, record, rowIndex, target) => {
            target.dispatchEvent(
              new CustomEvent('set-select-block', {
                bubbles: true,
                composed: true,
                detail: {
                  startRow: rowIndex,
                  startColumn: 0,
                  endRow: rowIndex,
                  endColumn: -1
                }
              })
            )
          }) as GristEventHandler
        }
      },
      config
    )
  }
}
