import { nothing } from 'lit'
import { directive, AsyncDirective } from 'lit/async-directive.js'
import { hasPrivilege } from '../profiled'

class PrivilegedDirective extends AsyncDirective {
  render(
    privilege: {
      privilege?: string
      category?: string
      domainOwnerGranted?: boolean
      superUserGranted?: boolean
    },
    trueResult: any,
    falseResult: any = nothing
  ) {
    this.setValue(nothing)

    hasPrivilege(privilege).then(result => {
      if (result) {
        this.setValue(trueResult)
      } else {
        this.setValue(falseResult)
      }
    })
  }
}

export const privileged = directive(PrivilegedDirective)
