import '@operato/input/ox-checkbox.js'
import '@operato/input/ox-select.js'
import '@operato/popup/ox-popup-list.js'
import '@operato/input/ox-input-search.js'

import { css, html, LitElement, PropertyValues, TemplateResult, nothing } from 'lit'
import { customElement, property, queryAsync, state } from 'lit/decorators.js'
import { styles as MDTypeScaleStyles } from '@material/web/typography/md-typescale-styles'

import { PagePreferenceProvider } from '@operato/p13n'
import { getDefaultValue } from '@operato/time-calculator'

import { FilterConfigObject, FilterPreference } from '../types.js'
import { DataGrist } from '../data-grist'
import { ColumnConfig, FilterOperator, FilterValue, GristConfig, PersonalGristPreference } from '../types'
import { FilterStyles } from './filter-styles'
import { getFilterRenderer } from './registry'

export type QueryFilterRangeValue = [from: number, to: number]

export type QueryFilter = {
  name: string
  operator: FilterOperator
  value: any
}

@customElement('ox-filters-form')
export class OxFiltersForm extends LitElement {
  static styles = [
    MDTypeScaleStyles,
    FilterStyles,
    css`
      :host {
        display: flex;
        padding: var(--spacing-small);
      }

      form {
        flex: 1;

        display: flex;
        flex-flow: row wrap;
        gap: var(--ox-filters-form-gap);
      }

      form > * {
        display: flex;
        align-items: center;
        gap: var(--input-intra-gap, 7px);
      }

      label span {
        display: block;
      }

      @media only screen and (max-width: 460px) {
        form {
          flex-direction: column;
          flex-flow: column;
        }
      }
    `
  ]

  @property({ type: Array }) value: FilterValue[] = []
  @property({ type: Boolean, attribute: 'without-search' }) withoutSearch: boolean = false
  @property({ type: Boolean, attribute: 'autofocus' }) autofocus: boolean = true
  @property({ type: Boolean, attribute: 'empty', reflect: true }) empty: boolean = true

  @state() personalConfigProvider?: PagePreferenceProvider
  @state() personalConfig?: PersonalGristPreference
  @state() personalFilters?: FilterPreference[]

  @state() config!: GristConfig
  @state() filterColumns: ColumnConfig[] = []
  @state() searchColumns: ColumnConfig[] = []

  @queryAsync('form') form!: HTMLFormElement

  private autoUpdateTargetsOnChange: { [name: string]: string[] } = {}
  private objectValue?: object

  connectedCallback(): void {
    super.connectedCallback()

    const grist = this.closest('ox-grist') as DataGrist

    if (grist) {
      this.config = grist.compiledConfig
      this.personalConfigProvider = grist.personalConfigProvider

      grist.addEventListener('config-change', (e: Event) => {
        this.config = (e as CustomEvent).detail
      })

      grist.addEventListener('fetch-params-change', (e: Event) => {
        const { filters, from } = (e as CustomEvent).detail || {}
        if (from === 'filters-form') {
          return
        }

        this.value = filters
      })

      this.renderRoot.addEventListener('change', async (e: Event) => {
        const { target, detail: value } = e as CustomEvent
        const name = (target as HTMLInputElement).name
        const { filter } = this.filterColumns.find(filter => filter.name == name) || {}

        if (this.autoUpdateTargetsOnChange[name]) {
          /* 일단은 심플하게, boundTo로 연결된 필터값이 바뀌면, 폼 전체를 update하도록 함. */
          ;(this.autoUpdateTargetsOnChange[name] || []).forEach(name => {
            const target = this.renderRoot.querySelector(`[name='${name}']`)
            if (target) {
              ;(target as HTMLInputElement).value = ''
            }
          })

          await this.updateObjectValues()
          this.requestUpdate()
        }

        const onchange = typeof filter == 'object' ? filter.onchange : null
        const keepGoing = onchange ? await onchange.call(null, value ?? (target as HTMLInputElement).value, this) : true

        keepGoing &&
          this.dispatchEvent(
            new CustomEvent('fetch-params-change', {
              bubbles: true,
              composed: true,
              detail: {
                filters: await this.getQueryFilters(),
                from: 'filters-form'
              }
            })
          )
      })
    }
  }

  buildDefaultValue(operator: FilterOperator, defaultValue: any) {
    if (defaultValue === undefined) {
      return
    }
    if (operator == 'between') {
      return (defaultValue as Array<any>).map(v => getDefaultValue(v, this))
    } else {
      return getDefaultValue(defaultValue, this)
    }
  }

  async updated(changes: PropertyValues<this>) {
    if (changes.has('personalConfigProvider') && this.personalConfigProvider) {
      this.personalConfig = await this.personalConfigProvider.load()
    } else if (changes.has('config') || changes.has('personalConfig')) {
      this.applyUpdatedConfiguration()
    }
  }

  render(): TemplateResult {
    const searchValue =
      (this.value?.find(filter => filter.operator === 'search')?.value as string)?.match(/^\%(.*)\%$/)?.[1] || ''

    return this.empty
      ? html``
      : html`
          <form
            class="md-typescale-body-medium-prominent"
            @submit=${(e: Event) => {
              e.stopPropagation()
              e.preventDefault()

              const grist = this.closest('ox-grist') as DataGrist

              grist && grist.fetch()
            }}
          >
            ${this.filterColumns
              .filter(column => !(column.filter as FilterConfigObject).hidden)
              .map((column: ColumnConfig) => {
                const { name, header, label, filter } = column

                const type = (filter as FilterConfigObject).type

                if (type == 'search') {
                  return html`
                    <ox-input-search name="search" .value=${searchValue} ?autofocus=${this.autofocus}></ox-input-search>
                  `
                }

                const operator = (filter as FilterConfigObject).operator
                const filterLabel = (filter as FilterConfigObject).label

                const labelText =
                  filterLabel !== undefined
                    ? filterLabel
                    : typeof label === 'object' && label.renderer
                      ? label.renderer(column)
                      : header.renderer(column) || name

                const idx = operator === 'between' ? 1 : 0
                const renderer = getFilterRenderer(
                  operator === 'like' || operator === 'i_like' || operator === 'i_nlike' || operator === 'nlike'
                    ? 'text'
                    : type
                )[idx]
                const value =
                  this.value?.find(filter => filter.name == name)?.value ??
                  this.buildDefaultValue(operator!, (filter as FilterConfigObject)?.value)

                if (!renderer) {
                  return html``
                }

                return type === 'boolean' || type === 'checkbox'
                  ? renderer(column, value, this)
                  : type !== 'select' && labelText
                    ? html`<label filter-title ?between=${operator === 'between'}
                        ><span>${labelText}</span> ${renderer(column, value, this)}
                      </label> `
                    : type !== 'select' && !labelText
                      ? renderer(column, value, this)
                      : operator === 'in'
                        ? html`
                            <ox-select
                              name=${name}
                              placeholder=${labelText}
                              .value=${value}
                              @change=${(e: CustomEvent) =>
                                e.target?.dispatchEvent(
                                  new CustomEvent('filter-change', {
                                    detail: {
                                      name,
                                      operator,
                                      value: e.detail
                                    }
                                  })
                                )}
                            >
                              <ox-popup-list multiple attr-selected="checked" with-search>
                                ${renderer(column, value, this)}
                              </ox-popup-list>
                            </ox-select>
                          `
                        : html`
                            <ox-select
                              name=${name}
                              placeholder=${labelText}
                              .value=${value}
                              @change=${(e: CustomEvent) =>
                                e.target?.dispatchEvent(
                                  new CustomEvent('filter-change', {
                                    detail: {
                                      name,
                                      operator,
                                      value: e.detail
                                    }
                                  })
                                )}
                            >
                              <ox-popup-list with-search> ${renderer(column, value, this)} </ox-popup-list>
                            </ox-select>
                          `
              })}
          </form>
          <slot name="setting"></slot>
        `
  }

  applyUpdatedConfiguration() {
    if (!this.config) {
      return
    }

    const filters = this.config.columns.filter(columnConfig => !!columnConfig.filter)

    this.filterColumns = filters.filter((columnConfig: ColumnConfig) => {
      const filter = columnConfig.filter as FilterConfigObject
      return filter!.operator !== 'search'
    })
    this.searchColumns = filters.filter(columnConfig => {
      const filter = columnConfig.filter as FilterConfigObject
      return filter!.operator === 'search'
    })

    if (this.searchColumns.length > 0 && !this.withoutSearch) {
      this.filterColumns.unshift({ name: 'search', filter: { type: 'search' } } as any)
    }

    if (!this.personalConfig) {
      this.personalFilters = this.filterColumns.map(column => {
        return { name: column.name }
      })
    } else {
      const { filters: personalFilters = [] } = this.personalConfig

      if (personalFilters) {
        const xfilters = this.filterColumns.map(column => {
          return personalFilters.find(pFilter => pFilter.name == column.name) || { name: column.name }
        })

        function reorderList(a: FilterPreference[], b: FilterPreference[]): FilterPreference[] {
          // 결과 배열 초기화, a 배열 길이만큼 undefined로 채움
          const result = new Array(a.length)

          // b 배열에 없는 아이템은 원래 위치로 채움
          a.forEach((item, index) => {
            if (!item.name || !b.find(bi => bi.name == item.name)) {
              result[index] = item
            }
          })

          b.forEach(item => {
            const ai = a.find(ai => ai.name == item.name)
            if (ai) {
              result[result.findIndex(slot => slot === undefined)] = ai
            }
          })

          return result
        }

        // 배열 재정렬 실행
        this.personalFilters = reorderList(xfilters as any, personalFilters as any) as FilterPreference[]

        this.filterColumns = this.personalFilters
          .map(filter => {
            const column = this.filterColumns.find(column => column.name == filter.name)
            if (column?.filter) {
              ;(column.filter as FilterConfigObject)!.hidden = filter.hidden
            }
            return column
          })
          .filter(Boolean) as ColumnConfig[]
      }
    }

    const grist = this.closest('ox-grist') as DataGrist

    this.value = (grist?.filters || []).map(filter => {
      return {
        ...filter,
        value: this.buildDefaultValue(filter!.operator, filter!.value)
      }
    })

    this.empty = (this.searchColumns.length === 0 || this.withoutSearch) && this.filterColumns.length === 0

    this.autoUpdateTargetsOnChange = {}
    this.filterColumns
      ?.filter(({ filter }) => {
        return typeof filter == 'object' && filter.boundTo && filter.boundTo.length > 0
      })
      .map(({ name, filter }) => {
        const boundTo = (filter as FilterConfigObject).boundTo

        boundTo!.forEach(to => {
          const origin = this.autoUpdateTargetsOnChange[to] || []
          if (name && !origin.includes(name)) {
            this.autoUpdateTargetsOnChange[to] = [...origin, name]
          }
        })
      })
  }

  async getQueryFilters(): Promise<QueryFilter[]> {
    const form = await this.form
    if (!form) return []

    const formData = new FormData(form)
    const search: string | undefined = formData.get('search')?.toString()

    var filters = this.filterColumns
      .filter(column => column.name !== 'search' && !(column.filter as FilterConfigObject)!.hidden)
      .map((column: ColumnConfig) => {
        const { name, type, filter } = column
        const operator = (filter as FilterConfigObject).operator

        var value = formData.getAll(name)
        if (value.length == 0) {
          return
        }

        if (-1 === value.findIndex(v => v !== '')) {
          return
        }

        const filterValue = value.map(v => {
          const value = v.toString()

          /* TODO registry에서 타입별로 parsing 방법을 지정할 수 있도록 해야한다. */
          switch (type) {
            case 'integer':
            case 'float':
            case 'number':
            case 'progress':
            case 'checkbox':
            case 'boolean':
              return !value ? undefined : JSON.parse(value)
            default:
              return value
          }
        })

        return {
          name,
          operator,
          value: filterValue.length === 1 ? filterValue[0] : filterValue
        }
      })
      .filter(result => result !== undefined) as QueryFilter[]

    if (search) {
      filters = filters.concat(
        this.searchColumns.map((column: ColumnConfig) => {
          const { name } = column

          return {
            name,
            operator: 'search',
            value: `%${search}%`
          }
        })
      )
    }

    return filters
  }

  public setInputValue(name: string, value: any) {
    const input = this.renderRoot.querySelector(`form [name="${name}"]`) as HTMLInputElement
    if (input) {
      input.value = value
      input.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

  public getInputValue(name: string): any {
    const input = this.renderRoot.querySelector(`form [name="${name}"]`) as HTMLInputElement
    return input?.value
  }

  private async updateObjectValues() {
    const form = await this.form
    if (!form) return []

    const formData = new FormData(form)

    const object = {} as any
    formData.forEach((value, key) => {
      const prev = object[key]

      if (key in object) {
        object[key] = prev instanceof Array ? [...prev, value] : [prev, value]
      } else {
        object[key] = value
      }
    })

    this.objectValue = object
  }

  public getFormObjectValue() {
    return this.objectValue
  }

  reset() {
    this.form
      .then((form: HTMLFormElement) => {
        form.reset()
      })
      .catch((error: any) => {
        console.error('Error resetting the form:', error)
      })
  }
}
