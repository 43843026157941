import { DataGridField } from '../data-grid/data-grid-field'
import { ColumnConfig, GristData, GristRecord } from '../types'

/*
 * handler들은 ox-grid-field 로부터 호출되는 것을 전제로 하며,
 * 전반적인 처리를 위해서, columns 및 data 정보를 포함해서 제공할 수 있어야 한다.
 */

export const SelectRowToggle = function (
  columns: ColumnConfig[],
  data: GristData,
  column: ColumnConfig,
  record: GristRecord,
  rowIndex: number,
  field: DataGridField
): void {
  field?.dispatchEvent(
    new CustomEvent('select-record-change', {
      bubbles: true,
      composed: true,
      detail: record['__selected__']
        ? {
            removed: [record]
          }
        : {
            added: [record]
          }
    })
  )
}
