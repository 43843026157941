/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@material/web/icon/icon.js'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu'
import '@polymer/paper-item/paper-item'
import '@operato/i18n/ox-i18n.js'
import './ox-input-color.js'

import { css, html } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

type BorderStyle =
  | 'solid'
  | 'round-dot'
  | 'square-dot'
  | 'dash'
  | 'dash-dot'
  | 'long-dash'
  | 'long-dash-dot'
  | 'long-dash-dot-dot'

/**
 * 테이블 셀의 좌,우,상,하 경계선의 스타일을 편집하는 컴포넌트이다.
 * Example:
 * <ox-input-table value=${border}>
 * </ox-input-table>
 */

@customElement('ox-input-table')
class OxInputTable extends OxFormField {
  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        fieldset {
          border: none;
          border-bottom: 1px solid #cfd8dc;
          color: var(--md-sys-color-on-primary-container);
          font-size: 12px;
          padding: 0 0 10px 0;
          margin: 0 0 10px 0;
        }

        fieldset legend {
          padding: 5px 0 0 5px;
          font-size: 11px;
          color: #e46c2e;
          font-weight: bold;
          text-transform: capitalize;
        }

        .icon-only-label {
          background: url(/assets/images/icon-properties-label.png) no-repeat;
          width: 30px;
          height: 24px;
        }

        .property-grid {
          display: grid;
          grid-template-columns: repeat(10, 1fr);
          grid-gap: 5px;
          grid-auto-rows: minmax(24px, auto);
          margin: 10px 0 0 0;
        }

        .property-grid > * {
          line-height: 1.5;
        }

        #border-set > md-icon {
          grid-column: span 2;
          margin: 0 0 0 8px;
          width: 32px;
          height: 32px;
        }

        .property-grid > label {
          grid-column: span 3;
          text-align: right;
          text-transform: capitalize;
        }

        .property-grid > label.icon-only-label {
          grid-column: span 1;
        }

        .property-grid > ox-input-color,
        .property-grid > input[type='number'] {
          grid-column: span 4;
          padding: 0;
          margin: 0;
        }

        .property-grid > paper-dropdown-menu {
          grid-column: span 7;
          padding: 0;
          margin: 0;
        }

        [table-event] {
          position: relative;
          background: url('/assets/images/icon-properties-table.png') no-repeat;
          grid-column: span 2;
          min-height: 65px;
        }

        [table-event] span {
          position: absolute;
          bottom: 0;
          font-size: 0.9em;
          line-height: 1.2;
          text-transform: capitalize;
          text-align: center;
          vertical-align: bottom;
        }

        #merge-cells {
          background-position: 50% 3px;
        }

        #split-cells {
          background-position: 50% -97px;
        }

        #delete-row {
          background-position: 50% -197px;
        }

        #delete-column {
          background-position: 50% -297px;
        }

        #insert-above {
          background-position: 50% -397px;
        }

        #insert-below {
          background-position: 50% -497px;
        }

        #insert-left {
          background-position: 50% -597px;
        }

        #insert-right {
          background-position: 50% -697px;
        }

        #distribute-horizontal {
          background-position: 50% -797px;
        }

        #distribute-vertical {
          background-position: 50% -897px;
        }

        .line-type paper-item {
          background: no-repeat url(/assets/images/icon-properties-line-type.png);
          width: 80px;
          min-height: 25px;
        }
        .line-type paper-item.solid {
          background-position: 50% 12px;
        }
        .line-type paper-item.round-dot {
          background-position: 50% -38px;
        }
        .line-type paper-item.square-dot {
          background-position: 50% -88px;
        }
        .line-type paper-item.dash {
          background-position: 50% -138px;
        }
        .line-type paper-item.dash-dot {
          background-position: 50% -188px;
        }
        .line-type paper-item.long-dash {
          background-position: 50% -238px;
        }
        .line-type paper-item.long-dash-dot {
          background-position: 50% -288px;
        }
        .line-type paper-item.long-dash-dot-dot {
          background-position: 50% -338px;
        }
      `
    ]
  }

  @property({ type: Number }) borderWidth: number = 1
  @property({ type: String }) borderColor: string = 'black'
  @property({ type: String }) borderStyle: BorderStyle = 'solid'
  @property({ type: Object }) value: any

  @query('#border-fieldset') borderFieldSet!: HTMLElement

  firstUpdated() {
    this.borderFieldSet.addEventListener('change', this._onClickType.bind(this))
  }

  render() {
    return html`
      <fieldset id="border-fieldset">
        <legend><ox-i18n msgid="label.border-style">border style</ox-i18n></legend>

        <div
          id="border-set"
          class="property-grid
          border-style-btn"
          @click=${(e: Event) => this._onClickType(e)}
        >
          <md-icon data-value="out">border_outer</md-icon>
          <md-icon data-value="in">border_inner</md-icon>
          <md-icon data-value="all">border_all</md-icon>
          <md-icon data-value="left">border_left</md-icon>
          <md-icon data-value="center">border_vertical</md-icon>
          <md-icon data-value="right">border_right</md-icon>
          <md-icon data-value="top">border_top</md-icon>
          <md-icon data-value="middle">border_horizontal</md-icon>
          <md-icon data-value="bottom">border_bottom</md-icon>
          <md-icon data-value="clear">border_clear</md-icon>
        </div>

        <div class="property-grid">
          <label class="icon-only-label linewidth"> </label>
          <input
            type="number"
            id="border-width"
            @change=${(e: Event) => (this.borderWidth = Number((e.target as HTMLInputElement).value))}
            .value=${this.borderWidth}
          />

          <label class="icon-only-label color"> </label>
          <ox-input-color
            id="border-color"
            @change=${(e: Event) => (this.borderColor = (e.target as any).value)}
            .value=${this.borderColor}
          >
          </ox-input-color>

          <label> <ox-i18n msgid="label.border-type">border type</ox-i18n> </label>
          <paper-dropdown-menu no-label-float="true" class="line-type solid">
            <!-- solid는 선택된 항목 보여주기위한 class로 하위 paper-item의 class와 동일하게 -->
            <paper-listbox
              id="border-style"
              @iron-select=${(e: Event) => (this.borderStyle = (e.target as any).selected)}
              slot="dropdown-content"
              .selected=${this.borderStyle}
              attr-for-selected="name"
            >
              <paper-item class="solid" name="solid"></paper-item>
              <paper-item class="round-dot" name="round-dot"></paper-item>
              <paper-item class="square-dot" name="square-dot"></paper-item>
              <paper-item class="dash" name="dash"></paper-item>
              <paper-item class="dash-dot" name="dash-dot"></paper-item>
              <paper-item class="long-dash" name="long-dash"></paper-item>
              <paper-item class="long-dash-dot" name="long-dash-dot"></paper-item>
              <paper-item class="long-dash-dot-dot" name="long-dash-dot-dot"></paper-item>
            </paper-listbox>
          </paper-dropdown-menu>
        </div>
      </fieldset>

      <fieldset id="cell-fieldset" @click=${(e: Event) => this._onClickCell(e)}>
        <div class="property-grid">
          <div id="merge-cells" table-event><span>merge cells</span></div>
          <div id="split-cells" table-event><span>split cells</span></div>
          <div id="delete-row" table-event><span>delete row</span></div>
          <div id="delete-column" table-event><span>delete column</span></div>
          <div id="insert-above" table-event><span>insert above</span></div>
          <div id="insert-below" table-event><span>insert below</span></div>
          <div id="insert-left" table-event><span>insert left</span></div>
          <div id="insert-right" table-event><span>insert right</span></div>
          <div id="distribute-horizontal" table-event><span>distribute horizontal</span></div>
          <div id="distribute-vertical" table-event><span>distribute vertical</span></div>
        </div>
      </fieldset>
    `
  }

  _onClickCell(e: Event) {
    var target = e.target as HTMLElement

    const event = target?.closest('[table-event]')?.getAttribute('id')
    if (!event) {
      return
    }

    this.dispatchEvent(
      new CustomEvent('i-need-selected', {
        bubbles: true,
        composed: true,
        detail: {
          callback: (selected: any[]) => {
            const table = selected[0].parent

            switch (event) {
              case 'delete-row':
                table.deleteRows(selected)
                break
              case 'delete-column':
                table.deleteColumns(selected)
                break
              case 'insert-above':
                table.insertCellsAbove(selected)
                break
              case 'insert-below':
                table.insertCellsBelow(selected)
                break
              case 'insert-left':
                table.insertCellsLeft(selected)
                break
              case 'insert-right':
                table.insertCellsRight(selected)
                break
              case 'merge-cells':
                table.mergeCells(selected)
                break
              case 'split-cells':
                table.splitCells(selected)
                break
              case 'distribute-horizontal':
                table.distributeHorizontal(selected)
                break
              case 'distribute-vertical':
                table.distributeVertical(selected)
                break
            }
          }
        }
      })
    )

    this.value = {
      borderWidth: this.borderWidth,
      borderColor: this.borderColor,
      borderStyle: this.borderStyle
    }

    this.dispatchEvent(
      new CustomEvent('change', {
        detail: this.value,
        bubbles: true,
        composed: true
      })
    )

    e.stopPropagation()
  }

  _onClickType(e: Event) {
    var target = e.target as HTMLElement

    const where = target?.closest('[data-value]')?.getAttribute('data-value')
    if (!where) {
      return
    }

    this.dispatchEvent(
      new CustomEvent('i-need-selected', {
        bubbles: true,
        composed: true,
        detail: {
          callback: (selected: any[]) => {
            const table = selected[0].parent

            table.setCellsStyle(
              selected,
              {
                strokeStyle: this.borderColor,
                lineDash: this.borderStyle,
                lineWidth: this.borderWidth
              },
              where
            )
          }
        }
      })
    )

    this.value = {
      borderWidth: this.borderWidth,
      borderColor: this.borderColor,
      borderStyle: this.borderStyle
    }

    this.dispatchEvent(
      new CustomEvent('change', {
        detail: this.value,
        bubbles: true,
        composed: true
      })
    )

    e.stopPropagation()
  }
}
