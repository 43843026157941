import { RecordCard } from '../data-card/record-card'
import { DataGridBody } from '../data-grid/data-grid-body'
import { DataGridField } from '../data-grid/data-grid-field'
import { RecordPartial } from '../data-list/record-partial'
import { RecordView } from '../record-view/record-view'
import { ColumnConfig, GristRecord } from '../types'

/*
 * handler들은 ox-grid-field 로부터 호출되는 것을 전제로 하며,
 * 전반적인 처리를 위해서, columns 및 data 정보를 포함해서 제공할 수 있어야 한다.
 */

export const RecordViewHandler = function (
  columns: ColumnConfig[],
  record: GristRecord,
  rowIndex: number,
  field: DataGridField | RecordCard | RecordPartial | DataGridBody,
  popupOptions: { [key: string]: any },
  closeCallback?: () => void
): RecordView {
  var recordView = document.createElement('ox-record-view') as RecordView

  recordView.columns = columns
  recordView.record = record
  recordView.rowIndex = rowIndex

  document.dispatchEvent(
    new CustomEvent('open-popup', {
      detail: {
        template: recordView,
        options: {
          backdrop: true,
          size: 'large',
          title: record['name'],
          ...popupOptions
        },
        callback: (popup: any) => {
          recordView.addEventListener('field-change', (e: Event) => {
            field.dispatchEvent(
              new CustomEvent('field-change', {
                bubbles: true,
                composed: true,
                detail: (e as any).detail
              })
            )
          })

          recordView.addEventListener('reset', (e: Event) => {
            field.dispatchEvent(
              new CustomEvent('record-reset', {
                bubbles: true,
                composed: true,
                detail: {
                  record: record,
                  row: rowIndex
                }
              })
            )
          })

          recordView.addEventListener('cancel', (e: Event) => {
            field.dispatchEvent(
              new CustomEvent('record-reset', {
                bubbles: true,
                composed: true,
                detail: {
                  record: record,
                  row: rowIndex
                }
              })
            )
            popup.close()
          })

          recordView.addEventListener('ok', (e: Event) => {
            popup.close()
          })

          popup.onclosed = closeCallback
        }
      }
    } as any)
  )

  return recordView
}
