import '@operato/input/ox-checkbox.js'

import { html } from 'lit'
import { until } from 'lit/directives/until.js'

import { FilterConfigObject, FilterOperator, FilterSelectRenderer, SelectOption, SelectOptionObject } from '../types'
import { OxFiltersForm } from './filters-form'
import { DataGridHeader } from '../data-grid/data-grid-header'

function buildOptions(options: SelectOption[], operator?: FilterOperator) {
  const selectOptionObjects = options.map(option => {
    switch (typeof option) {
      case 'string':
        return {
          display: option,
          value: option
        }
      case 'object':
        return {
          display: option.display || option.name,
          value: option.value
        }
      default:
        return option
    }
  }) as SelectOptionObject[]

  return operator === 'in'
    ? html`
        ${selectOptionObjects
          ?.filter(option => !!option)
          .map(
            ({ value, display, name }) => html` <ox-checkbox option value=${value}>${display || name}</ox-checkbox> `
          )}
      `
    : html`
        ${selectOptionObjects?.map(
          ({ value, display, name }) => html` <div option value=${value}>${display || name}&nbsp;</div> `
        )}
      `
}

export const FilterSelect: FilterSelectRenderer = (column, value, owner) => {
  /* value는 filters-form이나 grid-header에서 처리되므로 이 곳에서는 무시한다. */
  const filter = column.filter as FilterConfigObject
  const operator = filter?.operator
  const form = owner as OxFiltersForm | DataGridHeader

  var options = filter?.options || column.record.options || []

  if (typeof options === 'function') {
    if (!filter?.options) {
      console.warn(
        'ox-grist의 column.filter 속성에서는 column.record.options의 함수형 options을 사용할 수 없으므로, filter 속성에서 재지정해야한다.'
      )
    }

    options = options.call(null, value, column, form instanceof OxFiltersForm ? form.getFormObjectValue() : {}, owner)

    if (options instanceof Promise) {
      return html`${until(options.then(options => buildOptions(options, operator)))}`
    } else {
      return buildOptions((options || []) as SelectOption[], operator)
    }
  } else {
    return buildOptions((options || []) as SelectOption[], operator)
  }
}
