/**
 * 풀스크린 전환 이후와 풀스크린 해제 이후에 호출되는 콜백함수
 * @callback FullscreenCallback
 */
export type FullscreenCallback = () => void

/**
 * 엘리먼트를 풀스크린으로 표시되도록 한다.
 * @param {HTMLElement} element 대상 엘리먼트
 * @param {FullscreenCallback} afterfull 풀스크린이 된 이후에 호출되는 콜백함수
 * @param {FullscreenCallback} afterfinish 풀스크린이 해제된 이후에 호출되는 콜백함수
 */
export function fullscreen(element: HTMLElement, afterfull?: FullscreenCallback, afterfinish?: FullscreenCallback) {
  var org_width = element.style.width
  var org_height = element.style.height

  function _fullscreen_callback(e: Event) {
    if (
      !document.fullscreenElement &&
      //@ts-ignore
      !document.mozFullScreen &&
      //@ts-ignore
      !document.webkitIsFullScreen &&
      //@ts-ignore
      !document.msFullscreenElement
    ) {
      ;['fullscreenchange', 'webkitfullscreenchange', 'MSFullscreenChange'].forEach(event =>
        document.removeEventListener(event, _fullscreen_callback)
      )

      element.style.width = org_width
      element.style.height = org_height

      afterfinish && afterfinish()
    } else {
      element.style.width = '100%'
      element.style.height = '100%'

      afterfull && afterfull()
    }
  }

  ;['fullscreenchange', 'webkitfullscreenchange', 'MSFullscreenChange'].forEach(event =>
    document.addEventListener(event, _fullscreen_callback)
  )

  if (element.requestFullscreen) element.requestFullscreen()
  //@ts-ignore
  else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen()
  //@ts-ignore
  else if (element.mozRequestFullScreen) element.mozRequestFullScreen()
  //@ts-ignore
  else if (element.msRequestFullscreen) element.msRequestFullscreen()
}

export function exitfullscreen() {
  if (document.exitFullscreen) document.exitFullscreen()
  //@ts-ignore
  else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
  //@ts-ignore
  else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen()
  //@ts-ignore
  else if (document.msExitFullscreen) document.msExitFullscreen()
}

export function togglefullscreen(
  element: HTMLElement,
  afterfull?: FullscreenCallback,
  afterfinish?: FullscreenCallback
) {
  if (
    !document.fullscreenElement &&
    //@ts-ignore
    !document.mozFullScreen &&
    //@ts-ignore
    !document.webkitIsFullScreen &&
    //@ts-ignore
    !document.msFullscreenElement
  )
    fullscreen(element, afterfull, afterfinish)
  else exitfullscreen()
}
