import { css, html, LitElement, PropertyValues, TemplateResult, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ZERO_COLUMN } from '../configure/zero-config'
import { AccumulatorObject, ColumnConfig, GristRecord } from '../types'
import { i18next } from '@operato/i18n'

const DEFAULT_TEXT_ALIGN = 'left'

@customElement('ox-grid-accum-field')
export class DataGridAccumField extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;

        align-items: center;
        justify-content: var(--data-grid-field-justify-content, flex-start);
        position: relative;

        white-space: nowrap;
        background-color: var(--md-sys-color-primary-container);
        border: var(--grid-record-border-bottom);
        border-width: 1px 0;
        padding: var(--spacing-small);

        font-size: var(--grid-record-wide-fontsize);
        color: var(--md-sys-color-primary);
        font-weight: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500));

        min-height: 30px;
        box-sizing: border-box;
      }

      span,
      pre {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      * {
        margin: 0;
        text-align: var(--data-grid-field-text-align);
      }

      *[center] {
        flex: none;
        margin: 0 auto;
      }

      md-icon {
        font-size: 1.5em;
      }
    `
  ]

  @property({ type: String, attribute: true }) align = DEFAULT_TEXT_ALIGN
  @property({ type: Object }) record: GristRecord = {}
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex = -1
  @property({ type: Number }) columnIndex = 0
  @property({ type: Boolean }) editing = false
  @property({ type: Object }) value = {}
  @property({ attribute: false }) emphasized: any = false
  @property({ type: String, attribute: true }) fixed?: string

  render(): TemplateResult {
    if (!this.column || !this.column.accumulator) {
      return this.columnIndex == 0 ? html`<md-icon>functions</md-icon>` : html`${nothing}`
    }

    var { value, column, record, rowIndex } = this
    var { renderer } = column.record

    // tag가 true이면 value 앞에 (sum) 같은 태그 생성
    var { accumulator } = column
    let tag = ''
    if (typeof accumulator === 'object' && typeof accumulator.type === 'string' && accumulator?.tag) {
      tag = `(${i18next.t(`label.accumulator_${accumulator.type}`)})`
    }

    return html`${tag}&nbsp;${renderer?.call(this, value, column, record, rowIndex, this)}`
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('column')) {
      var align = this.column.record.align || DEFAULT_TEXT_ALIGN
      if (align != DEFAULT_TEXT_ALIGN) {
        let justify = 'center'
        switch (align) {
          case 'right':
            justify = 'flex-end'
            break
        }
        this.style.setProperty('--data-grid-field-justify-content', justify)
        this.style.setProperty('--data-grid-field-text-align', align)
      }
    }

    if (changes.has('fixed')) {
      if (this.fixed) {
        this.style.left = this.fixed + 'px'
      } else {
        this.style.left = 'unset'
      }
    }
  }
}
