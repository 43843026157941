import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-checkbox')
export class OxGristEditorCheckbox extends OxGristEditor {
  _onchange(e: Event) {
    e.stopPropagation()

    const input = e.target as HTMLInputElement

    this._dirtyValue = this.formatFromEditor(input.checked)
  }

  formatFromEditor(value: any) {
    if (typeof value == 'boolean') {
      return value
    }
  }

  formatForEditor(value: any): any {
    return value == !!value && !!String(value).match(/true/i)
  }

  get editorTemplate() {
    return html` <input type="checkbox" .checked=${!!this.value && !!String(this.value).match(/true/i)} /> `
  }
}
