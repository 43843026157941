/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import './ox-input-color.js'
import '@material/web/icon/icon.js'

import { css, html } from 'lit'
import { customElement, property, query, queryAll } from 'lit/decorators.js'
import { random as randomColor, TinyColor } from '@ctrl/tinycolor'

import { OxFormField } from './ox-form-field.js'
import { OxInputColor } from './ox-input-color.js'

/**
색상 배열을 편집하는 컴포넌트이다.

새로운 색상을 추가하고자 할 때는 `+` 버튼을 클릭한다.<br />
색상을 제거하고자 할 때는 `-` 버튼을 클릭한다.<br />

Example:

    <ox-input-multiple-colors values=${values}>
    </ox-input-multiple-colors>
*/

@customElement('ox-input-multiple-colors')
export class OxInputMultipleColors extends OxFormField {
  static styles = css`
    :host {
      display: inline-block;

      --md-icon-size: var(--fontsize-default, 14px);
    }

    #colors-container > div {
      display: grid;
      grid-template-columns: 34px 1fr 34px;
      grid-gap: var(--spacing-medium);
      align-items: center;
      justify-content: left;
      margin-bottom: var(--spacing-tiny, 2px);
    }

    ox-input-color {
      width: 100%;
    }

    md-icon {
      pointer-events: none;
    }

    button {
      height: 100%;
      border: var(--button-border);
      border-radius: 0;
      background-color: transparent;
      line-height: 0.8;
      color: var(--md-sys-color-on-surface);
      cursor: pointer;
    }

    button:hover,
    button:active {
      border: var(--button-activ-border);
      background-color: var(--md-sys-color-on-primary);
      color: var(--md-sys-color-primary);
    }
  `

  @property({ type: Array }) value: string[] = []

  @query('#colors-container') colorsContainer!: HTMLDivElement
  @queryAll('ox-input-color') colors!: NodeListOf<OxInputColor>

  firstUpdated() {
    this.renderRoot.addEventListener('change', this._onValueChanged.bind(this))
  }

  render() {
    return html`
      <div id="colors-container">
        ${(this.value || []).map(
          (item, index) => html`
            <div>
              <button @tap=${(e: Event) => this._appendEditorColor(e)} data-index=${index} ?disabled=${this.disabled}>
                <md-icon>add</md-icon>
              </button>
              <ox-input-color .value=${item} ?disabled=${this.disabled}> </ox-input-color>

              ${(this.value || []).length > 1
                ? html`
                    <button
                      @tap=${(e: Event) => this._removeEditorColor(e)}
                      data-index=${index}
                      ?disabled=${this.disabled}
                    >
                      <md-icon>remove</md-icon>
                    </button>
                  `
                : html``}
            </div>
          `
        )}
      </div>
    `
  }

  _onValueChanged() {
    this.value = Array.from(this.colors).map(color => color.value!)
  }

  _appendEditorColor(e: Event) {
    const idx = Number((e.target as HTMLElement).getAttribute('data-index'))
    const hue = this.value[idx] || '#fff'
    this.value.splice(idx + 1, 0, randomColor({ hue }).toRgbString())
    this.value = [...this.value]

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true }))
  }

  _removeEditorColor(e: Event) {
    var values = []
    for (var i = 0; i < this.value.length; i++) {
      if (i == Number((e.target as HTMLElement).getAttribute('data-index'))) {
        continue
      } else {
        values.push(this.value[i])
      }
    }

    this.value = values
    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true }))
  }
}
