import { AccumulatorObject, ColumnConfig, GristData } from '../types'

function sum(data: GristData, column: ColumnConfig): number {
  const name = column.name

  return data.records
    .filter(record => !Number.isNaN(record[name]))
    .reduce((sum, record) => {
      return sum + Number(record[name] || 0)
    }, 0)
}

function avg(data: GristData, column: ColumnConfig): number {
  const name = column.name
  const effectiveRecords = data.records.filter(record => !Number.isNaN(record[name]))

  return (
    effectiveRecords.reduce((sum, record) => {
      return sum + Number(record[name] || 0)
    }, 0) / effectiveRecords.length
  )
}

function count(data: GristData, column: ColumnConfig): number {
  return data.records.length
}

function min(data: GristData, column: ColumnConfig): number {
  const name = column.name

  return Math.min.apply(
    null,
    data.records.filter(record => !Number.isNaN(record[name])).map(record => Number(record[name] || 0))
  )
}

function max(data: GristData, column: ColumnConfig): number {
  const name = column.name

  return Math.max.apply(
    null,
    data.records.filter(record => !Number.isNaN(record[name])).map(record => Number(record[name] || 0))
  )
}

const Accumulators: { [name: string]: (data: GristData, column: ColumnConfig) => string | number } = {
  sum,
  avg,
  count,
  min,
  max
}

export function accumulate(data: GristData, column: ColumnConfig, accumulator: AccumulatorObject) {
  accumulator = typeof accumulator === 'object' ? accumulator.type : accumulator
  var func = accumulator as (data: GristData, column: ColumnConfig) => string | number

  if (typeof accumulator == 'string') {
    func = Accumulators[accumulator]
  }

  return func.call(null, data, column)
}
