import { formatter } from './formatter'

export type RelativeTimeParams = {
  relativeSeconds?: number
  timeZone?: string
  locale?: string
  format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions
}

export const getRelativeTime = (params: RelativeTimeParams = {}): string | number | Date => {
  const { relativeSeconds = 0, timeZone, format, locale } = params

  const now = new Date()

  let currentDate: Date
  if (timeZone) {
    const options = { timeZone }
    const currentDateISOString = now.toLocaleString('en-US', options).split(',')[0]
    currentDate = new Date(currentDateISOString)
  } else {
    currentDate = now
  }

  const targetTime = currentDate.getTime() + relativeSeconds * 1000

  return formatter(targetTime, params)
}
