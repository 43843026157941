import '@operato/input/ox-input-textarea.js'

import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-textarea')
export class OxGristEditorTextarea extends OxGristEditor {
  get inlineEditable() {
    return true
  }

  get editorTemplate() {
    return html` <ox-input-textarea .value=${this.value}></ox-input-textarea> `
  }
}
