/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { i18next } from '@operato/i18n'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-duration')
export class OxInputDuration extends OxFormField {
  static styles = css`
    :host {
      text-align: center;
      padding: 10px;
      --md-filled-icon-button-container-height: var(--form-element-height-medium);
      --md-filled-icon-button-container-shape: var(--md-sys-shape-corner-small);
    }

    :host * {
      box-sizing: border-box;
    }

    :host *:focus {
      outline: none;
    }

    form {
      display: flex;
      flex-direction: row;

      align-items: center;
    }

    [padding] {
      min-width: 100px;
    }

    input {
      box-sizing: border-box;
      padding: 0 var(--spacing-small);
      height: var(--form-element-height-medium);
      border: 1px solid var(--md-sys-color-outline);
      border-radius: var(--md-sys-shape-corner-small);
      max-width: 70px;
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
      text-align: right;
    }

    input:focus {
      outline: none;
      border-color: var(--md-sys-color-secondary-fixed-dim);
    }

    input:invalid {
      border-bottom: 1px solid var(--md-sys-color-error);
      color: var(--md-sys-color-error);
    }

    label {
      width: 100%;
      margin-right: var(--spacing-medium);
      font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
      color: var(--md-sys-color-on-primary-container);
    }
  `

  @property({ type: Number }) value?: number

  render() {
    var d = Number(this.value || 0)

    const days = Math.floor(d / (3600 * 24))
    d -= days * 24 * 3600
    const hours = Math.floor(d / 3600)
    d -= hours * 3600
    const minutes = Math.floor(d / 60)
    const seconds = d - minutes * 60

    return html`
      <form @change=${this.onChange.bind(this)}>
        <div padding></div>
        <input id="days" type="number" .value=${String(days || 0)} pattern="\\d*" ?disabled=${this.disabled} />
        <label for="days">${i18next.t('label.days')}</label>

        <input
          id="hours"
          type="number"
          .value=${String(hours || 0)}
          pattern="[01]?\\d|2[0-3]"
          ?disabled=${this.disabled}
        />
        <label for="hour">${i18next.t('label.hours')}</label>

        <input
          id="minutes"
          type="number"
          .value=${String(minutes || 0)}
          pattern="[0-5]?\\d"
          ?disabled=${this.disabled}
        />
        <label for="minute">${i18next.t('label.minutes')}</label>

        <input
          id="seconds"
          type="number"
          .value=${String(seconds || 0)}
          pattern="[0-5]?\\d"
          ?disabled=${this.disabled}
        />
        <label for="second">${i18next.t('label.seconds')}</label>

        <md-filled-icon-button
          type="button"
          @click=${(e: Event) => {
            e.preventDefault()
            e.stopPropagation()

            this.value = 0

            this.dispatchEvent(
              new CustomEvent('change', {
                bubbles: true,
                composed: true,
                detail: this.value
              })
            )
          }}
          ?disabled=${this.disabled}
        >
          <md-icon>backspace</md-icon>
        </md-filled-icon-button>
        <div padding></div>
      </form>
    `
  }

  firstUpdated() {
    ;(this.renderRoot.querySelector('input') as HTMLInputElement).focus()
  }

  @query('#days') days!: HTMLInputElement
  @query('#hours') hours!: HTMLInputElement
  @query('#minutes') minutes!: HTMLInputElement
  @query('#seconds') seconds!: HTMLInputElement

  onChange(e: Event) {
    e.stopPropagation()

    var form = this.renderRoot.querySelector('form') as HTMLFormElement
    var valid = form.checkValidity()
    if (!valid) {
      form.reportValidity()
      return
    }

    const days = Number(this.days.value)
    const hours = Number(this.hours.value)
    const minutes = Number(this.minutes.value)
    const seconds = Number(this.seconds.value)

    this.value = (days || 0) * 24 * 60 * 60 + (hours || 0) * 60 * 60 + (minutes || 0) * 60 + (seconds || 0)

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }
}
