import '../empty-note'
import './data-grid-header'
import './data-grid-body'
import './data-grid-footer'

import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import { styles as MDTypeScaleStyles } from '@material/web/typography/md-typescale-styles'

import { ScrollbarStyles } from '@operato/styles'

import { DataManipulator } from '../data-manipulator'
import { ColumnConfig, GristRecord, PaginationConfig } from '../types'
import { supportsPassive } from '../utils'
import { DataGridHeader } from './data-grid-header'

/**
 * DataGrid
 */
@customElement('ox-grid')
export class DataGrid extends DataManipulator {
  static styles = [
    MDTypeScaleStyles,
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;

        overflow: hidden;

        border-width: var(--grid-container-border-width);
      }

      ox-grid-body {
        flex: 1;
        position: relative;

        font-variation-settings: 'FILL' 1;
      }

      ox-grid-header {
        font-variation-settings: 'FILL' 1;
      }

      ox-grid-footer {
        font-variation-settings: 'FILL' 1;
      }

      ox-empty-note {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      div[setting] {
        position: relative;
        z-index: 5;
      }

      @media print {
        :host {
          zoom: 80%;
        }
      }
    `
  ]

  @property({ type: Object }) focused?: { row: number; column: number }
  @property({ type: Boolean }) empty?: boolean

  @query('ox-grid-body', true) body!: LitElement
  @query('ox-grid-header', true) header!: DataGridHeader
  @query('ox-grid-footer', true) footer!: LitElement

  @state() fixedLefts: number[] = []

  firstUpdated() {
    /* header and body scroll synchronization */
    this.header.addEventListener('scroll', e => {
      if (this.body.scrollLeft !== this.header.scrollLeft) {
        this.body.scrollLeft = this.header.scrollLeft
      }
    })

    this.body.addEventListener('scroll', e => {
      if (this.body.scrollLeft !== this.header.scrollLeft) {
        this.header.scrollLeft = this.body.scrollLeft
      }
    })

    this.header.addEventListener('wheel', this.onWheelEvent.bind(this))
    this.footer && this.footer.addEventListener('wheel', this.onWheelEvent.bind(this))

    /* record selection processing */
    this.addEventListener('select-all-change', e => {
      if (this.data.records.length == 0) {
        return
      }

      var { selected } = (e as CustomEvent).detail
      var { records } = this.data

      records.forEach(record => (record['__selected__'] = selected))

      this.header.requestUpdate()
      this.body.requestUpdate()
    })

    this.addEventListener('focus-change', e => {
      const { row, column } = (e as CustomEvent).detail || {}
      if (!this.focused || this.focused.row != row || this.focused.column != column) {
        this.focused = { row, column }
        this.focus()
        this.requestUpdate()
      }
    })
  }

  onWheelEvent(e: WheelEvent) {
    if (this.body.scrollWidth > this.body.clientWidth) {
      var delta = Math.max(-1, Math.min(1, e.deltaY || 0))
      this.body.scrollLeft = Math.max(0, this.body.scrollLeft - delta * 30)

      var maxScrollLeft = this.body.scrollWidth - this.body.clientWidth

      var atStart = this.body.scrollLeft === 0
      var atEnd = this.body.scrollLeft === maxScrollLeft

      // 스크롤이 맨 앞으로 와 있는 상태에서 휠을 올리는 경우 또는
      // 스크롤이 맨 끝으로 가 있는 상태에서 휠을 내리는 경우에만 디폴트 동작 허용
      if ((atStart && delta > 0) || (atEnd && delta < 0)) {
        return true
      }

      e.preventDefault()
    }
  }

  onSelectRecordChanged({
    selectedRecords,
    added,
    removed
  }: {
    selectedRecords: GristRecord[]
    added: GristRecord[]
    removed: GristRecord[]
  }): void {
    super.onSelectRecordChanged({ selectedRecords, added, removed })

    this.header.requestUpdate()
    this.body.requestUpdate()
  }

  onRecordChanged(
    recordData: GristRecord,
    row: number,
    column: ColumnConfig | null /* TODO column should be removed */
  ) {
    /* record-viewer 등 grid 외에서 변경되는 경우에도 header와 body를 refresh 하도록 한다. */
    super.onRecordChanged(recordData, row, column)

    this.header.requestUpdate()
    this.body.requestUpdate()
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('data')) {
      /*
       * 데이타 내용에 따라 동적으로 컬럼의 폭이 달라지는 경우(예를 들면, sequence field)가 있으므로,
       * data의 변동에 대해서도 다시 계산되어야 한다.
       */
      this.calculateWidths(this.config.columns)

      if (this.focused && 'row' in this.focused) {
        var { row = 0, column = 0 } = this.focused
        var { records: oldrecords = [] } = changes.get('data') || {}
        var { records: newrecords } = this.data

        var oldrecord = oldrecords[row]

        if (oldrecord && oldrecord !== newrecords[row]) {
          var newrecord = newrecords.find(
            record =>
              /* TODO keyfields를 정의하고, keyfields의 동일성으로 확정해야 한다. */
              oldrecord === record ||
              ('id' in oldrecord
                ? record.id == oldrecord.id
                : 'name' in oldrecord
                  ? record.name == oldrecord.name
                  : false)
          )

          this.focused = {
            row: newrecord ? newrecords.indexOf(newrecord) : row,
            column
          }
        }
      }
    } else if (changes.has('config')) {
      this.calculateWidths(this.config.columns)
    }

    /* grid fetch 후에 grid로 focus를 주어야 하는 지 검토가 필요하다. */
    // this.focus()
  }

  calculateWidths(columns: ColumnConfig[]) {
    /*
     * 컬럼 모델 마지막에 'auto' cell을 추가하여, 자투리 영역을 꽉 채워서 표시한다.
     */
    const widths = columns
      .filter(column => !column.hidden)
      .map(column => {
        switch (typeof column.width) {
          case 'number':
            return column.width + 'px'
          case 'string':
            return column.width
          case 'function':
            return column.width.call(this, column)
          default:
            return 'auto'
        }
      })
      .concat(['auto'])
      .join(' ')

    this.style.setProperty('--grid-template-columns', widths)
    this.style.setProperty('--grid-template-print-columns', widths.replace(/px/gi, 'fr'))
  }

  render() {
    var { pagination = {} as PaginationConfig, columns = [], filters } = this.config

    var paginatable = !pagination.infinite
    var data = this.data

    return html`
      <div setting>
        <slot name="setting"></slot>
      </div>

      <ox-grid-header
        class="md-typescale-label-medium-prominent"
        .columns=${columns}
        .sorters=${this.sorters}
        .filters=${this.filters}
        .data=${data}
        ?filtering-feature=${filters?.header}
        @column-width-change=${(e: CustomEvent) => {
          let { idx, width } = e.detail
          columns[idx].width = width
          this.calculateWidths(columns)
        }}
        @fixed-lefts-change=${(e: CustomEvent) => (this.fixedLefts = e.detail)}
      >
      </ox-grid-header>

      <ox-grid-body
        class="md-typescale-body-medium"
        .config=${this.config}
        .columns=${columns}
        .data=${data}
        .focused=${this.focused!}
        .fixedLefts=${this.fixedLefts}
      >
        ${this.empty ? html` <ox-empty-note title="NO RECORDS"></ox-empty-note> ` : html``}
      </ox-grid-body>
      ${paginatable
        ? html`
            <ox-grid-footer
              class="md-typescale-body-medium"
              .data=${data}
              .pagination=${this.pagination}
            ></ox-grid-footer>
          `
        : html``}
    `
  }

  focus() {
    super.focus()

    this.body.focus()
  }

  get pullToRefreshTarget() {
    return this.body
  }
}
