import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-tel')
export class OxGristEditorTel extends OxGristEditor {
  get editorTemplate() {
    return html` <input type="tel" .value=${this.value} /> `
  }
}
