import {
  ColumnConfig,
  FieldRenderer,
  GristClassifier,
  GristConfig,
  GristData,
  GristEventHandlerSet,
  GristRecord,
  GroupConfig,
  LabelConfig,
  ListConfig,
  TreeConfig,
  RowsConfig
} from '../types'

export const ZERO_CLASSIFIER: GristClassifier = (record, rowIndex) => {}
export const ZERO_FIELD_RENDERER: FieldRenderer = (value, column, record, rowIndex, owner) => {}

export const ZERO_EVENTHANDLERSET: GristEventHandlerSet = {
  click: undefined,
  dblclick: undefined,
  focus: undefined,
  contextmenu: undefined
}
export const ZERO_COLUMNS: ColumnConfig[] = []
export const ZERO_GROUPS: GroupConfig[] = []
export const ZERO_TOTALS: [] = []
export const ZERO_LABEL: LabelConfig = false

export const ZERO_COLUMN: ColumnConfig = {
  type: '',
  name: '',
  header: {
    renderer: column => {}
  },
  record: {
    renderer: ZERO_FIELD_RENDERER,
    classifier: ZERO_CLASSIFIER,
    options: {}
  },
  handlers: ZERO_EVENTHANDLERSET,
  label: ZERO_LABEL
}

export const ZERO_ROWS: RowsConfig = {
  appendable: false,
  insertable: false,
  editable: false,
  groups: ZERO_GROUPS,
  totals: ZERO_TOTALS,
  classifier: ZERO_CLASSIFIER,
  handlers: ZERO_EVENTHANDLERSET
}

export const ZERO_LIST: ListConfig = {
  fields: ['name', 'description', 'updatedAt'],
  details: []
}

export const ZERO_TREE: TreeConfig = {}

export const ZERO_CONFIG: GristConfig = {
  columns: ZERO_COLUMNS,
  rows: ZERO_ROWS,
  list: ZERO_LIST,
  tree: ZERO_TREE
}

export const ZERO_RECORD: GristRecord = {}
export const ZERO_RECORDS: GristRecord[] = []

export const ZERO_DATA: GristData = {
  records: ZERO_RECORDS
}

export const ZERO_PAGES = [20, 30, 50, 100]

export const ZERO_PAGINATION = {
  page: 1,
  limit: 20,
  total: 0,
  pages: ZERO_PAGES
}
