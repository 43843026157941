/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

/*
  This component is inspired by https://github.com/Polydile/dile-components, thanks Dile.
*/

import { css, html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-checkbox')
export class OxCheckbox extends OxFormField {
  static styles = [
    css`
      div {
        display: flex;
        gap: var(--input-intra-gap, 7px);

        align-items: center;
        cursor: pointer;
      }

      [disabled] {
        opacity: var(--ox-checkbox-disabled-opacity, 0.5);
        cursor: auto;
      }

      .checkbox {
        display: flex;
        border-radius: var(--ox-checkbox-border-radius, 4px);
        background-color: var(--ox-checkbox-unchecked-background-color, var(--md-sys-color-surface-container-lowest));
        border: var(--ox-checkbox-unchecked-border, 1px solid var(--md-sys-color-outline));
        width: var(--ox-checkbox-size, 15px);
        height: var(--ox-checkbox-size, 15px);
        align-items: center;
        justify-content: center;
      }

      :host([checked]) .checkbox {
        background-color: var(--ox-checkbox-checked-background-color, var(--md-sys-color-primary));
      }

      path {
        fill: var(--ox-checkbox-unchecked-color, var(--md-sys-color-surface-container-lowest));
      }

      :host([indeterminate]) line {
        stroke: var(--ox-checkbox-intermidiate-color, var(--md-sys-color-outline));
      }

      :host([checked]) path {
        fill: var(--ox-checkbox-checked-color, var(--md-sys-color-on-primary));
      }

      svg {
        width: var(--ox-checkbox-size, 15px);
        height: var(--ox-checkbox-size, 15px);
      }

      .label {
        color: var(--ox-checkbox-label-color, var(--md-sys-color-on-surface));
        line-height: var(--md-sys-typescale-label-medium-line-height, 1rem);
      }

      :host([checked]) .label {
        color: var(ox-checkbox-label-checked-color, var(--md-sys-color-on-surface));
        font-weight: 700;
      }
    `
  ]

  @property({ type: Boolean, attribute: 'checked', reflect: true }) checked: boolean | undefined = false
  @property({ type: Boolean, attribute: 'indeterminatable' }) indeterminatable: boolean = false
  @property({ type: Boolean, attribute: 'indeterminate', reflect: true }) indeterminate: boolean = false
  @property({ type: Boolean, attribute: 'left-label' }) left: boolean = false

  @state() _hasInner: boolean = !!this.innerHTML.trim().length

  render() {
    return html`
      <div ?disabled=${this.disabled} @click=${this.onClick}>
        ${this._hasInner && this.left
          ? html` <span label>
              <slot></slot>
            </span>`
          : ''}
        <a href="#" @click=${(e: Event) => e.preventDefault()} class="checkbox">
          ${this.indeterminate ? this.indeterminateIcon : this.checked ? this.checkedIcon : this.uncheckedIcon}
        </a>
        ${this._hasInner && !this.left
          ? html` <span class="label">
              <slot></slot>
            </span>`
          : ''}
      </div>
    `
  }

  connectedCallback(): void {
    super.connectedCallback()

    this.setAttribute('tabindex', '0')
    this.addEventListener('keydown', this.onKeyDown)
  }

  disconnectedCallback(): void {
    super.disconnectedCallback()

    this.setAttribute('tabindex', '-1')
    this.removeEventListener('keydown', this.onKeyDown)
  }

  onClick() {
    if (this.disabled) {
      return
    }

    if (!this.indeterminatable) {
      this.indeterminate = false
      this.checked = !this.checked
    } else {
      if (this.indeterminate) {
        this.indeterminate = false
        this.checked = true
      } else if (this.checked) {
        this.indeterminate = false
        this.checked = false
      } else {
        this.indeterminate = true
        this.checked = undefined
      }
    }

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.checked
      })
    )
  }

  get checkedIcon() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </svg>
    `
  }

  get uncheckedIcon() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
      </svg>
    `
  }

  get indeterminateIcon() {
    return html`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <line x1="4" x2="20" y1="12" y2="12" stroke-width="2" stroke-linecap="round" />
      </svg>
    `
  }

  onKeyDown(e: KeyboardEvent) {
    e.preventDefault()

    if (e.key === ' ' || e.key == 'Spacebar') {
      this.onClick()
    }
  }

  protected appendFormData({ formData }: FormDataEvent): void {
    this.name && !this.indeterminate && formData.append(this.name, this.checked ? 'true' : 'false')
  }
}
