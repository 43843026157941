import { formatter } from './formatter'

export type RelativeMonthDateParams = {
  timeZone?: string
  format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions
  locale?: string
  relativeMonths?: number
  relativeDays?: number
}

export const getRelativeMonthDate = (params: RelativeMonthDateParams = {}): Date | number | string => {
  const { relativeMonths = 0, relativeDays = 0, timeZone, format, locale } = params

  const now = new Date()
  let currentDate: Date

  if (timeZone) {
    const options: Intl.DateTimeFormatOptions = { timeZone }
    const currentDateISOString = now.toLocaleString('en-US', options).split(',')[0]
    currentDate = new Date(currentDateISOString)
  } else {
    currentDate = now
  }

  const targetMonth = currentDate.getMonth() + relativeMonths
  const targetYear = currentDate.getFullYear() + Math.floor(targetMonth / 12)
  const targetMonthRemainder = targetMonth % 12

  const targetDate = new Date(targetYear, targetMonthRemainder, currentDate.getDate() + relativeDays)

  return formatter(targetDate, params)
}
