import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-datetime')
export class OxGristEditorDateTime extends OxGristEditor {
  formatForEditor(timestamp: any) {
    if (!timestamp) {
      timestamp = Date.now()
    }
    var datetime = new Date(timestamp)

    var tzoffset = datetime.getTimezoneOffset() * 60000 //offset in milliseconds

    return new Date(timestamp - tzoffset).toISOString().slice(0, -1)
  }

  formatFromEditor(value: any) {
    var datetime = new Date(value)

    return datetime.getTime()
  }

  get editorTemplate() {
    return html` <input type="datetime-local" .value=${this.value} max="9999-12-31T23:59" /> `
  }
}
