import { html } from 'lit'

import { FieldRenderer } from '../types'

export const OxGristRendererLink: FieldRenderer = (value, column, record, rowIndex, field) => {
  var { href, target } = column.record.options || {}

  value = value == null ? '' : value

  if (typeof href == 'function') {
    href = href(value, column, record, rowIndex, field)
  }

  return target
    ? html` <a style="text-decoration:none;color:inherit" href=${href || value} target=${target}>${value}&nbsp;</a> `
    : html` <a style="text-decoration:none;color:inherit" href=${href || value}>${value}&nbsp;</a> `
}
