/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { localized } from '@lit/localize'

import { OxFormField } from './ox-form-field'

@localized()
@customElement('ox-input-3axis')
export class OxInput3Axis extends OxFormField {
  static styles = [
    css`
      :host {
        display: flex;
      }

      input {
        box-sizing: border-box;
        padding: 0 var(--spacing-small);
        height: var(--form-element-height-medium);
        border: 1px solid var(--md-sys-color-outline);
        border-radius: var(--md-sys-shape-corner-small);
        background-color: var(--md-sys-color-on-primary);
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-on-primary-container);
      }

      input:not(:last-child) {
        margin-right: var(--margin-default, 2px);
      }

      input:focus {
        outline: none;
        border-color: var(--md-sys-color-secondary-fixed-dim);
      }
    `
  ]

  firstUpdated() {
    this.renderRoot.addEventListener('change', this._onChange.bind(this))
  }

  _onChange(e: Event) {
    e.stopPropagation()
    e.preventDefault()

    var element = e.target as HTMLElement
    var id = element.id as 'x' | 'y' | 'z'
    var value = Number((element as HTMLInputElement).value)

    this.value = {
      ...this.value,
      [id]: value
    }

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }

  render() {
    const { x, y, z } = this.value
    return html`
      <input type="number" id="x" placeholder="X" .value=${String(x)} ?disabled=${this.disabled} />
      <input type="number" id="y" placeholder="Y" .value=${String(y)} ?disabled=${this.disabled} />
      <input type="number" id="z" placeholder="Z" .value=${String(z)} ?disabled=${this.disabled} />
    `
  }
}
