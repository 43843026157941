import { html } from 'lit'

import { format as formatter } from '@operato/utils'

import { FieldRenderer } from '../types'

export const OxGristRendererText: FieldRenderer = (value, column, record, rowIndex, field) => {
  var { format } = column.record || {}

  var text = value == null ? '' : value
  if (format) {
    text = formatter(format, text)
  }

  return html`<span data-reactive-tooltip>${text}&nbsp;</span>`
}
