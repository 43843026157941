import '@operato/input/ox-input-image.js'

import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-image')
export class OxGristEditorImage extends OxGristEditor {
  get editorTemplate() {
    return html` <ox-input-image .value=${this.value}></ox-input-image> `
  }

  _onchange(e: Event) {
    e.stopPropagation()

    const input = e.target as HTMLInputElement

    // value가 image file object인지, image url인지 확인
    if (input.files?.[0]) {
      this._dirtyValue = this.formatFromEditor(input.files[0])
    } else {
      this._dirtyValue = this.formatFromEditor(input.value)
    }

    this._onfocusout()
  }

  formatFromEditor(value: any) {
    return value
  }
}
