/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import '@operato/i18n/ox-i18n.js'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu'
import '@polymer/paper-listbox/paper-listbox'
import '@polymer/paper-item/paper-item'
import '@operato/input/ox-input-angle.js'
import '@operato/input/ox-input-color-stops.js'

import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ColorStop } from './ox-input-color-stops.js'
import { OxFormField } from './ox-form-field.js'

export type GradientOption = {
  type: 'linear' | 'radial'
  rotation: number
  direction?:
    | 'left-to-right'
    | 'lefttop-to-rightbottom'
    | 'top-to-bottom'
    | 'righttop-to-leftbottom'
    | 'right-to-left'
    | 'rightbottom-to-lefttop'
    | 'bottom-to-top'
    | 'leftbottom-to-righttop'
    | 'left-to-right'
    | 'center-to-corner'
  center?: 'center' | 'left-top' | 'right-top' | 'right-bottom' | 'left-bottom'
  colorStops?: ColorStop[]
}

@customElement('ox-input-color-gradient')
export class OxInputColorGradient extends OxFormField {
  static styles = css`
    :host {
      display: grid;

      grid-template-columns: repeat(10, 1fr);
      grid-gap: 5px;
      grid-auto-rows: minmax(24px, auto);

      align-items: center;
    }

    :host > * {
      align-self: stretch;
    }

    :host > label {
      grid-column: span 2;
      text-align: right;
      text-transform: capitalize;
      align-self: center;
    }

    :host > .icon-only-label {
      grid-column: span 1;
    }

    :host > input,
    :host > [editors] {
      grid-column: span 8;
    }

    :host > select {
      grid-column: span 4;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    :host > ox-input-angle {
      grid-column: span 3;
    }

    ox-input-color-stops {
      grid-column: span 10;
    }

    .host > input[type='checkbox'] {
      grid-column: 3 / 4;
    }

    .host > input[type='checkbox'] ~ label {
      grid-column: span 7;
      text-align: left;
    }

    [editors] > :not([active-selector]) {
      display: none;
    }

    [gradient-direction] {
      overflow: hidden;
      max-width: 210px;
    }

    [gradient-direction] paper-item {
      background: url(/assets/images/icon-editor-gradient-direction.png) 50% 0 no-repeat;
      min-height: 32px;
      padding: 3px 5px;
      width: 30px;
      float: left;
    }

    [gradient-direction] [name='lefttop-to-rightbottom'] {
      background-position: 50% 4px;
    }

    [gradient-direction] [name='left-top'] {
      background-position: 50% 4px;
    }

    [gradient-direction] [name='top-to-bottom'] {
      background-position: 50% -46px;
    }

    [gradient-direction] [name='righttop-to-leftbottom'] {
      background-position: 50% -96px;
    }

    [gradient-direction] [name='right-top'] {
      background-position: 50% -96px;
    }

    [gradient-direction] [name='right-to-left'] {
      background-position: 50% -146px;
    }

    [gradient-direction] [name='rightbottom-to-lefttop'] {
      background-position: 50% -196px;
    }

    [gradient-direction] [name='right-bottom'] {
      background-position: 50% -196px;
    }

    [gradient-direction] [name='bottom-to-top'] {
      background-position: 50% -246px;
    }

    [gradient-direction] [name='leftbottom-to-righttop'] {
      background-position: 50% -296px;
    }

    [gradient-direction] [name='left-bottom'] {
      background-position: 50% -296px;
    }

    [gradient-direction] [name='left-to-right'] {
      background-position: 50% -346px;
    }

    [gradient-direction] [name='center-to-corner'] {
      background-position: 50% -396px;
    }

    [gradient-direction] [name='center'] {
      background-position: 50% -396px;
    }

    [gradient-direction] paper-item[focused] {
      background-color: rgba(255, 246, 143, 0.5);
    }

    .icon-only-label {
      top: 0 !important;
      width: 30px !important;
      height: 24px;
      background: url(/assets/images/icon-properties-label.png) no-repeat;
    }

    .icon-only-label.color {
      background-position: 70% -198px;
    }
  `

  @property({ type: Object }) value: GradientOption = {
    type: 'linear',
    direction: 'left-to-right',
    rotation: 0
  }

  firstUpdated() {
    this.renderRoot.addEventListener('change', this._onChange.bind(this))
  }

  _onChange(e: Event) {
    var element = e.target as HTMLElement & { type: string }
    var key = element.getAttribute('value-key')

    if (!key) {
      return
    }

    var value

    switch (element.tagName) {
      case 'INPUT':
        switch (element.type) {
          case 'checkbox':
            value = (element as HTMLInputElement).checked
            break
          case 'number':
            value = Number((element as HTMLInputElement).value) || 0
            break
          case 'text':
            value = String((element as HTMLInputElement).value)
        }
        break

      case 'PAPER-BUTTON':
        value = (element as any).active
        break

      case 'PAPER-LISTBOX':
        value = (element as any).selected
        break

      default:
        value = (element as HTMLInputElement).value
        break
    }

    if (key === 'rotation') {
      this.value = {
        ...this.value,
        rotation: value,
        direction: undefined
      }
    } else if (key === 'direction') {
      if (value) {
        this.value = {
          ...this.value,
          direction: value,
          rotation: this._convertDirectionToRotation(value)
        }
      }
    } else {
      this.value = {
        ...this.value,
        [key]: value
      }
    }

    this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true }))
  }

  render() {
    var selector = (this.value && this.value.type) || 'linear'
    var value = this.value || {}

    return html`
      <label> <ox-i18n msgid="label.type">type</ox-i18n> </label>
      <select value-key="type" .value=${value.type || 'linear'} ?disabled=${this.disabled}>
        <option>linear</option>
        <option>radial</option>
      </select>

      <label class="icon-only-label color"></label>
      <ox-input-angle value-key="rotation" .value=${value.rotation || 0} ?disabled=${this.disabled}> </ox-input-angle>

      <label> <ox-i18n msgid="label.direction">direction</ox-i18n> </label>
      <div editors>
        <paper-dropdown-menu no-label-float="true" ?active-selector=${selector == 'linear'} .value=${value.direction}>
          <paper-listbox
            @selected-changed=${(e: Event) => this._onChange(e)}
            value-key="direction"
            slot="dropdown-content"
            gradient-direction
            .selected=${value.direction}
            attr-for-selected="name"
          >
            <paper-item name="lefttop-to-rightbottom"></paper-item>
            <paper-item name="top-to-bottom"></paper-item>
            <paper-item name="righttop-to-leftbottom"></paper-item>
            <paper-item name="right-to-left"></paper-item>
            <paper-item name="rightbottom-to-lefttop"></paper-item>
            <paper-item name="bottom-to-top"></paper-item>
            <paper-item name="leftbottom-to-righttop"></paper-item>
            <paper-item name="left-to-right"></paper-item>
            <paper-item name="center-to-corner"></paper-item>
          </paper-listbox>
        </paper-dropdown-menu>

        <paper-dropdown-menu no-label-float="true" ?active-selector=${selector == 'radial'} .value=${value.center}>
          <paper-listbox
            @selected-changed=${(e: Event) => this._onChange(e)}
            value-key="center"
            slot="dropdown-content"
            gradient-direction
            .selected=${value.center || 'center'}
            attr-for-selected="name"
          >
            <paper-item name="center"></paper-item>
            <paper-item name="left-top"></paper-item>
            <paper-item name="right-top"></paper-item>
            <paper-item name="right-bottom"></paper-item>
            <paper-item name="left-bottom"></paper-item>
          </paper-listbox>
        </paper-dropdown-menu>
      </div>

      <ox-input-color-stops
        value-key="colorStops"
        type="gradient"
        .value=${value.colorStops}
        ?disabled=${this.disabled}
      >
      </ox-input-color-stops>
    `
  }

  _convertDirectionToRotation(direction: string): number {
    var rotation
    switch (direction) {
      case 'lefttop-to-rightbottom':
        rotation = 45
        break
      case 'top-to-bottom':
        rotation = 90
        break
      case 'righttop-to-leftbottom':
        rotation = 135
        break
      case 'right-to-left':
        rotation = 180
        break
      case 'rightbottom-to-lefttop':
        rotation = 215
        break
      case 'bottom-to-top':
        rotation = 270
        break
      case 'leftbottom-to-righttop':
        rotation = 315
        break
      case 'left-to-right':
      default:
        rotation = 0
        break
    }

    return (rotation / 360) * Math.PI * 2
  }
}
