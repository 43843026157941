import { ColumnConfig, GristRecord } from '../types'
import { LitElement, PropertyValues, TemplateResult, css, html } from 'lit'
import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { customElement, property } from 'lit/decorators.js'

const DEFAULT_TEXT_ALIGN = 'left'

@customElement('ox-report-field')
export class DataReportField extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        align-items: center;
        justify-content: var(--data-report-field-justify-content, flex-start);
        position: relative;

        white-space: nowrap;
        overflow: hidden;
        background-color: var(--report-record-background-color);
        padding: var(--report-record-field-padding);
        border: 1px solid transparent;
        border-bottom: var(--report-record-field-border-bottom);

        font-size: var(--report-record-wide-fontsize);
        text-overflow: ellipsis;

        min-height: 30px;
        box-sizing: border-box;
      }

      :host([gutter]) * {
        cursor: default;
        font-size:1.2em;
      }

      * {
        flex: 1;
        margin: 0;
        text-align: var(--data-report-field-text-align);
      }

      *[center] {
        flex: none;
        margin: 0 auto;
      }
    `
  ]

  @property({ attribute: true }) align?: string
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Number }) rowIndex: number = -1
  @property({ type: Number }) columnIndex: number = -1
  @property({ type: Object }) value: any

  render(): TemplateResult {
    if (!this.column) {
      return html``
    }

    var { value, column, record, rowIndex } = this
    var { renderer } = column.record

    return html` ${renderer(value, column, record, rowIndex, this)} `
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('column')) {
      var align = (this.column.record && this.column.record.align) || DEFAULT_TEXT_ALIGN
      if (align != DEFAULT_TEXT_ALIGN) {
        let justify = 'center'
        switch (align) {
          case 'right':
            justify = 'flex-end'
            break
        }
        this.style.setProperty('--data-report-field-justify-content', justify)
        this.style.setProperty('--data-report-field-text-align', align)
      }
    }
  }
}
