/**
 * async method to implement sleep
 * @param {number} ms milli-seconds to sleep
 * @example
 * await sleep(1000) // pending for 1 second
 */

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
