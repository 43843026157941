import { getRelativeTime } from './time-generator'

export const getCurrentTime = (
  params: { timeZone?: string; format?: 'timestamp' | 'date' | Intl.DateTimeFormatOptions } = {}
): Date | number | string => {
  return getRelativeTime({
    ...params,
    relativeSeconds: 0
  })
}
