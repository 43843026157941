/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { PropertyValues, css, html, nothing } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'

import { OxFormField } from '@operato/input'
import { GristRecord } from '../types'

@customElement('ox-input-tree')
export class OxInputTree extends OxFormField {
  static styles = css`
    :host {
      overflow: hidden;
    }

    div[wrap] {
      flex: 1;

      position: relative;

      display: flex;
      align-items: center;
      gap: 6px;

      padding-left: calc(var(--tree-depth, 0) * 18px);
    }

    span[expander] {
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 20px;
      cursor: pointer;
      position: relative;
    }

    span[expander][collapsed]::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-25%, -50%) rotate(-90deg);
      content: ' ';
      border: 5px solid transparent;
      border-top: 5px solid var(--md-sys-color-on-primary-container, #1890ff);
    }

    span[expander][expanded]::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -25%);
      content: ' ';
      border: 5px solid transparent;
      border-top: 5px solid var(--md-sys-color-on-primary-container, #1890ff);
    }

    span[checkbox] {
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 20px;
      cursor: pointer;
      position: relative;
    }

    span[checkbox]::before {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: ' ';
      display: block;
      width: 10px;
      height: 10px;
      border: 1px solid var(--md-sys-color-on-primary-container, #1890ff);
      border-radius: 2px;
    }

    span[checkbox][checked='checked']::before {
      background-color: var(--md-sys-color-on-primary-container, #1890ff);
      border-color: var(--md-sys-color-on-primary-container, #1890ff);
    }

    span[checkbox][checked='checked']::after {
      position: absolute;
      content: ' ';
      display: block;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 7px;
      border: 2px solid #fff;
      border-top: none;
      border-left: none;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
    }

    span[checkbox][checked='half-checked']::before {
      background-color: var(--md-sys-color-on-primary-container, #1890ff);
      border-color: var(--md-sys-color-on-primary-container, #1890ff);
    }

    span[checkbox][checked='half-checked']::after {
      position: absolute;
      content: ' ';
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 2px;
      background-color: var(--md-sys-color-surface);
    }

    span[label] {
      flex: 1;
    }

    input {
      width: 100%;
      height: 100%;
      border: 0;
      background-color: transparent;
      box-sizing: border-box;
    }

    input:focus {
      outline: none;
    }
  `

  @property({ type: Object }) record!: GristRecord
  @property({ type: Boolean }) selectable?: boolean

  @state() private checked?: 'checked' | 'half-checked' | 'unchecked'
  @state() private expanded?: boolean = false

  @query('input') input!: HTMLInputElement

  render() {
    var { __children__ } = this.record

    const expandable = __children__ && __children__.length > 0

    return html`
      <div wrap>
        ${expandable
          ? html`
              <span
                expander
                @click=${this.onClickExpander.bind(this)}
                ?expanded=${this.expanded}
                ?collapsed=${!this.expanded}
              ></span>
            `
          : html`<span expander></span>`}
        ${this.selectable
          ? html` <span checkbox @click=${this.onClickCheckbox.bind(this)} checked=${ifDefined(this.checked)}></span>`
          : nothing}

        <span label
          ><input
            value=${ifDefined(this.value)}
            @change=${(e: Event) => {
              e.stopPropagation()
              this.value = (e.target as HTMLInputElement).value
              this.dispatchEvent(new CustomEvent('change', { bubbles: true, composed: true }))
            }}
            focus
        /></span>

        <!-- <span label>${this.value}</span> -->
      </div>
    `
  }

  updated(changes: PropertyValues<this>) {
    var { __depth__, __check_in_tree__, __expanded__ } = this.record
    this.checked = __check_in_tree__
    this.expanded = __expanded__

    this.style.setProperty('--tree-depth', String(__depth__))
  }

  focus() {
    this.input.focus()
  }

  select() {
    this.input.select()
  }

  onClickCheckbox(e: MouseEvent) {
    e.stopPropagation()

    this.dispatchEvent(
      new CustomEvent('check-in-tree', {
        bubbles: true,
        composed: true,
        detail: this.record
      })
    )

    this.requestUpdate()
  }

  onClickExpander(e: MouseEvent) {
    e.stopPropagation()

    this.dispatchEvent(
      new CustomEvent(this.record.__expanded__ ? 'collapse-node' : 'expand-node', {
        bubbles: true,
        composed: true,
        detail: this.record
      })
    )

    this.requestUpdate()
  }
}
