/*
 * Source Code from https://github.com/Mottie/javascript-number-formatter
 * 소스코드 출처 : https://github.com/Mottie/javascript-number-formatter
 */

/**
 * Formats a number according to the given mask.
 *
 * ```example
 * var formattedValue = format('+$#,##0.00', 12345.67);
 * console.log(formattedValue); // Output: +$12,345.67
 * ```
 *
 * @param {any} mask - The formatting mask to apply.
 * @param {any} value - The value to format.
 * @returns {string} - The formatted string.
 */
export function format(mask: string, value: number): string {
  if (!mask || isNaN(+value)) {
    return value.toString() // return as it is.
  }

  let isNegative: boolean,
    result: string | RegExpMatchArray | null,
    decimal: string,
    group: string,
    posLeadZero: number,
    posTrailZero: number,
    posSeparator: number,
    part: string[],
    szSep: string[],
    integer: string,
    // find prefix/suffix
    len = mask.length,
    start = mask.search(/[0-9\-\+#]/),
    prefix = start > 0 ? mask.substring(0, start) : '',
    // reverse string: not an ideal method if there are surrogate pairs
    str = mask.split('').reverse().join(''),
    end = str.search(/[0-9\-\+#]/),
    offset = len - end,
    substr = mask.substring(offset, offset + 1),
    indx = offset + (substr === '.' || substr === ',' ? 1 : 0),
    suffix = end > 0 ? mask.substring(indx, len) : '',
    splittedMask: string[],
    splittedValue: string[],
    stringValue: string

  // mask with prefix & suffix removed
  mask = mask.substring(start, indx)

  // convert any string to number according to formation sign.
  value = mask.charAt(0) === '-' ? -value : +value
  isNegative = value < 0 ? ((value = -value), true) : false // process only abs(), and turn on flag.

  // search for separator for grp & decimal, anything not digit, not +/- sign, not #.
  result = mask.match(/[^\d\-\+#]/g)
  decimal = '.' // ( result && result[ result.length - 1 ] ) || '.'; // ','는 소수점이 되지 않게 함
  group = (result && result[1] && result[0]) || ',' // treat the left most symbol as group separator

  // split the decimal for the format string if any.
  splittedMask = mask.split(decimal)
  // Fix the decimal first, toFixed will auto fill trailing zero.
  value = parseFloat(value.toFixed((splittedMask[1] && splittedMask[1].length) || 0))
  stringValue = +value + '' // convert number to string to trim off *all* trailing decimal zero(es)

  // fill back any trailing zero according to format
  posTrailZero = (splittedMask[1] && splittedMask[1].lastIndexOf('0')) || 0 // look for last zero in format
  part = stringValue.split('.')
  // integer will get !part[1]
  if (!part[1] || (part[1] && part[1].length <= posTrailZero)) {
    stringValue = (+value).toFixed(posTrailZero + 1)
  }
  szSep = splittedMask[0].split(group) // look for separator
  splittedMask[0] = szSep.join('') // join back without separator for counting the pos of any leading 0.

  posLeadZero = (splittedMask[0] && splittedMask[0].indexOf('0')) || 0
  if (posLeadZero > -1) {
    while (part[0].length < splittedMask[0].length - posLeadZero) {
      part[0] = '0' + part[0]
    }
  } else if (+part[0] === 0) {
    part[0] = ''
  }

  splittedValue = stringValue.split('.')
  splittedValue[0] = part[0]

  // process the first group separator from decimal (.) only, the rest ignore.
  // get the length of the last slice of split result.
  posSeparator = (szSep[1] && szSep[szSep.length - 1].length) || 0
  if (posSeparator) {
    integer = splittedValue[0]
    str = ''
    offset = integer.length % posSeparator
    len = integer.length
    for (indx = 0; indx < len; indx++) {
      str += integer.charAt(indx) // ie6 only support charAt for sz.
      // -posSeparator so that won't trail separator on full length
      /* jshint -W018 */
      if (!((indx - offset + 1) % posSeparator) && indx < len - posSeparator) {
        str += group
      }
    }
    splittedValue[0] = str
  }
  splittedValue[1] = splittedMask[1] && splittedValue[1] ? decimal + splittedValue[1] : ''

  // remove negative sign if result is zero
  result = splittedValue.join('')
  if (result === '0' || result === '') {
    // remove negative sign if result is zero
    isNegative = false
  }

  // 앞에 +가 붙는다면 양수일 경우에도 +를 표기해줌
  let fixedPlusSign: string

  if (splittedMask[0].substring(0, 1) === '+') fixedPlusSign = isNegative ? '-' : '+'
  else fixedPlusSign = isNegative ? '-' : ''

  // put back any negation, combine integer and fraction, and add back prefix & suffix
  return prefix + (fixedPlusSign + result) + suffix
}
