import { getCurrentTime } from './now-generator'
import { getToday } from './today-generator'
import { getRelativeDate } from './date-generator'
import { getRelativeWeekDate } from './week-date-generator'
import { getRelativeMonthDate } from './month-date-generator'
import { getRelativeYearDate } from './year-date-generator'
import { getRelativeTime } from './time-generator'
import { getRelativeMinuteTime } from './minute-time-generator'
import { getRelativeHourTime } from './hour-time-generator'

export const NOOP = function () {}

/**
 * Function type for generating values.
 *
 * @callback ValueGeneratorFn
 * @param {...any} args - The arguments used for generating a value.
 * @returns {*} - The generated value.
 */
export type ValueGeneratorFn = (...args: any[]) => any

var VALUE_GENERATORS: { [name: string]: ValueGeneratorFn } = {
  now: getCurrentTime,
  today: getToday,
  date: getRelativeDate,
  time: getRelativeTime,
  week: getRelativeWeekDate,
  month: getRelativeMonthDate,
  year: getRelativeYearDate,
  getCurrentTime,
  getToday,
  getRelativeDate,
  getRelativeWeekDate,
  getRelativeMonthDate,
  getRelativeYearDate,
  getRelativeMinuteTime,
  getRelativeHourTime
}

export function registerValueGenerator(name: string, generator: ValueGeneratorFn) {
  VALUE_GENERATORS[name] = generator
}

export function unregisterValueGenerator(name: string) {
  delete VALUE_GENERATORS[name]
}

export function getValueGenerators() {
  return { ...VALUE_GENERATORS }
}

export function getValueGenerator(name: string) {
  return VALUE_GENERATORS[name] || NOOP
}

export function getDefaultValue(parameter: { name: string; params?: any[] } | Function | any, binder?: any) {
  if (!parameter) {
    return
  }

  if (typeof parameter == 'function') {
    return parameter.call(binder || null)
  } else if (typeof parameter == 'object') {
    const { name, params } = parameter
    const generator = getValueGenerator(name)

    if (generator !== NOOP) {
      return generator.call(binder || null, params)
    }
  }

  return parameter
}
