import '@operato/input/ox-input-select-buttons.js'

import { FilterConfigObject, FilterSelectRenderer } from '../types'

import { html } from 'lit-html'

export const FilterSelectButtons: FilterSelectRenderer = (column, value, owner) => {
  const filter = column.filter as FilterConfigObject
  const operator = filter?.operator

  var options = filter?.options || column.record.options || []

  if (typeof options === 'function') {
    if (!filter?.options) {
      console.warn(
        'ox-grist의 column.filter 속성에서는 column.record.options의 함수형 options을 사용할 수 없으므로, filter 속성에서 재지정해야한다.'
      )
    }
    options = options.call(column, owner)
  }

  options = options.map((option: any) => {
    switch (typeof option) {
      case 'string':
        return {
          display: option,
          value: option
        }
      case 'object':
        return {
          display: option.display,
          value: option.value
        }
      default:
        return option
    }
  })

  return html`
    <ox-input-select-buttons
      name=${column.name}
      .value=${value}
      .options=${options}
      ?multiple=${operator === 'in'}
      @change=${(e: CustomEvent) => {
        ;(e.target as HTMLElement).dispatchEvent(
          new CustomEvent('filter-change', {
            bubbles: true,
            composed: true,
            detail: {
              name: column.name,
              operator: filter.operator,
              value: (e.target as HTMLInputElement).value
            }
          })
        )
      }}
    ></ox-input-select-buttons>
  `
}
