/**
 * @license Copyright © HatioLab Inc. All rights reserved.
 */

import { css, html } from 'lit'
import { customElement, property, query } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field'

@customElement('ox-input-angle')
export class OxInputAngle extends OxFormField {
  static styles = [
    css`
      :host {
        display: inline-block;
      }

      input {
        width: 100%;
        height: 100%;
        min-height: var(--form-element-height-medium);
        padding: 0 var(--spacing-small);
        box-sizing: border-box;
        border: var(--input-border, 1px solid var(--md-sys-color-outline));
        border-radius: var(--md-sys-shape-corner-small);
        background-color: var(--md-sys-color-on-primary);
        font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
        color: var(--md-sys-color-on-primary-container);
      }

      input:focus {
        outline: var(--input-focus-outline, inherit);
        border-color: var(--md-sys-color-secondary-fixed-dim);
      }
    `
  ]

  @property({ type: String }) placeholder?: string

  @query('input') input!: HTMLInputElement

  render() {
    return html`
      <input
        type="number"
        .value=${String(this._toDegree(this.value))}
        .placeholder=${this.placeholder || '0°'}
        @change=${(e: Event) => this._onChangeValue(e)}
        ?disabled=${this.disabled}
      />
    `
  }

  _onChangeValue(e: Event) {
    this.value = this._toRadian(this.input.value)

    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }

  _toDegree(value: string | number | undefined) {
    return Math.round(((Number(value) || 0) * 180) / Math.PI)
  }

  _toRadian(degree: string | number | undefined) {
    return isNaN(Number(degree)) ? undefined : Number(degree) * (Math.PI / 180)
  }
}
