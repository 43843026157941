/*
 * inspired by https://stackoverflow.com/questions/54520554/custom-element-getrootnode-closest-function-crossing-multiple-parent-shadowd
 */

/**
 * Finds the closest ancestor element of the given base element that matches the provided CSS selector.
 *
 * @param {string} selector - The CSS selector to search for.
 * @param {Element} base - The base element to start the search from.
 * @returns {Element | null} - The closest ancestor element matching the selector, or null if none is found.
 */
export function closestElement(selector: string, base: Element) {
  function __closestFrom(el: Element | Window | Document | null): Element | null {
    if (!el || el === document || el === window) {
      return null
    }
    if ((el as Slottable).assignedSlot) {
      el = (el as Slottable).assignedSlot
    }
    let found = (el as Element).closest(selector)
    return found ? found : __closestFrom(((el as Element).getRootNode() as ShadowRoot).host)
  }
  return __closestFrom(base)
}
