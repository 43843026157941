/**
 * Used to detect browser support for adding an event listener with options
 * Credit: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 */
export function passiveEventsSupported() {
  var supported = false

  try {
    var options = {
      get passive(): boolean {
        supported = true
        return true
      }
    }
    window.addEventListener('test', options as any, options)
    window.removeEventListener('test', options as any, options as any)
  } catch (err) {
    supported = false
  }

  return supported
}
