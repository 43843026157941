import '@material/web/icon/icon.js'

import { html, nothing } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'

import { ColumnConfig, FieldRenderer, GristRecord, HeaderRenderer } from '../types'

export class GutterButton {
  static instance(config: Partial<ColumnConfig> = { type: 'gutter', gutterName: 'button' }) {
    var { icon = 'edit', title, iconOnly = true } = config

    var iconFn = typeof icon == 'function' ? icon : (record?: GristRecord) => icon
    var titleFn = typeof title == 'function' ? title : (record?: GristRecord) => title

    return Object.assign(
      {},
      {
        type: 'gutter',
        gutterName: 'button',
        width: 26,
        resizable: false,
        sortable: false,
        header: {
          renderer: function (column) {
            const title = titleFn()
            const icon = iconFn()

            return iconOnly ? html` <md-icon title=${ifDefined(title)}>${icon}</md-icon> ` : title
          } as HeaderRenderer
        },
        record: {
          align: 'center',
          renderer: function (value, column, record, rowIndex, field) {
            const title = titleFn(record)
            const icon = iconFn(record)

            return iconOnly
              ? html` <md-icon title=${ifDefined(title && titleFn(record))}>${iconFn(record)}</md-icon> `
              : title || icon
                ? html`<button><md-icon>${iconFn(record)}</md-icon>${titleFn(record)}</button>`
                : nothing
          } as FieldRenderer
        },
        forGrid: true,
        forList: true,
        forCard: true
      },
      config
    )
  }
}
