import { FilterConfigObject, FilterOperator } from '..'
import { NOOP } from '../const'
import { getEditor } from '../editors'
import { generateGutterColumn } from '../gutters'
import { getGristEventHandler } from '../handlers'
import { getRenderer } from '../renderers'
import { ColumnConfig } from '../types'

export const buildColumn = (column: any): ColumnConfig => {
  var compiled: ColumnConfig = { ...column }

  if (column.type == 'gutter') {
    compiled = generateGutterColumn(column)
  }

  var { name, header, record, handlers, label, filter } = compiled

  /* header */

  if (typeof header == 'string') {
    compiled.header = {
      renderer: () => header
    }
  }

  if (typeof compiled.header?.renderer == 'string') {
    const text = compiled.header.renderer
    compiled.header = {
      ...compiled.header,
      renderer: () => text
    }
  }

  /* label */
  if (label) {
    let type = typeof label
    switch (type) {
      case 'boolean':
        compiled.label = {
          renderer: compiled.header?.renderer || (() => name)
        }
        break
      case 'string':
        compiled.label = {
          renderer: () => label
        }
        break
      default:
        compiled.label = label
    }
  } else {
    compiled.label = false
  }

  /*
   * record
   */
  var { renderer: recordRenderer, editor, editable, classifier } = record || {}

  recordRenderer = getRenderer(recordRenderer || column.type)

  if (editable !== false && typeof editor !== 'function') {
    editor = getEditor(column.type)
  }

  if (typeof classifier !== 'function') {
    classifier = NOOP
  }

  compiled.record = {
    ...record,
    classifier,
    renderer: recordRenderer,
    editor
  }

  /* filter */
  if (filter) {
    let type = typeof filter
    switch (type) {
      case 'boolean':
        compiled.filter = {
          type: column.type,
          operator: 'eq'
        }
        break
      case 'string':
        compiled.filter = {
          type: column.type,
          operator: filter as FilterOperator
        }
        break
      default:
        compiled.filter = {
          //@ts-ignore
          type: column.type,
          operator: 'eq',
          ...(filter as FilterConfigObject)
        }
    }
  }

  /* handler */
  var { click, dblclick, focus, contextmenu } = handlers || {}

  compiled.handlers = {
    click: click && getGristEventHandler(click),
    dblclick: dblclick && getGristEventHandler(dblclick),
    focus: focus && getGristEventHandler(focus),
    contextmenu: contextmenu && getGristEventHandler(contextmenu)
  }

  return compiled
}
