import { DataCardField } from '../data-card/data-card-field.js'
import { DataCardGutter } from '../data-card/data-card-gutter.js'
import { RecordCard } from '../data-card/record-card.js'
import { DataGridField } from '../data-grid/data-grid-field.js'
import { DataListField } from '../data-list/data-list-field.js'
import { DataListGutter } from '../data-list/data-list-gutter.js'
import { RecordPartial } from '../data-list/record-partial.js'
import { DataReportField } from '../data-report/data-report-field.js'
import { ColumnConfig, FieldRenderer, GristRecord } from '../types.js'
import { OxGristRendererBoolean } from './ox-grist-renderer-boolean.js'
import { OxGristRendererColor } from './ox-grist-renderer-color.js'
import { OxGristRendererDate } from './ox-grist-renderer-date.js'
import { OxGristRendererFile } from './ox-grist-renderer-file.js'
import { OxGristRendererImage } from './ox-grist-renderer-image.js'
import { OxGristRendererJson5 } from './ox-grist-renderer-json5.js'
import { OxGristRendererLink } from './ox-grist-renderer-link.js'
import { OxGristRendererPassword } from './ox-grist-renderer-password.js'
import { OxGristRendererProgress } from './ox-grist-renderer-progress.js'
import { OxGristRendererSelect } from './ox-grist-renderer-select.js'
import { OxGristRendererText } from './ox-grist-renderer-text.js'
import { OxGristRendererTextarea } from './ox-grist-renderer-textarea.js'
import { OxGristRendererTree } from './ox-grist-renderer-tree.js'
import { OxGristRenderer } from './ox-grist-renderer.js'

interface OxGristRendererConstructor {
  new (): OxGristRenderer
}

function isClass(func: Function) {
  return typeof func === 'function' && /^class\s/.test(Function.prototype.toString.call(func))
}

var RENDERERS: {
  [name: string]: FieldRenderer | OxGristRendererConstructor
} = {
  string: OxGristRendererText,
  text: OxGristRendererText,
  textarea: OxGristRendererTextarea,
  email: OxGristRendererText,
  tel: OxGristRendererText,
  password: OxGristRendererPassword,
  integer: OxGristRendererText,
  float: OxGristRendererText,
  number: OxGristRendererText,
  select: OxGristRendererSelect,
  boolean: OxGristRendererBoolean,
  checkbox: OxGristRendererBoolean,
  month: OxGristRendererText,
  week: OxGristRendererText,
  date: OxGristRendererDate,
  time: OxGristRendererDate,
  datetime: OxGristRendererDate,
  color: OxGristRendererColor,
  progress: OxGristRendererProgress,
  link: OxGristRendererLink,
  image: OxGristRendererImage,
  file: OxGristRendererFile,
  json5: OxGristRendererJson5,
  tree: OxGristRendererTree
}

export function registerRenderer(type: string, renderer: FieldRenderer) {
  RENDERERS[type] = renderer
}

export function unregisterRenderer(type: string): void {
  delete RENDERERS[type]
}

export function getRenderers(): { [name: string]: FieldRenderer | OxGristRendererConstructor } {
  return { ...RENDERERS }
}

export function getRenderer(type: string | FieldRenderer): FieldRenderer {
  if (typeof type === 'function') {
    return type
  }

  var renderer: FieldRenderer | OxGristRendererConstructor = RENDERERS[type || 'text'] || OxGristRendererText

  if (!isClass(renderer)) {
    return renderer as FieldRenderer
  }

  return function (
    value: any,
    column: ColumnConfig,
    record: GristRecord,
    rowIndex: number,
    field:
      | DataGridField
      | RecordCard
      | DataCardGutter
      | DataCardField
      | DataListGutter
      | DataListField
      | RecordPartial
      | DataReportField
      | Element
  ) {
    var element: OxGristRenderer = new (renderer as OxGristRendererConstructor)() as any

    element.value = value
    element.record = record
    element.column = column
    element.row = rowIndex
    element.field = field as DataGridField

    return element as OxGristRenderer
  }
}
