import '@material/web/icon/icon.js'
import '../data-grid/data-grid-field'

import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ZERO_RECORD } from '../configure/zero-config'
import { ColumnConfig, GristRecord, ValidationReason } from '../types'
import { recordViewBodyClickHandler } from './event-handlers/record-view-body-click-handler'
import i18next from 'i18next'

@customElement('ox-record-view-body')
export class RecordViewBody extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: column;
        align-content: center;
      }

      :host > * {
        align-self: stretch;
      }

      div[content] {
        align-self: center;

        display: grid;
        grid-template-columns: 2fr 3fr 2fr 3fr;
        grid-auto-rows: min-content;
        grid-gap: var(--spacing-large) 0;

        padding: var(--spacing-large) var(--spacing-huge);
        font-size: 16px; /* for ios safari */
        flex: 1;
        width: 100%;
        max-width: 1300px;
        box-sizing: border-box;
      }

      label {
        display: flex;
        align-items: center;
        position: relative;
        text-transform: capitalize;
        padding: var(--record-view-item-padding);
        font: var(--record-view-label-font);
        color: var(--record-view-label-color);
        text-align: right;
        padding-right: var(--spacing-large);
      }

      label[wide] {
        grid-column: 1 / 2;
      }

      label md-icon {
        display: none;
      }

      label > span {
        margin-left: auto;
      }

      label[editable] md-icon {
        display: inline-block;
        font-size: var(--record-view-label-icon-size);
        opacity: 0.5;
      }

      ox-grid-field {
        background-color: var(--record-view-grid-field-background-color, var(--md-sys-color-surface-container-lowest));
        border: var(--record-view-grid-field-border);
        border-radius: var(--md-sys-shape-corner-small);
        padding: var(--spacing-tiny) var(--spacing-small);

        font: var(--record-view-font);
        color: var(--record-view-color);

        max-width: 360px;
      }

      ox-grid-field[editing='true'] {
        border: var(--record-view-edit-border);
      }

      ox-grid-field[wide] {
        grid-column: 2 / 5;
        width: 100%;
        max-width: 986px;
      }

      :first-child + ox-grid-field {
        color: var(--record-view-focus-color);
        font-weight: bold;
      }

      .highlight-invalid {
        position: relative;
        padding: var(--spacing-tiny) var(--spacing-small);
      }

      .highlight-invalid::after {
        content: attr(data-reason); /* 콘텐츠를 동적으로 변경하기 위해 data-reason 속성을 사용 */
        color: red;
        font-size: 12px;
        position: absolute;
        left: 0;
        bottom: -8px; /* 라벨 아래쪽에 메시지를 표시 */
      }

      @media only screen and (max-width: 1000px) {
        div[content] {
          grid-template-columns: 2fr 3fr;
          padding: var(--spacing-medium);
        }

        label[wide] {
          grid-column: 1 / 2;
        }

        ox-grid-field[wide] {
          grid-column: 2 / 3;
          width: 100%;
          max-width: 360px;
        }
      }

      @media only screen and (max-width: 600px) {
        div[content] {
          grid-template-columns: 2fr 3fr;
          padding: var(--spacing-medium);
        }

        label[wide] {
          grid-column: 1 / 2;
        }

        ox-grid-field[wide] {
          grid-column: 1 / 3;
          width: 100%;
          max-width: unset;
        }
      }
    `
  ]

  @property({ type: Array }) columns: ColumnConfig[] = []
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Number }) rowIndex: number = -1

  public currentTarget: any

  connectedCallback() {
    super.connectedCallback()

    this.setAttribute('tabindex', '0')

    this.addEventListener('keydown', this._onKeyDown)
    this.renderRoot.addEventListener('click', recordViewBodyClickHandler.bind(this))
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener('keydown', this._onKeyDown)
  }

  setFocus(fieldElement: HTMLElement) {
    fieldElement?.dispatchEvent(new CustomEvent('click', { bubbles: true, composed: true }))
  }

  setFocusOnInvalid(invalidFields: { field: string; reason: ValidationReason }[]) {
    const allLabels = this.renderRoot.querySelectorAll('label')
    allLabels.forEach((label: HTMLLabelElement) => {
      label.classList.remove('highlight-invalid')
      label.removeAttribute('data-reason')
    })

    // 유효성 검사를 통과하지 못한 필드에 대해 처리
    invalidFields.forEach(({ field, reason }, index) => {
      const labelElement = this.renderRoot.querySelector(`[data-name="${field}"]`) as HTMLLabelElement
      const fieldElement = this.renderRoot.querySelector(`[data-name="${field}"] + ox-grid-field`) as HTMLInputElement

      // 동적으로 data-reason 속성을 설정하여 메시지를 변경
      if (labelElement) {
        labelElement.classList.add('highlight-invalid')
        labelElement.setAttribute('data-reason', '(' + i18next.t(`text.validation-reason.${reason}`) + ')')
      }

      // 첫 번째 필드에 포커스 설정
      if (index === 0 && fieldElement) {
        this.setFocus(fieldElement)
      }
    })
  }

  _onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      const fields = Array.from(this.renderRoot.querySelectorAll('ox-grid-field[tabstop]'))
      const focused = this.renderRoot.querySelector('ox-grid-field[editing]')
      const focusedIndex = fields.findIndex(field => field === focused)

      let nextIndex = focusedIndex + (event.shiftKey ? -1 : 1)

      if (nextIndex >= fields.length || nextIndex < 0) {
        return // Let the default behavior happen if it's the last or first element
      }

      event.preventDefault()
      const nextField = fields[nextIndex] as HTMLInputElement
      nextField && this.setFocus(nextField)
    }
  }

  render() {
    var columns = this.columns.filter(column => !column.hidden && column.type !== 'gutter')
    var record = this.record
    var rowIndex = this.rowIndex

    return html`
      <div content>
        ${columns.map((column, index) => {
          let { editable, mandatory, wide } = column.record
          if (typeof editable === 'function') {
            editable = editable.call(this, record[column.name], column, record, rowIndex, this)
          }

          let dirtyFields = record['__dirtyfields__'] || {}

          return html`
            <label ?editable=${editable} ?wide=${wide} data-name=${column.name}>
              <span>${mandatory ? '*' : ''}${this._renderLabel(column)}</span>
              <md-icon>edit</md-icon>
            </label>
            <ox-grid-field
              .rowIndex=${rowIndex}
              .column=${column}
              .record=${record}
              .value=${record[column.name]}
              ?dirty=${!!dirtyFields[column.name]}
              ?tabstop=${!!editable}
              ?wide=${wide}
            ></ox-grid-field>
          `
        })}
      </div>
    `
  }

  _renderLabel(column: ColumnConfig) {
    var { renderer } = column.header
    var title = renderer.call(this, column)

    return html` ${title} `
  }
}
