import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import throttle from 'lodash-es/throttle'

import { ZERO_CONFIG, ZERO_DATA } from '../configure/zero-config'
import { ColumnConfig, GristConfig, GristData, SortersConfig } from '../types'
import { supportsPassive } from '../utils'

@customElement('ox-report-header')
class DataReportHeader extends LitElement {
  static styles = [
    css`
      :host {
        display: grid;
        grid-template-columns: var(--report-template-columns);

        overflow: hidden;
      }

      div {
        display: flex;

        white-space: nowrap;
        overflow: hidden;
        background-color: var(--report-header-background-color, gray);
        border: 1px solid var(--report-header-border-color);
        border-width: 1px 1px 1px 0;
        padding: var(--report-header-padding);

        text-overflow: ellipsis;
        text-align: center;
        font-size: var(--report-header-fontsize, 1em);
        color: var(--report-header-color);
      }

      span {
        white-space: nowrap;
        overflow: hidden;

        text-align: center;
      }

      span[titler] {
        flex: 1;
        text-overflow: ellipsis;
        font: var(--report-header-font);
        color: var(--report-header-color);
        text-transform: capitalize;
      }

      span[sorter] {
        padding: 0;
        border: 0;
        font-size: 10px;
      }

      span[splitter] {
        cursor: col-resize;
      }

      @media print {
        :host {
          grid-template-columns: var(--report-template-print-columns);
        }
      }
    `
  ]

  @property({ type: Object }) config: GristConfig = ZERO_CONFIG
  @property({ type: Array }) columns: ColumnConfig[] = []
  @property({ type: Object }) data: GristData = ZERO_DATA
  @property({ type: Array }) private _sorters?: SortersConfig

  private throttledNotifier?: (idx: number, width: number) => void

  onWheelEvent(e: WheelEvent) {
    if (this.scrollWidth > this.clientWidth) {
      var delta = Math.max(-1, Math.min(1, e.deltaY || 0))
      this.scrollLeft = Math.max(0, this.scrollLeft - delta * 40)

      var maxScrollLeft = this.scrollWidth - this.clientWidth

      var atStart = this.scrollLeft === 0
      var atEnd = this.scrollLeft === maxScrollLeft

      // 스크롤이 맨 앞으로 와 있는 상태에서 휠을 올리는 경우 또는
      // 스크롤이 맨 끝으로 가 있는 상태에서 휠을 내리는 경우에만 디폴트 동작 허용
      if ((atStart && delta > 0) || (atEnd && delta < 0)) {
        return true
      }

      e.preventDefault()
    }
  }

  firstUpdated() {
    this.addEventListener('wheel', this.onWheelEvent.bind(this))
  }

  async updated(changed: PropertyValues<this>) {
    if (changed.has('config')) {
      this._sorters = this.config.sorters || []
    }
  }

  render() {
    var columns = this.columns || []

    return html`
      ${columns.map((column, idx) =>
        !column.hidden
          ? html`
              <div @dragstart=${(e: MouseEvent) => this._dragStart(e, idx)}>
                <span titler @click=${(e: Event) => this._changeSort(column)}>${this._renderHeader(column)} </span>

                ${column.sortable
                  ? html`
                      <span sorter @click=${(e: Event) => this._changeSort(column)}>
                        ${this._renderSortHeader(column)}
                      </span>
                    `
                  : html``}
                ${column.resizable !== false ? html` <span splitter draggable="true">&nbsp;</span> ` : html``}
              </div>
            `
          : html``
      )}

      <div></div>
    `
  }

  _renderHeader(column: ColumnConfig) {
    var { renderer } = column.header || {}
    var title = renderer.call(this, column)

    return html` ${title} `
  }

  _renderSortHeader(column: ColumnConfig) {
    if (column.hidden) {
      return html``
    }

    var sorters = this._sorters || []

    var sorter = sorters.find(sorter => column.type !== 'gutter' && column.name == sorter.name)
    if (!sorter) {
      return html``
    }

    if (sorters.length > 1) {
      var rank = sorters.indexOf(sorter) + 1
      return sorter.desc ? html` &#9660;<sub>${rank}</sub> ` : html` &#9650;<sub>${rank}</sub> `
    } else {
      return sorter.desc ? html` &#9660; ` : html` &#9650; `
    }
  }

  _changeSort(column: ColumnConfig) {
    if (!column.sortable) {
      return
    }

    var sorters = [...(this._sorters || [])]

    var idx = sorters.findIndex(sorter => sorter.name == column.name)
    if (idx !== -1) {
      let sorter = sorters[idx]
      if (sorter.desc) {
        sorters.splice(idx, 1)
      } else {
        sorter.desc = true
      }
    } else {
      var sorter = {
        name: column.name
      }

      sorters.push(sorter)
    }

    this._sorters = sorters

    this.dispatchEvent(
      new CustomEvent('fetch-params-change', {
        bubbles: true,
        composed: true,
        detail: {
          sorters: this._sorters,
          from: 'data-report-header'
        }
      })
    )
  }

  _notifyWidthChange(idx: number, width: number) {
    if (!this.throttledNotifier) {
      this.throttledNotifier = throttle((idx, width) => {
        this.dispatchEvent(
          new CustomEvent('column-width-change', {
            bubbles: true,
            composed: true,
            detail: {
              idx,
              width
            }
          })
        )
      }, 100)
    }

    this.throttledNotifier(idx, width)
  }

  _dragStart(e: MouseEvent, idx: number) {
    var target = e.currentTarget as HTMLElement
    var startX = e.offsetX

    var dragHandler = ((e: MouseEvent) => {
      let column = this.columns[idx]

      let width = Math.max(0, Number(column.width) + e.offsetX - startX)
      if (width == 0) {
        /* CLARIFY-ME 왜 마지막 이벤트의 offsetX로 음수 값이 오는가 */
        return
      }

      this._notifyWidthChange(idx, width)
    }).bind(this)

    var dragEndHandler = ((e: MouseEvent) => {
      target.removeEventListener('drag', dragHandler)
      target.removeEventListener('dragend', dragEndHandler)

      dragHandler(e)
    }).bind(this)

    target.addEventListener('drag', dragHandler)
    target.addEventListener('dragend', dragEndHandler)
  }
}
