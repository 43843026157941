import { DataGridField } from '../data-grid/data-grid-field'
import { ColumnConfig, GristData, GristRecord } from '../types'

/*
 * handler들은 ox-grid-field 로부터 호출되는 것을 전제로 하며,
 * 전반적인 처리를 위해서, columns 및 data 정보를 포함해서 제공할 수 있어야 한다.
 */

export const RecordCopy = function (
  columns: ColumnConfig[],
  data: GristData,
  column: ColumnConfig,
  record: GristRecord,
  rowIndex: number,
  field: DataGridField
): void {
  if (rowIndex >= data.records.length) {
    return
  }

  var copiedRecord = {
    __dirty__: '+'
  } as GristRecord

  columns
    .filter(column => column.record.editable)
    .forEach(column => {
      copiedRecord[column.name] = record[column.name]
    })

  data.records.splice(rowIndex + 1, 0, copiedRecord)
  field.dispatchEvent(
    new CustomEvent('record-change', {
      bubbles: true,
      composed: true
    })
  )
}
