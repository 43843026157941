import './data-report-header'
import './data-report-body'

import { ColumnConfig, GristConfig, GristData } from '../types'
import { LitElement, PropertyValues, css, html } from 'lit'
import { ZERO_CONFIG, ZERO_DATA } from '../configure/zero-config'
import { customElement, property, query } from 'lit/decorators.js'

import { ScrollbarStyles } from '@operato/styles'

/**
 * DataReportComponent
 */

@customElement('ox-report-component')
export class DataReportComponent extends LitElement {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;

        overflow: hidden;

        border: 1px solid var(--report-header-border-color);
        border-radius: var(--report-component-border-radius);
      }

      ox-report-body {
        flex: 1;
      }

      @media print {
        :host {
          zoom: 80%;
        }
      }
    `
  ]

  @property({ type: Object }) config: GristConfig = ZERO_CONFIG
  @property({ type: Object }) data: GristData = ZERO_DATA

  private _widths: string = ''

  @query('ox-report-body', true) body!: HTMLElement
  @query('ox-report-header', true) header!: HTMLElement

  firstUpdated() {
    /* header and body scroll synchronization */
    this.header?.addEventListener('scroll', (e: Event) => {
      const body = this.body
      const header = this.header
      if (body && header) {
        if (body.scrollLeft !== header.scrollLeft) {
          body.scrollLeft = header.scrollLeft
        }
      }
    })

    this.body?.addEventListener('scroll', e => {
      const body = this.body
      const header = this.header
      if (body && header) {
        if (body.scrollLeft !== header.scrollLeft) {
          header.scrollLeft = body.scrollLeft
        }
      }
    })
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('config') || changes.has('data')) {
      /*
       * 데이타 내용에 따라 동적으로 컬럼의 폭이 달라지는 경우(예를 들면, sequence field)가 있으므로,
       * data의 변동에 대해서도 다시 계산되어야 한다.
       */
      this.calculateWidths(this.config && this.config.columns)
    }
  }

  calculateWidths(columns: ColumnConfig[]) {
    /*
     * 컬럼 모델 마지막에 'auto' cell을 추가하여, 자투리 영역을 꽉 채워서 표시한다.
     */
    this._widths = columns
      .filter(column => !column.hidden)
      .map(column => {
        switch (typeof column.width) {
          case 'number':
            return column.width + 'px'
          case 'string':
            return column.width
          case 'function':
            return column.width.call(this, column)
          default:
            return 'auto'
        }
      })
      .concat(['auto'])
      .join(' ')

    this.style.setProperty('--report-template-columns', this._widths)
    this.style.setProperty('--report-template-print-columns', this._widths.replace(/px/gi, 'fr'))
  }

  render() {
    var { columns } = this.config

    var data = this.data

    return html`
      <ox-report-header
        .config=${this.config}
        .columns=${columns}
        .data=${data}
        @column-width-change=${(e: CustomEvent) => {
          let { idx, width } = e.detail
          columns[idx].width = width
          this.calculateWidths(columns)
        }}
      ></ox-report-header>

      <ox-report-body .config=${this.config} .columns=${columns} .data=${data}></ox-report-body>
    `
  }

  focus() {
    super.focus()

    this.body.focus()
  }

  get pullToRefreshTarget() {
    return this.body
  }
}
