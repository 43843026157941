import { NOOP } from '../const'
import { DateFormatter } from './date-formatter'
import { NumberFormatter } from './number-formatter'
import { TextFormatter } from './text-formatter'

var FORMATTERS: { [name: string]: (value: any) => string } = {
  number: NumberFormatter,
  date: DateFormatter,
  text: TextFormatter
}

export function registerFormatter(type: string, formatter: (value: any) => string) {
  FORMATTERS[type] = formatter
}

export function unregisterFormatter(type: string) {
  delete FORMATTERS[type]
}

export function getFormatters() {
  return { ...FORMATTERS }
}

export function getFormatter(type: string) {
  if (typeof type == 'function') {
    return type
  }

  return FORMATTERS[type] || NOOP
}
