import '@material/web/icon/icon.js'

import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ZERO_DATA, ZERO_PAGES } from '../configure/zero-config'
import { GristData, PaginationConfig } from '../types'
import { stringifyBigNumber } from '@operato/utils'

@customElement('ox-grid-footer')
export class DataGridFooter extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-small);
        overflow: hidden;

        padding: var(--grid-footer-padding);
        background-color: var(--grid-footer-background-color);
        font-size: var(--grid-footer-font-size);
        align-items: center;

        user-select: none;
      }

      :host * {
        white-space: nowrap;
        overflow: hidden;

        text-overflow: ellipsis;
        text-align: center;
        color: var(--grid-footer-color);
      }

      .filler {
        flex: 1;
      }

      md-icon {
        font-size: 1.5em;
        vertical-align: middle;

        font-variation-settings: 'FILL' 1;
      }

      .limit {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-small);
        align-items: center;
      }

      .limit a {
        color: var(--grid-footer-limit-color);
      }

      .limit a[selected] {
        color: var(--grid-footer-color);
        font-weight: bold;
        text-decoration: underline;
      }

      a[inactive] * {
        color: var(--grid-footer-inactive-color);
      }
    `
  ]

  @property() data: GristData = ZERO_DATA
  @property() pagination: PaginationConfig = {}

  _gotoPage(page: number) {
    var { pages = ZERO_PAGES } = this.pagination || {}
    var { limit = pages[0], total = 0 } = this.data

    if (page > Math.ceil(total / limit) || page <= 0) {
      return
    }
    this.dispatchEvent(new CustomEvent('page-change', { bubbles: true, composed: true, detail: page }))
  }

  _changeLimit(limit: number) {
    this.dispatchEvent(new CustomEvent('limit-change', { bubbles: true, composed: true, detail: limit }))
  }

  render() {
    var { pages = ZERO_PAGES } = this.pagination || {}
    var { records = [], page = 1, limit = pages[0], total = 0 } = this.data

    var begin = records.length == 0 ? 0 : limit * (page - 1) + 1
    var end = records.length == 0 ? 0 : begin + records.length - 1
    var totalPage = Math.max(1, Math.ceil(total / limit))

    return html`
      <a ?inactive=${page <= 1} @click=${() => this._gotoPage(1)}><md-icon>skip_previous</md-icon></a>
      <a ?inactive=${page <= 1} @click=${() => this._gotoPage(page - 1)}><md-icon>navigate_before</md-icon></a>
      <span>page <strong>${page}</strong>&nbsp;/&nbsp;${totalPage}</span>
      <a ?inactive=${page >= totalPage} @click=${() => this._gotoPage(page + 1)}><md-icon>navigate_next</md-icon></a>
      <a ?inactive=${page >= totalPage} @click=${() => this._gotoPage(totalPage)}><md-icon>skip_next</md-icon></a>

      <span class="filler"></span>

      <span class="limit">
        ${pages.map(
          size => html`
            <a ?selected=${limit == size} @click=${() => this._changeLimit(size)}>${stringifyBigNumber(size)}</a>
          `
        )}
        records
      </span>
      <span>&nbsp;</span>
      <span>${begin} - ${end}</span>
      <span>&nbsp;/&nbsp;</span>
      <span>total ${total || 0} records.</span>
    `
  }
}
