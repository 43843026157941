import { DataReportBody } from '../data-report-body'
import { DataReportField } from '../data-report-field'

/**
 * ox-report-body 의 click handler
 */
export function dataReportBodyClickHandler(this: DataReportBody, e: Event) {
  e.stopPropagation()

  var target = e.target as DataReportField
  var { column, record, rowIndex, columnIndex } = target

  if (!column) {
    /* 여백 컬럼이 클릭된 경우 */
    return
  }

  if (!isNaN(rowIndex) && !isNaN(columnIndex)) {
    if (!this.focused || rowIndex !== this.focused.row || columnIndex !== this.focused.column) {
      this.focused = {
        row: rowIndex,
        column: columnIndex
      }
    } else {
      this.requestUpdate()
    }
  } else {
    console.error('should not be here.')
  }

  /* do column click handler */
  var { click } = column.handlers
  click && click(this.columns, this.data, column, record, rowIndex, target)

  /* do rows click handler */
  var { click: rowsClick } = this.config.rows.handlers
  rowsClick && rowsClick(this.columns, this.data, column, record, rowIndex, target)
}
