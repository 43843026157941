import { debug, error, warn } from '../logger'

import { SnapshotTaker } from './snapshot-taker'

/**
 * A utility class for managing and navigating through a history of snapshots.
 */
export class TimeCapsule {
  private q: Array<any> = []
  private maxsize = 10
  private pos = -1

  private _snapshot_taker: SnapshotTaker | null = null

  /**
   * Creates an instance of TimeCapsule.
   * @param {number} maxsize - The maximum number of snapshots to store.
   * @param {any} [start_state] - The initial state to be stored as the first snapshot (optional).
   */
  constructor(maxsize: number, start_state?: any) {
    maxsize = Number(maxsize)

    if (!maxsize || maxsize <= 0) {
      error('TimeCapsule maxsize should be greater than 0.', maxsize)
    } else {
      this.maxsize = maxsize
    }

    this.reset()
    if (start_state) {
      this.snapshot(start_state)
    }
  }

  /**
   * Disposes of the TimeCapsule and clears all stored snapshots.
   */
  dispose() {
    this.reset()
  }

  /**
   * Captures a snapshot of the current state and stores it.
   * @param {any} state - The state to be captured as a snapshot.
   */
  snapshot(state: any) {
    this.q.splice(this.pos + 1, this.q.length - (this.pos + 1), state)

    if (this.q.length > this.maxsize) {
      this.q.splice(0, this.q.length - this.maxsize)
    }

    this.pos = this.q.length - 1
  }

  /**
   * Moves forward to the next snapshot in the history.
   * @returns {any} The next state snapshot if available, or logs a warning if not.
   */
  forward() {
    if (this.snapshot_taker) this.snapshot_taker.take()

    if (this.forwardable) {
      return this.q[++this.pos]
    }
    warn('Not forwardable.')
  }

  /**
   * Moves backward to the previous snapshot in the history.
   * @returns {any} The previous state snapshot if available, or logs a warning if not.
   */
  backward() {
    if (this.snapshot_taker) this.snapshot_taker.take()

    if (this.backwardable) {
      return this.q[--this.pos]
    }
    warn('Not backwardable.')
  }

  /**
   * Gets the current state snapshot.
   * @returns {any} The current state snapshot if available, or logs a warning if not.
   */
  get current() {
    if (this.pos !== -1) return this.q[this.pos]

    warn('Non state has been recorded.')
  }

  /**
   * Gets the number of snapshots stored in the TimeCapsule.
   * @returns {number} The count of stored snapshots.
   */
  get length() {
    return this.q.length
  }

  /**
   * Checks if there is a snapshot available to move forward.
   * @returns {boolean} True if moving forward is possible, otherwise false.
   */
  get forwardable() {
    return this.pos < this.q.length - 1
  }

  /**
   * Checks if there is a snapshot available to move backward.
   * @returns {boolean} True if moving backward is possible, otherwise false.
   */
  get backwardable() {
    return this.pos > 0
  }

  /**
   * Gets the SnapshotTaker associated with this TimeCapsule.
   * @returns {SnapshotTaker | null} The associated SnapshotTaker, or null if not set.
   */
  get snapshot_taker(): SnapshotTaker | null {
    return this._snapshot_taker
  }

  /**
   * Sets the SnapshotTaker for this TimeCapsule.
   * @param {SnapshotTaker | null} snapshot_taker - The SnapshotTaker instance to associate with this TimeCapsule.
   */
  set snapshot_taker(snapshot_taker: SnapshotTaker | null) {
    this._snapshot_taker = snapshot_taker
  }

  /**
   * Resets the TimeCapsule, clearing all stored snapshots and resetting the position.
   */
  reset() {
    this.q = []
    this.pos = -1
  }
}
