import { ZERO_RECORDS } from './configure/zero-config.js'
import { DataConsumer } from './data-consumer.js'
import { FetchHandler, FetchOption, FilterValue, GristRecord, SorterConfig, SortersConfig } from './types.js'

function EMPTY_FETCHHANDLER() {
  return {
    total: 0,
    records: []
  }
}

function _calculateTotalPage(limit: number, total: number) {
  /*
   * total page는 1이상이어야 한다.
   * 즉, 레코드가 하나도 없어도, 페이지 갯수는 1이 되어야 한다.
   */
  return Math.max(1, Math.ceil(total / limit))
}

export class DataProvider {
  page: number = 1
  limit: number = 20
  total: number = 0
  records: GristRecord[] = []

  private consumer?: DataConsumer
  private _fetchHandler?: FetchHandler
  private _sorters?: SorterConfig[]
  private _filters?: FilterValue[]

  private _pageChangeHandler: EventListener = this.onPageChange.bind(this)
  private _limitChangeHandler = this.onLimitChange.bind(this)
  private _fetchParamsChangeHandler = this.onFetchParamsChange.bind(this)
  private _recordChangeHandler = this.onRecordChange.bind(this)
  private _attachPageHandler: EventListener = this.onAttachPage.bind(this)

  private _fetchHandlerWrap: any
  private _fetchOptions: any

  constructor(consumer: DataConsumer) {
    this.consumer = consumer

    this.fetchHandler = consumer.fetchHandler

    this.consumer.addEventListener('attach-page', this._attachPageHandler)
    this.consumer.addEventListener('page-change', this._pageChangeHandler)
    this.consumer.addEventListener('limit-change', this._limitChangeHandler)
    this.consumer.addEventListener('fetch-params-change', this._fetchParamsChangeHandler)
    this.consumer.addEventListener('record-change', this._recordChangeHandler)
  }

  dispose() {
    this.consumer?.removeEventListener('attach-page', this._attachPageHandler)
    this.consumer?.removeEventListener('page-change', this._pageChangeHandler)
    this.consumer?.removeEventListener('limit-change', this._limitChangeHandler)
    this.consumer?.removeEventListener('fetch-params-change', this._fetchParamsChangeHandler)
    this.consumer?.removeEventListener('record-change', this._recordChangeHandler)
  }

  onAttachPage() {
    this.attach()
  }

  onPageChange(e: Event) {
    var page = (e as CustomEvent).detail
    this.fetch({ page })
  }

  onLimitChange(e: Event) {
    var limit = (e as CustomEvent).detail
    this.fetch({ limit })
  }

  onFetchParamsChange(e: Event) {
    const { sorters, filters, from } = (e as CustomEvent).detail || {}

    sorters && (this.sorters = sorters)
    filters && (this.filters = filters)

    if (this.consumer?.mode !== 'GRID') {
      this.page = 0
    }

    from !== 'config' && this.fetch()
  }

  onRecordChange(e: Event) {
    this.consumer?.checkDirties()
  }

  get fetchOptions() {
    return this._fetchOptions
  }

  set fetchOptions(fetchOptions) {
    this._fetchOptions = fetchOptions

    this.fetch()
  }

  get fetchHandler() {
    if (!this._fetchHandlerWrap) {
      this._fetchHandlerWrap = async (options: FetchOption) => {
        if (!this._fetchHandler) {
          return
        }

        try {
          this.consumer?.showSpinner()
          return await (this._fetchHandler || EMPTY_FETCHHANDLER)(options)
        } finally {
          this.consumer?.hideSpinner()
        }
      }
    }

    return this._fetchHandlerWrap
  }

  set fetchHandler(fetchHandler) {
    this._fetchHandler = fetchHandler
    delete this._fetchHandlerWrap
  }

  get sorters() {
    return this._sorters
  }

  set sorters(sorters) {
    this._sorters = sorters
  }

  /* alias for sorters */
  get sortings() {
    return this._sorters
  }

  set sortings(sorters) {
    this._sorters = sorters
  }

  get filters() {
    return this._filters
  }

  set filters(filters) {
    this._filters = filters
  }

  async attach(reset = false) {
    var { page = 0, limit = 20 } = this

    /*
     * page는 0 based index가 아님에 주의한다.
     * 즉, page 값이 1 은 첫페이지를 의미한다.
     */
    if (reset) {
      this.records = ZERO_RECORDS
      page = 1
    } else {
      /* attach의 경우는 grist data의 변경상태를 유지하기 위해서, grist._data 를 기반으로 한다. */
      this.records = this.consumer?._data ? this.consumer._data.records : ZERO_RECORDS
      page = page + 1
    }

    return this._update(
      {
        /* fetch에서 limit과 page를 제공하지 않는 경우를 대비함. */
        limit,
        page,
        ...(await this.fetchHandler.call(null, {
          page,
          limit,
          sorters: this.sorters,
          sortings: this.sorters,
          filters: this.filters,
          options: this.fetchOptions
        }))
      },
      reset
    )
  }

  async fetch({
    page = this.page,
    limit = this.limit,
    sorters,
    sortings,
    filters
  }: {
    page?: number
    limit?: number
    sorters?: SortersConfig
    sortings?: SortersConfig
    filters?: FilterValue[]
  } = {}) {
    /* sortings property is only for things-factory server-side list parameter convention */
    /* fetchHandler should reture { page, limit, total, records } */
    this.records = ZERO_RECORDS

    sorters = sorters || sortings || this.sorters
    filters = filters || this.filters

    this.sorters = sorters

    return this._update({
      /* fetch에서 limit과 page를 제공하지 않는 경우를 대비함. */
      limit,
      page,
      ...(await this.fetchHandler.call(null, {
        page,
        limit,
        sorters,
        sortings: sorters,
        filters,
        options: this.fetchOptions
      }))
    })
  }

  async _update(
    {
      page,
      limit,
      total,
      records
    }: {
      page: number
      limit: number
      total: number
      records: GristRecord[]
    },
    reset?: boolean
  ): Promise<void> {
    // total을 감안해서 page가 최대값을 넘지 않도록 한다.
    var maxpage = _calculateTotalPage(limit, total)
    if (maxpage < page) {
      return await this.fetch({ page: maxpage, limit })
    }

    // page와 limit이 없는 경우 records 검사 전에 초기화
    this.page = this.page || page
    this.limit = this.limit || limit

    if (!records) {
      return
    }

    if (this.records === ZERO_RECORDS) {
      this.records = records
    } else if (this.page < page) {
      // attach인 경우에는 records를 append한다.
      this.records = [...this.records, ...records]
    } else {
      return
    }

    this.limit = limit
    this.total = total
    this.page = page

    this.consumer &&
      (this.consumer.data = {
        page: this.page,
        limit: this.limit,
        total: this.total,

        records: this.records
      })
  }
}
